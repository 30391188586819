import { ApolloClient, InMemoryCache, ApolloLink, NormalizedCacheObject } from "@apollo/client";
import createErrorHandlingLink from "./createErrorHandlingLink";
import createAuthLink from "./createAuthLink";
import createHttpLink from "./createHttpLink";

export default function createClient(): ApolloClient<NormalizedCacheObject> {
  const errorHandlingLink = createErrorHandlingLink();
  const authLink = createAuthLink();
  const httpLink = createHttpLink();
  const links = [errorHandlingLink, authLink, httpLink];
  const link = ApolloLink.from(links);
  const cache = new InMemoryCache();
  const defaultOptions = {};

  return new ApolloClient({
    link,
    cache,
    defaultOptions
  });
}
