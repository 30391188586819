import { lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PageLoader from "../components/PageLoader/PageLoader";
import Login from "../pages/Login/Login";

const HomePage = lazy(() => import("../pages/Home/Home"));
const CompanyList = lazy(() => import("../pages/Company/CompanyList/CompanyList"));
const CompanyNew = lazy(() => import("../pages/Company/CompanyNew/CompanyNew"));

export default function AppRouter() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />
    },
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/companies/new",
      element: <CompanyNew />
    },
    {
      path: "/companies",
      element: <CompanyList />
    }
  ]);

  return <RouterProvider router={router} fallbackElement={<PageLoader />} />;
}
