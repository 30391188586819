const config = {
  apiKey: "AIzaSyD1KpmtsngV10WsOdNWJKSFEZxICg9E-j4",
  authDomain: "jupiter-ams-development.firebaseapp.com",
  databaseURL: "https://jupiter-ams-development-default-rtdb.firebaseio.com",
  projectId: "jupiter-ams-development",
  storageBucket: "jupiter-ams-development.appspot.com",
  messagingSenderId: "55727517742",
  appId: "1:55727517742:web:d44bb52f37fd92e58f2fbf",
  measurementId: "G-W63WWG5JQD"
};

export default config;
