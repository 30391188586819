import { Google } from "@mui/icons-material";
import { Box, Button, Card, CardContent, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import CenteredLayout from "../../layouts/CenteredLayout";
import isOffline from "../../libs/isOffline";

export default function LoginPage() {
  const { config, signIn, user } = useAuth();
  const [email, setEmail] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();
  const onSignInClick = () => {
    if (!isOffline()) {
      return signIn();
    }

    cookies.set("offlineSignInEmail", email);
    window.location.reload();
  };

  useEffect(() => {
    if (user) {
      const params = new URLSearchParams(location.search);
      const redirect = decodeURIComponent(params.get("next") || "/");
      navigate(redirect);
    }
  }, [user, navigate, location.search]);

  return (
    <CenteredLayout>
      <Card>
        <CardContent>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              p: 2
            }}
          >
            {isOffline() && (
              <TextField
                fullWidth
                label="Email"
                inputProps={{ type: "email" }}
                autoComplete="false"
                autoFocus
                placeholder="Enter the email to sign in"
                value={email}
                onChange={event => setEmail(event.target.value)}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    return onSignInClick();
                  }
                }}
              />
            )}
            <Button
              variant="contained"
              size="large"
              sx={{ mt: 4, mb: 4 }}
              startIcon={<Google />}
              onClick={onSignInClick}
            >
              Log In
            </Button>
          </Box>
          <Typography variant="subtitle2" color="text.secondary" sx={{ textAlign: "center" }}>
            Powered by JupiterAMS, v{config?.version}
          </Typography>
        </CardContent>
      </Card>
    </CenteredLayout>
  );
}
