import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ApolloProvider } from "@apollo/client";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import createClient from "./libs/graphql/createClient";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AuthProvider } from "./contexts/auth";
import Root from "./Root";
import { Suspense } from "react";
import vi from "date-fns/locale/vi";

const client = createClient();

export default function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <DndProvider backend={HTML5Backend}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={vi}>
          <ApolloProvider client={client}>
            <AuthProvider>
              <Root />
            </AuthProvider>
          </ApolloProvider>
        </LocalizationProvider>
      </DndProvider>
    </Suspense>
  );
}
