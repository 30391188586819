import { setContext } from "@apollo/client/link/context";
import cookies from "js-cookie";

export default function createAuthLink() {
  return setContext((_, { headers }) => {
    const offlineSignInEmail = cookies.get("offlineSignInEmail");
    const token = cookies.get("token");
    const headersToReturn = {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    };

    if (offlineSignInEmail) {
      headersToReturn["x-offline-sign-in-email"] = offlineSignInEmail;
    }

    return {
      headers: headersToReturn
    };
  });
}
