import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  Currency: any;
  DID: any;
  Date: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  SafeInt: any;
  Time: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  Upload: any;
  UtcOffset: any;
  Void: any;
};

export type AigContractPrerequisiteList = {
  __typename?: 'AIGContractPrerequisiteList';
  directDeposit?: Maybe<Scalars['Boolean']>;
  eo?: Maybe<Scalars['Boolean']>;
  fieldManual?: Maybe<Scalars['Boolean']>;
  producerLicense?: Maybe<Scalars['Boolean']>;
};

export type AccountType = {
  __typename?: 'AccountType';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type Activity = {
  __typename?: 'Activity';
  belongsToBackOffice?: Maybe<Scalars['Boolean']>;
  category?: Maybe<ActivityCategory>;
  categoryId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCompanyRole>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isLinkToItem?: Maybe<Scalars['Boolean']>;
  itemLink?: Maybe<Scalars['String']>;
  itemText?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['ID']>;
  recipientUser?: Maybe<UserCompanyRole>;
  subject: Scalars['String'];
};

export type ActivityCategory = {
  __typename?: 'ActivityCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ActivityFilterInput = {
  activityCategories?: InputMaybe<FilterIdsInput>;
  agents?: InputMaybe<FilterIdsInput>;
  createdAt?: InputMaybe<DateTimeFilterQueryInput>;
  isBackOffice?: InputMaybe<FilterBooleanInput>;
  isMyActivities?: InputMaybe<FilterBooleanInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
  workflows?: InputMaybe<FilterIdsInput>;
};

export type AdjustmentReviewState = {
  __typename?: 'AdjustmentReviewState';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type AgentApplicationBackgroundQuestion = {
  __typename?: 'AgentApplicationBackgroundQuestion';
  answer?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  question: Scalars['String'];
};

export type AgentApplicationBackgroundQuestionInput = {
  answer?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  question: Scalars['String'];
};

export type AgentApplicationEmployment = {
  __typename?: 'AgentApplicationEmployment';
  companyAddress1?: Maybe<Scalars['String']>;
  companyAddress2?: Maybe<Scalars['String']>;
  companyCity?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyState?: Maybe<Scalars['String']>;
  companyZip?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isCurrent: Scalars['Boolean'];
  isUnemployed: Scalars['Boolean'];
  position?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  user?: Maybe<User>;
  userCompanyRoleId?: Maybe<Scalars['ID']>;
};

export type AgentApplicationEmploymentInput = {
  companyAddress1?: InputMaybe<Scalars['String']>;
  companyAddress2?: InputMaybe<Scalars['String']>;
  companyCity?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyState?: InputMaybe<Scalars['String']>;
  companyZip?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  isCurrent: Scalars['Boolean'];
  isUnemployed: Scalars['Boolean'];
  position?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type AgentApplicationReference = {
  __typename?: 'AgentApplicationReference';
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  userCompanyRoleId?: Maybe<Scalars['ID']>;
};

export type AgentApplicationReferenceInput = {
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type AgentBank = {
  __typename?: 'AgentBank';
  agentId: Scalars['ID'];
  agentUser?: Maybe<UserCompanyRole>;
  balance?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  lastTransactionId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
};

export type AgentBankFilterInput = {
  agents?: InputMaybe<FilterIdsInput>;
  balance?: InputMaybe<NumberFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type AgentBankTransaction = {
  __typename?: 'AgentBankTransaction';
  agentId: Scalars['ID'];
  agentUser?: Maybe<UserCompanyRole>;
  balanceAfter?: Maybe<Scalars['Float']>;
  balanceBefore?: Maybe<Scalars['Float']>;
  commissionStatementId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  creditAmount?: Maybe<Scalars['Float']>;
  debitAmount?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['BigInt']>;
  fileUrl?: Maybe<Scalars['String']>;
  finalizedAt?: Maybe<Scalars['DateTime']>;
  finalizedBy?: Maybe<UserCompanyRole>;
  id: Scalars['ID'];
  isFinalizedMarkable?: Maybe<Scalars['Boolean']>;
  isSystemGenerated?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
};

export type AgentBankTransactionFilterInput = {
  agents?: InputMaybe<FilterIdsInput>;
  createdAt?: InputMaybe<DateTimeFilterQueryInput>;
  creditAmount?: InputMaybe<NumberFilterInput>;
  debitAmount?: InputMaybe<NumberFilterInput>;
  query?: InputMaybe<FilterQueryInput>;
  states?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type AgentBankTransactionState = {
  __typename?: 'AgentBankTransactionState';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type AgentBankTransactionType = {
  __typename?: 'AgentBankTransactionType';
  code: Scalars['ID'];
  isUserInputAccept?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
};

export type AgentDocumentInput = {
  categoryId?: InputMaybe<Scalars['ID']>;
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  size: Scalars['BigInt'];
  tmpFileUrl: Scalars['String'];
};

export type AgentExtraInfo = {
  __typename?: 'AgentExtraInfo';
  acknowledgedDateFieldManual?: Maybe<Scalars['DateTime']>;
  base64ApplicantSignature?: Maybe<Scalars['String']>;
  base64ApproverSignature?: Maybe<Scalars['String']>;
  base64SignatureFieldManual?: Maybe<Scalars['String']>;
  defaultStatementDeductionPercentage?: Maybe<Scalars['Float']>;
  driverLicenseConfirmingText?: Maybe<Scalars['String']>;
  driverLicenseFileName?: Maybe<Scalars['String']>;
  driverLicenseFileSize?: Maybe<Scalars['BigInt']>;
  driverLicenseFileUrl?: Maybe<Scalars['String']>;
  driverLicenseStatus?: Maybe<Scalars['String']>;
  exchangeSaleId?: Maybe<Scalars['ID']>;
  exchangeSaleNumber?: Maybe<Scalars['String']>;
  exchangeSaleWrittenDate?: Maybe<Scalars['Date']>;
  isAgreeTermsFieldManual?: Maybe<Scalars['Boolean']>;
  isAgreementResignedAt?: Maybe<Scalars['DateTime']>;
  isPushNotification?: Maybe<Scalars['Boolean']>;
  licenseId?: Maybe<Scalars['ID']>;
  licenseIssueDate?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  printedNameFieldManual?: Maybe<Scalars['String']>;
  statusFieldManual?: Maybe<Scalars['String']>;
  userCompanyRoleId: Scalars['ID'];
};

export type AgentFileEntry = {
  __typename?: 'AgentFileEntry';
  agentId?: Maybe<Scalars['ID']>;
  category?: Maybe<FileCategory>;
  categoryId?: Maybe<Scalars['ID']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCompanyRole>;
  createdByUserId?: Maybe<Scalars['ID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  id: Scalars['ID'];
  size: Scalars['Int'];
};

export type AgentFilterAgentLevelInput = {
  agentLevelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type AgentFilterInput = {
  agentIds?: InputMaybe<FilterQueryInput>;
  agentLevels?: InputMaybe<AgentFilterAgentLevelInput>;
  agentStates?: InputMaybe<FilterIdsInput>;
  agents?: InputMaybe<FilterIdsInput>;
  baseshopMembersOf?: InputMaybe<FilterIdsInput>;
  dateApplied?: InputMaybe<DateTimeFilterQueryInput>;
  dateReviewed?: InputMaybe<DateTimeFilterQueryInput>;
  documents?: InputMaybe<FilterIdsInput>;
  downlinesOf?: InputMaybe<FilterIdsInput>;
  isAgreementResignedAt?: InputMaybe<DateTimeFilterQueryInput>;
  isAppointed?: InputMaybe<FilterBooleanInput>;
  isCertified?: InputMaybe<FilterBooleanInput>;
  isCertifiedTrainer?: InputMaybe<FilterBooleanInput>;
  isCommissionOnHold?: InputMaybe<FilterBooleanInput>;
  isErrorOmissionApproved?: InputMaybe<FilterBooleanInput>;
  isExchangeSaleCompleted?: InputMaybe<FilterBooleanInput>;
  isInactiveBySystem?: InputMaybe<FilterBooleanInput>;
  isLicensed?: InputMaybe<FilterBooleanInput>;
  isOnlyBaseshopAgent?: InputMaybe<FilterBooleanInput>;
  isSellingPolicies?: InputMaybe<FilterBooleanInput>;
  lastLoggedInAt?: InputMaybe<DateTimeFilterQueryInput>;
  noRecruitsDuration?: InputMaybe<DateTimeFilterQueryInput>;
  noSalesDuration?: InputMaybe<DateTimeFilterQueryInput>;
  query?: InputMaybe<AgentFilterQueryInput>;
  residentStates?: InputMaybe<AgentFilterResidentStateInput>;
  roles?: InputMaybe<FilterIdsInput>;
  sponsors?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<AgentFilterQueryInput>;
};

export type AgentFilterQueryInput = {
  operatorTypeId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};

export type AgentFilterResidentStateInput = {
  codes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type AgentLevel = {
  __typename?: 'AgentLevel';
  id: Scalars['ID'];
  isCertifiedAgent?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isPromoteLevel?: Maybe<Scalars['Boolean']>;
  isStartOfManagerialLevel?: Maybe<Scalars['Boolean']>;
  isStartingLevel?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  level: Scalars['String'];
  name: Scalars['String'];
  position: Scalars['Int'];
  title: Scalars['String'];
};

export type AgentLicensingState = {
  __typename?: 'AgentLicensingState';
  downlineLicensed?: Maybe<Scalars['Boolean']>;
  stateCode?: Maybe<Scalars['ID']>;
  uplineLicensed?: Maybe<Scalars['Boolean']>;
};

export type AgentMemoFilterInput = {
  isViewed?: InputMaybe<FilterBooleanInput>;
  publishedAt?: InputMaybe<DateTimeFilterQueryInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
  viewedAt?: InputMaybe<DateTimeFilterQueryInput>;
};

export type AgentType = {
  __typename?: 'AgentType';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  description: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  plainTextKey?: Maybe<Scalars['String']>;
};

export type ApprovedLicensingMapFilterInput = {
  carrierFilterValues?: InputMaybe<FilterQueryInput>;
  productTypeFilterValues?: InputMaybe<FilterQueryInput>;
};

export type AttachFileInput = {
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  size: Scalars['BigInt'];
  tmpFileUrl: Scalars['String'];
};

export type BraintreeBillingAddress = {
  __typename?: 'BraintreeBillingAddress';
  company?: Maybe<Scalars['String']>;
  countryCodeAlpha2?: Maybe<Scalars['String']>;
  countryCodeAlpha3?: Maybe<Scalars['String']>;
  countryCodeNumeric?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  extendedAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  maskedNumber?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type BraintreePaymentMethod = {
  __typename?: 'BraintreePaymentMethod';
  bin?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  cardholderName?: Maybe<Scalars['String']>;
  commercial?: Maybe<Scalars['String']>;
  countryOfIssuance?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customerGlobalId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  customerLocation?: Maybe<Scalars['String']>;
  debit?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  durbinRegulated?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  expired?: Maybe<Scalars['Boolean']>;
  globalId?: Maybe<Scalars['String']>;
  graphQLId?: Maybe<Scalars['String']>;
  healthcare?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isNetworkTokenized?: Maybe<Scalars['Boolean']>;
  issuingBank?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  maskedNumber?: Maybe<Scalars['String']>;
  payroll?: Maybe<Scalars['String']>;
  prepaid?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  subscriptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  token?: Maybe<Scalars['String']>;
  uniqueNumberIdentifier?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  venmoSdk?: Maybe<Scalars['Boolean']>;
  verifications?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CalculationMethod = {
  __typename?: 'CalculationMethod';
  id: Scalars['ID'];
  isCalculation?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  description?: Maybe<Scalars['String']>;
  end?: Maybe<TimeZoneDate>;
  htmlLink?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  recurrence?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<TimeZoneDate>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type Carrier = {
  __typename?: 'Carrier';
  ftpPassword?: Maybe<Scalars['String']>;
  ftpPath?: Maybe<Scalars['String']>;
  ftpServerAddress?: Maybe<Scalars['String']>;
  ftpServers?: Maybe<Array<Maybe<CarrierFtp>>>;
  ftpUsername?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAllowManualUploadRawCommissionFile?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isFtpSyncEnabled?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  isVisibleOnLicensingMap?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  providerId: Scalars['String'];
  removeFilesInFTPAfterSync?: Maybe<Scalars['Boolean']>;
  shortName?: Maybe<Scalars['String']>;
};

export type CarrierAppointment = {
  __typename?: 'CarrierAppointment';
  agent?: Maybe<UserCompanyRole>;
  carrier?: Maybe<Carrier>;
  carrierId: Scalars['ID'];
  confirmingText?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  fileSize?: Maybe<Scalars['BigInt']>;
  fileUrl: Scalars['String'];
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isDownloadable?: Maybe<Scalars['Boolean']>;
  isReassignable?: Maybe<Scalars['Boolean']>;
  isSubmittable?: Maybe<Scalars['Boolean']>;
  isTerminatable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  producerCode: Scalars['String'];
  rootProductTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  state: Scalars['String'];
  stateCodes: Array<Maybe<Scalars['ID']>>;
  stateName?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  terminationDate?: Maybe<Scalars['DateTime']>;
  userCompanyRoleId: Scalars['ID'];
};

export type CarrierAppointmentFilterInput = {
  carrierAppointmentStates?: InputMaybe<FilterIdsInput>;
  carriers?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type CarrierAppointmentState = {
  __typename?: 'CarrierAppointmentState';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type CarrierFtp = {
  __typename?: 'CarrierFtp';
  carrerId: Scalars['String'];
  ftpPassword?: Maybe<Scalars['String']>;
  ftpPath?: Maybe<Scalars['String']>;
  ftpServerAddress?: Maybe<Scalars['String']>;
  ftpUsername?: Maybe<Scalars['String']>;
};

export type CarrierFtpInput = {
  ftpPassword?: InputMaybe<Scalars['String']>;
  ftpPath: Scalars['String'];
  ftpServerAddress: Scalars['String'];
  ftpUsername: Scalars['String'];
};

export type CarrierReceivingCommissionReportType = {
  __typename?: 'CarrierReceivingCommissionReportType';
  carrier?: Maybe<Carrier>;
  carrierId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type CommissionAdjustment = {
  __typename?: 'CommissionAdjustment';
  adjustedAt?: Maybe<Scalars['DateTime']>;
  agentId: Scalars['ID'];
  agentUser?: Maybe<UserCompanyRole>;
  amount?: Maybe<Scalars['Float']>;
  category?: Maybe<CommissionAdjustmentCategory>;
  categoryCode: Scalars['ID'];
  commissionCalculatedEntryId?: Maybe<Scalars['ID']>;
  commissionEntryId?: Maybe<Scalars['ID']>;
  commissionStatement?: Maybe<CommissionStatement>;
  commissionStatementId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  excludeUntil?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  policy?: Maybe<Policy>;
  policyId?: Maybe<Scalars['ID']>;
  reason?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
};

export type CommissionAdjustmentCategory = {
  __typename?: 'CommissionAdjustmentCategory';
  code: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type CommissionAdjustmentFilterInput = {
  agents?: InputMaybe<FilterIdsInput>;
  amount?: InputMaybe<NumberFilterInput>;
  commissionAdjustmentCategories?: InputMaybe<FilterIdsInput>;
  createdAt?: InputMaybe<DateTimeFilterQueryInput>;
  isNotIncludedInAnyStatement?: InputMaybe<FilterBooleanInput>;
  policies?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  statements?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type CommissionAdjustmentGeneratedInput = {
  agentCode?: InputMaybe<Scalars['String']>;
  agentId?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  commissionCalculatedEntryId?: InputMaybe<Scalars['String']>;
  commissionEntryId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  policyId?: InputMaybe<Scalars['String']>;
  policyNumber?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type CommissionAdjustmentReview = {
  __typename?: 'CommissionAdjustmentReview';
  adjustmentReviewState?: Maybe<AdjustmentReviewState>;
  createdAt?: Maybe<Scalars['BigInt']>;
  createdByUser?: Maybe<UserCompanyRole>;
  createdByUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  state: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['Int']>;
};

export type CommissionAdjustmentReviewItem = {
  __typename?: 'CommissionAdjustmentReviewItem';
  agentId: Scalars['ID'];
  agentUser?: Maybe<UserCompanyRole>;
  amount?: Maybe<Scalars['Float']>;
  commissionAdjustmentCategoryCode?: Maybe<Scalars['String']>;
  commissionCalculatedEntryId?: Maybe<Scalars['ID']>;
  commissionEntryId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  isReviewed?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  policy?: Maybe<Policy>;
  policyId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};

export type CommissionAdjustmentReviewItemEditInput = {
  category: Scalars['String'];
  id: Scalars['ID'];
  note: Scalars['String'];
  reason: Scalars['String'];
};

export type CommissionAdjustmentReviewItemInput = {
  agentId: Scalars['ID'];
  amount?: InputMaybe<Scalars['Float']>;
  commissionCalculatedEntryId?: InputMaybe<Scalars['ID']>;
  commissionEntryId: Scalars['ID'];
  policyId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type CommissionAdjustmentReviewItemListFilterInput = {
  agents?: InputMaybe<FilterIdsInput>;
  commissionEntryId?: InputMaybe<FilterQueryInput>;
  policyNumber?: InputMaybe<FilterQueryInput>;
};

export type CommissionAssignment = {
  __typename?: 'CommissionAssignment';
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  agent?: Maybe<UserCompanyRole>;
  businessName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  confirmingText?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employerIdentificationNumber?: Maybe<Scalars['String']>;
  exemptFromFATCAReportingCode?: Maybe<Scalars['String']>;
  exemptPayeeCode?: Maybe<Scalars['String']>;
  federalTaxClassification?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActivable?: Maybe<Scalars['Boolean']>;
  isApprovable?: Maybe<Scalars['Boolean']>;
  isAuthorizationViewable?: Maybe<Scalars['Boolean']>;
  isDeactivable?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isDownloadable?: Maybe<Scalars['Boolean']>;
  isReassignCommission?: Maybe<Scalars['Boolean']>;
  isRecallable?: Maybe<Scalars['Boolean']>;
  isRejectable?: Maybe<Scalars['Boolean']>;
  isReturnable?: Maybe<Scalars['Boolean']>;
  isSubmitable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ownershipType?: Maybe<Scalars['String']>;
  reviewedDate?: Maybe<Scalars['DateTime']>;
  routingNumber?: Maybe<Scalars['String']>;
  signedCommissionAssignmentFormFileName?: Maybe<Scalars['String']>;
  signedCommissionAssignmentFormFileSize?: Maybe<Scalars['BigInt']>;
  signedCommissionAssignmentFormFileUrl?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  taxpayerType?: Maybe<Scalars['String']>;
  userCompanyRoleId: Scalars['ID'];
  voidedCheckFiles?: Maybe<Array<Maybe<CommissionAssignmentFile>>>;
  w9Files?: Maybe<Array<Maybe<CommissionAssignmentFile>>>;
  zip?: Maybe<Scalars['String']>;
};

export type CommissionAssignmentExtraInfo = {
  __typename?: 'CommissionAssignmentExtraInfo';
  acknowledgedDate?: Maybe<Scalars['DateTime']>;
  base64Signature?: Maybe<Scalars['String']>;
  commissionAssignmentId: Scalars['ID'];
  isAgreeTerms?: Maybe<Scalars['Boolean']>;
  printedName?: Maybe<Scalars['String']>;
  userCompanyRoleId: Scalars['ID'];
};

export type CommissionAssignmentFile = {
  __typename?: 'CommissionAssignmentFile';
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['BigInt']>;
  fileUrl?: Maybe<Scalars['String']>;
};

export type CommissionAssignmentFileInput = {
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['BigInt']>;
  fileUrl?: InputMaybe<Scalars['String']>;
  isUploaded?: InputMaybe<Scalars['Boolean']>;
};

export type CommissionAssignmentFilterInput = {
  assignmentStates?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type CommissionAssignmentStates = {
  __typename?: 'CommissionAssignmentStates';
  backgroundColor?: Maybe<Scalars['String']>;
  code: Scalars['ID'];
  color?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CommissionCalculatedEntriesOnHold = {
  __typename?: 'CommissionCalculatedEntriesOnHold';
  agent?: Maybe<UserCompanyRole>;
  entriesOnHold?: Maybe<Scalars['JSONObject']>;
  totalAdjustedPayout?: Maybe<Scalars['Float']>;
};

export type CommissionCalculatedEntry = {
  __typename?: 'CommissionCalculatedEntry';
  adjustedPayout?: Maybe<Scalars['Float']>;
  adjustedPayoutRemarks?: Maybe<Scalars['String']>;
  advanceRate?: Maybe<Scalars['Float']>;
  advertisedPayoutPercentage?: Maybe<Scalars['Float']>;
  agent?: Maybe<UserCompanyRole>;
  agentId: Scalars['ID'];
  agentLevel?: Maybe<AgentLevel>;
  agentLevelId?: Maybe<Scalars['ID']>;
  agentStatusAtIssueDate?: Maybe<Scalars['String']>;
  agentStatusAtProcessCommissionDate?: Maybe<Scalars['String']>;
  agentStatusAtWrittenDate?: Maybe<Scalars['String']>;
  calculatedPayoutPercentage?: Maybe<Scalars['Float']>;
  carrierAppointmentAtIssueDate?: Maybe<CarrierAppointment>;
  carrierAppointmentAtProcessCommissionDate?: Maybe<CarrierAppointment>;
  carrierAppointmentAtWrittenDate?: Maybe<CarrierAppointment>;
  carrierAppointmentIdAtIssueDate?: Maybe<Scalars['ID']>;
  carrierAppointmentIdAtProcessCommissionDate?: Maybe<Scalars['ID']>;
  carrierAppointmentIdAtWrittenDate?: Maybe<Scalars['ID']>;
  carrierId?: Maybe<Scalars['ID']>;
  commissionCycle?: Maybe<CommissionCycle>;
  commissionEntry?: Maybe<CommissionEntry>;
  commissionEntryId: Scalars['ID'];
  commissionStatement?: Maybe<CommissionStatement>;
  commissionStatementId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  createdByUserId: Scalars['ID'];
  deliveryReceiptApprovedDate?: Maybe<Scalars['DateTime']>;
  eAndOAtIssueDate?: Maybe<EAndO>;
  eAndOAtProcessCommissionDate?: Maybe<EAndO>;
  eAndOAtWrittenDate?: Maybe<EAndO>;
  eAndOIdAtIssueDate?: Maybe<Scalars['ID']>;
  eAndOIdAtProcessCommissionDate?: Maybe<Scalars['ID']>;
  eAndOIdAtWrittenDate?: Maybe<Scalars['ID']>;
  excessPercentage?: Maybe<Scalars['Float']>;
  firstYearPercentage?: Maybe<Scalars['Float']>;
  generationDepth?: Maybe<Scalars['Int']>;
  generationalOverridePercentage?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  ineligibleReason?: Maybe<Scalars['String']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isEligibleForPayment?: Maybe<Scalars['Boolean']>;
  isHoldable?: Maybe<Scalars['Boolean']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  isOnHold?: Maybe<Scalars['Boolean']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  isPremiumDerived?: Maybe<Scalars['Boolean']>;
  isQualifiedForGenerationalOverrides?: Maybe<Scalars['Boolean']>;
  isReceivingCapUsed?: Maybe<Scalars['Boolean']>;
  isUnHoldable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  levelHistoryId?: Maybe<Scalars['ID']>;
  licenseInfoAtIssueDate?: Maybe<LicenseInfo>;
  licenseInfoAtProcessCommissionDate?: Maybe<LicenseInfo>;
  licenseInfoAtWrittenDate?: Maybe<LicenseInfo>;
  licenseInfoIdAtIssueDate?: Maybe<Scalars['ID']>;
  licenseInfoIdAtProcessCommissionDate?: Maybe<Scalars['ID']>;
  licenseInfoIdAtWrittenDate?: Maybe<Scalars['ID']>;
  notRecalculableReason?: Maybe<Scalars['String']>;
  onHoldReason?: Maybe<Scalars['String']>;
  overriddenAdjustedPayout?: Maybe<Scalars['Float']>;
  overriddenExcessPercentage?: Maybe<Scalars['Float']>;
  overriddenFirstYearPercentage?: Maybe<Scalars['Float']>;
  overriddenGenerationalDepth?: Maybe<Scalars['Int']>;
  overriddenGenerationalOverrideNote?: Maybe<Scalars['String']>;
  overriddenGenerationalOverridePercentage?: Maybe<Scalars['Float']>;
  overriddenIsPaymentEligible?: Maybe<Scalars['Boolean']>;
  overriddenIsQualifiedForGenerationalOverride?: Maybe<Scalars['Boolean']>;
  overriddenNote?: Maybe<Scalars['String']>;
  overriddenPayout?: Maybe<Scalars['Float']>;
  overriddenPayoutPercentage?: Maybe<Scalars['Float']>;
  overriddenRenewalPercentage?: Maybe<Scalars['Float']>;
  overriddenRollingBonusPercentage?: Maybe<Scalars['Float']>;
  overriddenServicePercentage?: Maybe<Scalars['Float']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  payout?: Maybe<Scalars['Float']>;
  payoutPercentage?: Maybe<Scalars['Float']>;
  policy?: Maybe<Policy>;
  policyApprovedDate?: Maybe<Scalars['DateTime']>;
  policyId?: Maybe<Scalars['ID']>;
  premium?: Maybe<Scalars['Float']>;
  premiumRemarks?: Maybe<Scalars['String']>;
  producingAgentId?: Maybe<Scalars['ID']>;
  receivingAccount?: Maybe<ReceivingAccount>;
  receivingAccountId?: Maybe<Scalars['ID']>;
  renewalPercentage?: Maybe<Scalars['Float']>;
  rollingBonusPercentage?: Maybe<Scalars['Float']>;
  servicePercentage?: Maybe<Scalars['Float']>;
  splitPercentage?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
  updatedByUserId?: Maybe<Scalars['String']>;
};

export type CommissionCalculatedEntryFilterInput = {
  agentStates?: InputMaybe<FilterIdsInput>;
  agents?: InputMaybe<FilterIdsInput>;
  baseshopMembersOf?: InputMaybe<FilterIdsInput>;
  carriers?: InputMaybe<FilterIdsInput>;
  categories?: InputMaybe<FilterIdsInput>;
  commissionCycleEndDate?: InputMaybe<DateTimeFilterQueryInput>;
  commissionEntryCategoryCode?: InputMaybe<FilterIdsInput>;
  inProgressStatements?: InputMaybe<FilterIdsInput>;
  isChargeback?: InputMaybe<FilterBooleanInput>;
  isIncludable?: InputMaybe<FilterBooleanInput>;
  isOnHold?: InputMaybe<FilterBooleanInput>;
  isOverridden?: InputMaybe<FilterBooleanInput>;
  isPaid?: InputMaybe<FilterBooleanInput>;
  isQualifiedForGenerationalOverrides?: InputMaybe<FilterBooleanInput>;
  paidAt?: InputMaybe<DateTimeFilterQueryInput>;
  paymentEligible?: InputMaybe<FilterBooleanInput>;
  policies?: InputMaybe<FilterIdsInput>;
  policyStates?: InputMaybe<FilterIdsInput>;
  producingAgents?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  rawBatches?: InputMaybe<FilterIdsInput>;
  receivingAccounts?: InputMaybe<FilterIdsInput>;
  statements?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
  transactionDate?: InputMaybe<DateTimeFilterQueryInput>;
  writingAgent?: InputMaybe<FilterIdsInput>;
};

export type CommissionCalculatedEntrySummary = {
  __typename?: 'CommissionCalculatedEntrySummary';
  totalAdjustedPayout?: Maybe<Scalars['Float']>;
  totalEntryCount?: Maybe<Scalars['Int']>;
  totalPaidAdjustedPayout?: Maybe<Scalars['Float']>;
  totalPaidEntryCount?: Maybe<Scalars['Int']>;
  totalUnpaidAdjustedPayout?: Maybe<Scalars['Float']>;
  totalUnpaidEntryCount?: Maybe<Scalars['Int']>;
  totalUnqualifiedGenerationalOverride?: Maybe<Scalars['Int']>;
  totalUnqualifiedGenerationalOverrideAmount?: Maybe<Scalars['Float']>;
};

export type CommissionCarrierAndCategoryBreakdown = {
  __typename?: 'CommissionCarrierAndCategoryBreakdown';
  carrierId: Scalars['ID'];
  commissionEntryCategoryCode: Scalars['String'];
  total?: Maybe<Scalars['Float']>;
};

export type CommissionCarrierBreakdown = {
  __typename?: 'CommissionCarrierBreakdown';
  id: Scalars['ID'];
  paidAmount?: Maybe<Scalars['Float']>;
  receivedAmount?: Maybe<Scalars['Float']>;
};

export type CommissionCategoryBreakdown = {
  __typename?: 'CommissionCategoryBreakdown';
  id: Scalars['ID'];
  paidAmount?: Maybe<Scalars['Float']>;
  receivedAmount?: Maybe<Scalars['Float']>;
};

export type CommissionCycle = {
  __typename?: 'CommissionCycle';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  endDate?: Maybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  isApprovable?: Maybe<Scalars['Boolean']>;
  isBankTransactionFileImportable?: Maybe<Scalars['Boolean']>;
  isBankTransactionFileRemovable?: Maybe<Scalars['Boolean']>;
  isCompleteMarkable?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  isNACHADownloadable?: Maybe<Scalars['Boolean']>;
  isPaymentPendingMarkable?: Maybe<Scalars['Boolean']>;
  isRejectable?: Maybe<Scalars['Boolean']>;
  isRetrievable?: Maybe<Scalars['Boolean']>;
  isSubmittable?: Maybe<Scalars['Boolean']>;
  isTransactionsForQuickBooksDownloadable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numberOfStatements?: Maybe<Scalars['Int']>;
  previousCommissionCycle?: Maybe<CommissionCycle>;
  processDate?: Maybe<Scalars['BigInt']>;
  rejectedReason?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['BigInt']>;
  statementsPerStatus?: Maybe<Array<Maybe<CommissionCycleStatementPerStatus>>>;
  statementsPerStatusFollowNACHA?: Maybe<Array<Maybe<CommissionCycleStatementPerStatus>>>;
  status?: Maybe<Scalars['String']>;
  topPerformers?: Maybe<Array<Maybe<CommissionCycleTopPerformer>>>;
  totalPayoutAmount?: Maybe<Scalars['Float']>;
  totalPayoutByCarriers?: Maybe<Array<Maybe<CommissionCycleCarrier>>>;
  transactionsFileName?: Maybe<Scalars['String']>;
  transactionsFileSize?: Maybe<Scalars['Int']>;
  transactionsFileUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
};

export type CommissionCycleCarrier = {
  __typename?: 'CommissionCycleCarrier';
  carrierId?: Maybe<Scalars['ID']>;
  commissionEntryCategoryCode?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type CommissionCycleFilterInput = {
  commissionCycleStates?: InputMaybe<FilterIdsInput>;
  endDate?: InputMaybe<DateTimeFilterQueryInput>;
  processDate?: InputMaybe<DateTimeFilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type CommissionCycleState = {
  __typename?: 'CommissionCycleState';
  backgroundColor?: Maybe<Scalars['String']>;
  code: Scalars['ID'];
  color?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CommissionCycleStatementPerStatus = {
  __typename?: 'CommissionCycleStatementPerStatus';
  amount?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type CommissionCycleTopPerformer = {
  __typename?: 'CommissionCycleTopPerformer';
  agentUser?: Maybe<UserCompanyRole>;
  total?: Maybe<Scalars['Float']>;
};

export type CommissionEligibilityCheckSuppression = {
  __typename?: 'CommissionEligibilityCheckSuppression';
  actor?: Maybe<UserCompanyRole>;
  createdAt?: Maybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  policy?: Maybe<Policy>;
  policyId: Scalars['ID'];
  userCompanyRole?: Maybe<UserCompanyRole>;
  userCompanyRoleId: Scalars['ID'];
};

export type CommissionEntry = {
  __typename?: 'CommissionEntry';
  advanceAmount?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  agentName?: Maybe<Scalars['String']>;
  agentUser?: Maybe<UserCompanyRole>;
  agentUserCarrierAppointment?: Maybe<CarrierAppointment>;
  agentUserCarrierAppointmentId?: Maybe<Scalars['ID']>;
  annualizedPremium?: Maybe<Scalars['Float']>;
  calculatedEntries?: Maybe<Array<Maybe<CommissionCalculatedEntry>>>;
  calculatedPayoutAmount?: Maybe<Scalars['Float']>;
  carrierId: Scalars['ID'];
  commission?: Maybe<Scalars['Float']>;
  commissionAdjustments?: Maybe<Array<Maybe<CommissionAdjustment>>>;
  commissionBasis?: Maybe<Scalars['Float']>;
  commissionEntryCategoryCode?: Maybe<Scalars['String']>;
  commissionRate?: Maybe<Scalars['Float']>;
  commissionRawBatch?: Maybe<CommissionRawBatch>;
  commissionRawBatchId?: Maybe<Scalars['ID']>;
  commissionRawBatchPolicy?: Maybe<CommissionRawBatchPolicy>;
  commissionType?: Maybe<Scalars['String']>;
  compensationPlan?: Maybe<CompensationPlan>;
  compensationPlanId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  insuredName?: Maybe<Scalars['String']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isDryRecalculatable?: Maybe<Scalars['Boolean']>;
  isOverridden?: Maybe<Scalars['Boolean']>;
  isPayoutEntriesHoldable?: Maybe<Scalars['Boolean']>;
  isPayoutEntriesUnholdable?: Maybe<Scalars['Boolean']>;
  isPolicyNetNegative?: Maybe<Scalars['Boolean']>;
  isPremiumDerived?: Maybe<Scalars['Boolean']>;
  isRecalculatable?: Maybe<Scalars['Boolean']>;
  isReceivingCapUsed?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  monthPaid?: Maybe<Scalars['Int']>;
  overriddenCarrierAppointmentId?: Maybe<Scalars['ID']>;
  overriddenCommissionEntryCategoryCode?: Maybe<Scalars['String']>;
  overriddenCompensationPlanId?: Maybe<Scalars['ID']>;
  overriddenCustomerId?: Maybe<Scalars['ID']>;
  overriddenNote?: Maybe<Scalars['String']>;
  overriddenPolicyId?: Maybe<Scalars['ID']>;
  overriddenProductId?: Maybe<Scalars['ID']>;
  overriddenProductTypeId?: Maybe<Scalars['ID']>;
  overriddenUserAgentId?: Maybe<Scalars['ID']>;
  paidAmount?: Maybe<Scalars['Float']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentMode?: Maybe<Scalars['String']>;
  payoutStatus?: Maybe<Scalars['String']>;
  pendingAmount?: Maybe<Scalars['Float']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  planCode?: Maybe<Scalars['String']>;
  planDescription?: Maybe<Scalars['String']>;
  policy?: Maybe<Policy>;
  policyId?: Maybe<Scalars['ID']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyPremium?: Maybe<Scalars['Float']>;
  policyTargetPremium?: Maybe<Scalars['Float']>;
  policyType?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['ID']>;
  productType?: Maybe<ProductType>;
  productTypeId?: Maybe<Scalars['ID']>;
  profitAmount?: Maybe<Scalars['Float']>;
  receivingAmount?: Maybe<Scalars['Float']>;
  remark?: Maybe<Scalars['String']>;
  rowNumber?: Maybe<Scalars['Int']>;
  sheetName?: Maybe<Scalars['String']>;
  splitPercentage?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  totalAdvancePercentage?: Maybe<Scalars['Float']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  transactionDescription?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
  userAgentId?: Maybe<Scalars['ID']>;
};

export type CommissionEntryCategory = {
  __typename?: 'CommissionEntryCategory';
  backgroundColor: Scalars['String'];
  code: Scalars['ID'];
  commissionEntryCategoryCompany?: Maybe<CommissionEntryCategoryCompany>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  textColor: Scalars['String'];
};

export type CommissionEntryCategoryCompany = {
  __typename?: 'CommissionEntryCategoryCompany';
  backgroundColor?: Maybe<Scalars['String']>;
  code: Scalars['ID'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CommissionEntryFilterInput = {
  agentId?: InputMaybe<FilterQueryInput>;
  agentName?: InputMaybe<FilterQueryInput>;
  agents?: InputMaybe<FilterIdsInput>;
  baseshopMembersOf?: InputMaybe<FilterIdsInput>;
  carriers?: InputMaybe<FilterIdsInput>;
  categories?: InputMaybe<FilterIdsInput>;
  commission?: InputMaybe<NumberFilterInput>;
  commissionEntryCategoryCode?: InputMaybe<FilterIdsInput>;
  commissionRate?: InputMaybe<NumberFilterInput>;
  commissionRawBatches?: InputMaybe<FilterIdsInput>;
  downlinesOf?: InputMaybe<FilterIdsInput>;
  id?: InputMaybe<NumberFilterInput>;
  isOverridden?: InputMaybe<FilterBooleanInput>;
  isPolicyNetNegative?: InputMaybe<FilterBooleanInput>;
  isPublished?: InputMaybe<FilterBooleanInput>;
  issueDate?: InputMaybe<DateTimeFilterQueryInput>;
  maxCarrierTransactionDate?: InputMaybe<FilterQueryInput>;
  payoutStatuses?: InputMaybe<FilterIdsInput>;
  planDescription?: InputMaybe<FilterQueryInput>;
  policies?: InputMaybe<FilterIdsInput>;
  policyNumber?: InputMaybe<FilterQueryInput>;
  policyPremium?: InputMaybe<NumberFilterInput>;
  policyTargetPremium?: InputMaybe<NumberFilterInput>;
  policyWrittenDate?: InputMaybe<DateTimeFilterQueryInput>;
  processStates?: InputMaybe<FilterIdsInput>;
  productTypes?: InputMaybe<FilterIdsInput>;
  products?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  rawBatches?: InputMaybe<FilterIdsInput>;
  splitPercentage?: InputMaybe<NumberFilterInput>;
  text?: InputMaybe<FilterQueryInput>;
  transactionDate?: InputMaybe<DateTimeFilterQueryInput>;
  transactionDescription?: InputMaybe<FilterQueryInput>;
};

export type CommissionEntryRawQuery = {
  __typename?: 'CommissionEntryRawQuery';
  agentId?: Maybe<Scalars['String']>;
  agentName?: Maybe<Scalars['String']>;
  agentUserCarrierAppointmentId?: Maybe<Scalars['ID']>;
  annualizedCommission?: Maybe<Scalars['Float']>;
  annualizedPremium?: Maybe<Scalars['Float']>;
  carrierId?: Maybe<Scalars['ID']>;
  commission?: Maybe<Scalars['Float']>;
  commissionEntryCategoryCode?: Maybe<Scalars['String']>;
  commissionRate?: Maybe<Scalars['Float']>;
  commissionRawBatchId?: Maybe<Scalars['ID']>;
  commissionType?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  compensationPlanId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  insuredName?: Maybe<Scalars['String']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  isPolicyNetNegative?: Maybe<Scalars['Boolean']>;
  isPremiumDerived?: Maybe<Scalars['Boolean']>;
  isReceivingCapUsed?: Maybe<Scalars['Boolean']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  monthPaid?: Maybe<Scalars['Int']>;
  overriddenCarrierAppointmentId?: Maybe<Scalars['ID']>;
  overriddenCommissionEntryCategoryCode?: Maybe<Scalars['String']>;
  overriddenCompensationPlanId?: Maybe<Scalars['ID']>;
  overriddenCustomerId?: Maybe<Scalars['ID']>;
  overriddenNote?: Maybe<Scalars['String']>;
  overriddenPolicyId?: Maybe<Scalars['ID']>;
  overriddenProductId?: Maybe<Scalars['ID']>;
  overriddenProductTypeId?: Maybe<Scalars['ID']>;
  overriddenUserAgentId?: Maybe<Scalars['ID']>;
  paidDate?: Maybe<Scalars['DateTime']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentMode?: Maybe<Scalars['String']>;
  payoutStatus?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  planCode?: Maybe<Scalars['String']>;
  planDescription?: Maybe<Scalars['String']>;
  policyId?: Maybe<Scalars['ID']>;
  policyNumber?: Maybe<Scalars['String']>;
  policyPremium?: Maybe<Scalars['Float']>;
  policyTargetPremium?: Maybe<Scalars['Float']>;
  policyType?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  productTypeId?: Maybe<Scalars['ID']>;
  remark?: Maybe<Scalars['String']>;
  rowNumber?: Maybe<Scalars['Int']>;
  sheetName?: Maybe<Scalars['String']>;
  splitPercentage?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  submitDate?: Maybe<Scalars['DateTime']>;
  totalAdvancePercentage?: Maybe<Scalars['Float']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  transactionDescription?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedByUserId?: Maybe<Scalars['ID']>;
  userAgentId?: Maybe<Scalars['ID']>;
};

export type CommissionEntryRawQueryInput = {
  agentId?: InputMaybe<Scalars['String']>;
  agentName?: InputMaybe<Scalars['String']>;
  agentUserCarrierAppointmentId?: InputMaybe<Scalars['ID']>;
  annualizedCommission?: InputMaybe<Scalars['Float']>;
  annualizedPremium?: InputMaybe<Scalars['Float']>;
  carrierId?: InputMaybe<Scalars['ID']>;
  commission?: InputMaybe<Scalars['Float']>;
  commissionEntryCategoryCode?: InputMaybe<Scalars['String']>;
  commissionRate?: InputMaybe<Scalars['Float']>;
  commissionRawBatchId?: InputMaybe<Scalars['ID']>;
  commissionType?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  compensationPlanId?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdByUserId?: InputMaybe<Scalars['ID']>;
  customerId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  insuredName?: InputMaybe<Scalars['String']>;
  isLegacy?: InputMaybe<Scalars['Boolean']>;
  isPolicyNetNegative?: InputMaybe<Scalars['Boolean']>;
  isPremiumDerived?: InputMaybe<Scalars['Boolean']>;
  isReceivingCapUsed?: InputMaybe<Scalars['Boolean']>;
  issueDate?: InputMaybe<Scalars['DateTime']>;
  message?: InputMaybe<Scalars['String']>;
  monthPaid?: InputMaybe<Scalars['Int']>;
  overriddenCarrierAppointmentId?: InputMaybe<Scalars['ID']>;
  overriddenCommissionEntryCategoryCode?: InputMaybe<Scalars['String']>;
  overriddenCompensationPlanId?: InputMaybe<Scalars['ID']>;
  overriddenCustomerId?: InputMaybe<Scalars['ID']>;
  overriddenNote?: InputMaybe<Scalars['String']>;
  overriddenPolicyId?: InputMaybe<Scalars['ID']>;
  overriddenProductId?: InputMaybe<Scalars['ID']>;
  overriddenProductTypeId?: InputMaybe<Scalars['ID']>;
  overriddenUserAgentId?: InputMaybe<Scalars['ID']>;
  paidDate?: InputMaybe<Scalars['DateTime']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
  paymentMode?: InputMaybe<Scalars['String']>;
  payoutStatus?: InputMaybe<Scalars['String']>;
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  periodStart?: InputMaybe<Scalars['DateTime']>;
  planCode?: InputMaybe<Scalars['String']>;
  planDescription?: InputMaybe<Scalars['String']>;
  policyId?: InputMaybe<Scalars['ID']>;
  policyNumber?: InputMaybe<Scalars['String']>;
  policyPremium?: InputMaybe<Scalars['Float']>;
  policyTargetPremium?: InputMaybe<Scalars['Float']>;
  policyType?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['ID']>;
  productTypeId?: InputMaybe<Scalars['ID']>;
  remark?: InputMaybe<Scalars['String']>;
  rowNumber?: InputMaybe<Scalars['Int']>;
  sheetName?: InputMaybe<Scalars['String']>;
  splitPercentage?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  submitDate?: InputMaybe<Scalars['DateTime']>;
  totalAdvancePercentage?: InputMaybe<Scalars['Float']>;
  transactionDate?: InputMaybe<Scalars['DateTime']>;
  transactionDescription?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedByUserId?: InputMaybe<Scalars['ID']>;
  userAgentId?: InputMaybe<Scalars['ID']>;
};

export type CommissionOverviewFilterInput = {
  carriers?: InputMaybe<FilterIdsInput>;
  policyIssuedDate?: InputMaybe<DateFilterQueryInput>;
  policyWrittenDate?: InputMaybe<DateFilterQueryInput>;
  transactionDate?: InputMaybe<DateFilterQueryInput>;
};

export type CommissionOverviewStats = {
  __typename?: 'CommissionOverviewStats';
  carrierBreakdowns?: Maybe<Array<Maybe<CommissionCarrierBreakdown>>>;
  commissionCategoryBreakdowns?: Maybe<Array<Maybe<CommissionCategoryBreakdown>>>;
  paidCommissionCarrierAndCategoryBreakdowns?: Maybe<Array<Maybe<CommissionCarrierAndCategoryBreakdown>>>;
  receivedCommissionCarrierAndCategoryBreakdowns?: Maybe<Array<Maybe<CommissionCarrierAndCategoryBreakdown>>>;
  topChargebackAgents?: Maybe<Array<Maybe<TopChargebackAgent>>>;
  topChargebackBaseshops?: Maybe<Array<Maybe<TopChargebackBaseshop>>>;
  topProfitableAgents?: Maybe<Array<Maybe<TopProfitableAgent>>>;
  topProfitableBaseshops?: Maybe<Array<Maybe<TopProfitableBaseshop>>>;
  totalCommissionAmount?: Maybe<TotalCommissionAmount>;
};


export type CommissionOverviewStatsTopChargebackAgentsArgs = {
  order?: InputMaybe<Scalars['String']>;
};


export type CommissionOverviewStatsTopChargebackBaseshopsArgs = {
  order?: InputMaybe<Scalars['String']>;
};


export type CommissionOverviewStatsTopProfitableAgentsArgs = {
  order?: InputMaybe<Scalars['String']>;
};


export type CommissionOverviewStatsTopProfitableBaseshopsArgs = {
  order?: InputMaybe<Scalars['String']>;
};

export type CommissionPayoutByType = {
  __typename?: 'CommissionPayoutByType';
  paidPayout?: Maybe<Scalars['Float']>;
  pendingPayout?: Maybe<Scalars['Float']>;
  received?: Maybe<Scalars['Float']>;
};

export type CommissionPayoutMargin = {
  __typename?: 'CommissionPayoutMargin';
  amount?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type CommissionProcessState = {
  __typename?: 'CommissionProcessState';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type CommissionRawBatch = {
  __typename?: 'CommissionRawBatch';
  carrier?: Maybe<Carrier>;
  carrierId: Scalars['ID'];
  commissionPayout?: Maybe<Scalars['Float']>;
  commissionReceived?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  fileUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  policyCount?: Maybe<Scalars['Int']>;
  reportType?: Maybe<CarrierReceivingCommissionReportType>;
  reportTypeId: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  totalDuplicateEntries?: Maybe<Scalars['Int']>;
  totalEntries?: Maybe<Scalars['Int']>;
  totalExcludedEntries?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
};

export type CommissionRawBatchEntriesPerCategory = {
  __typename?: 'CommissionRawBatchEntriesPerCategory';
  categoryCode?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  commissionRawBatchId: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
};

export type CommissionRawBatchEntriesPerStatus = {
  __typename?: 'CommissionRawBatchEntriesPerStatus';
  commissionRawBatchId: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
};

export type CommissionRawBatchFilterInput = {
  carriers?: InputMaybe<FilterIdsInput>;
  createdAt?: InputMaybe<DateTimeFilterQueryInput>;
  isLegacy?: InputMaybe<FilterBooleanInput>;
  isPaid?: InputMaybe<FilterBooleanInput>;
  processStates?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type CommissionRawBatchPolicy = {
  __typename?: 'CommissionRawBatchPolicy';
  commissionRawBatchId: Scalars['ID'];
  id: Scalars['ID'];
  netAmount?: Maybe<Scalars['Float']>;
  numberOfPaidEntries?: Maybe<Scalars['Float']>;
  numberOfReceivingEntries?: Maybe<Scalars['Float']>;
  paidAmount?: Maybe<Scalars['Float']>;
  policyID: Scalars['ID'];
  receivedAmount?: Maybe<Scalars['Float']>;
};

export type CommissionRawBatchTopProducingAgent = {
  __typename?: 'CommissionRawBatchTopProducingAgent';
  agentId: Scalars['ID'];
  agentName?: Maybe<Scalars['String']>;
  commissionRawBatchId: Scalars['ID'];
  id: Scalars['ID'];
  total?: Maybe<Scalars['Float']>;
};

export type CommissionStatement = {
  __typename?: 'CommissionStatement';
  agentBankTransaction?: Maybe<AgentBankTransaction>;
  agents?: Maybe<Array<Maybe<UserCompanyRole>>>;
  beginningBalance?: Maybe<Scalars['Float']>;
  calculatedEntries?: Maybe<Array<Maybe<CommissionCalculatedEntry>>>;
  commissionCycle?: Maybe<CommissionCycle>;
  commissionCycleId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  endingBalance?: Maybe<Scalars['Float']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  ineligibleGenerationalOverridesAmount?: Maybe<Scalars['Float']>;
  isAdjustmentsCreatable?: Maybe<Scalars['Boolean']>;
  isAdjustmentsDeletable?: Maybe<Scalars['Boolean']>;
  isAdjustmentsUpdatable?: Maybe<Scalars['Boolean']>;
  isDeductible?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isExcludedFromSubmissionMarkable?: Maybe<Scalars['Boolean']>;
  isGeneratePdfAllowed?: Maybe<Scalars['Boolean']>;
  isIncludedInNACHA?: Maybe<Scalars['Boolean']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  isNACHAExcludedMarkable?: Maybe<Scalars['Boolean']>;
  isNACHAIncludedMarkable?: Maybe<Scalars['Boolean']>;
  isPayRegardlessAmount?: Maybe<Scalars['Boolean']>;
  isPayRegardlessAmountUpdatable?: Maybe<Scalars['Boolean']>;
  isPaymentRejectedMarkable?: Maybe<Scalars['Boolean']>;
  isPendingMarkable?: Maybe<Scalars['Boolean']>;
  isPublishable?: Maybe<Scalars['Boolean']>;
  isReadyForSubmissionMarkable?: Maybe<Scalars['Boolean']>;
  isReturnable?: Maybe<Scalars['Boolean']>;
  isSoftCopyDownloadable?: Maybe<Scalars['Boolean']>;
  isSubmittable?: Maybe<Scalars['Boolean']>;
  isTransactionInfoUpdatable?: Maybe<Scalars['Boolean']>;
  isUnpublishable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  issuedDate?: Maybe<Scalars['DateTime']>;
  paymentRejectedReason?: Maybe<Scalars['String']>;
  policies?: Maybe<Array<Maybe<Policy>>>;
  producingAgentCategoryRows?: Maybe<Array<Maybe<CommissionStatementProducingAgentCategory>>>;
  recipient?: Maybe<UserCompanyRole>;
  recipientAccountNumber?: Maybe<Scalars['String']>;
  recipientAddress?: Maybe<Scalars['String']>;
  recipientAddress2?: Maybe<Scalars['String']>;
  recipientCity?: Maybe<Scalars['String']>;
  recipientId: Scalars['ID'];
  recipientName: Scalars['String'];
  recipientRoutingNumber?: Maybe<Scalars['String']>;
  recipientState?: Maybe<Scalars['String']>;
  recipientZip?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submissionExcludedReason?: Maybe<Scalars['String']>;
  totalAdjustment?: Maybe<Scalars['Float']>;
  totalCommission?: Maybe<Scalars['Float']>;
  totalDeduction?: Maybe<Scalars['Float']>;
  totalFundAmount?: Maybe<Scalars['Float']>;
  totalPayout?: Maybe<Scalars['Float']>;
  totalRepaymentToAgent?: Maybe<Scalars['Float']>;
  totalRollover?: Maybe<Scalars['Float']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
};

export type CommissionStatementFilterInput = {
  commissionCycleEndDate?: InputMaybe<DateTimeFilterQueryInput>;
  commissionCycles?: InputMaybe<FilterIdsInput>;
  createdAt?: InputMaybe<DateTimeFilterQueryInput>;
  endDate?: InputMaybe<DateTimeFilterQueryInput>;
  isDeductible?: InputMaybe<FilterBooleanInput>;
  isPaid?: InputMaybe<FilterBooleanInput>;
  issuedDate?: InputMaybe<DateTimeFilterQueryInput>;
  query?: InputMaybe<FilterQueryInput>;
  recipients?: InputMaybe<FilterIdsInput>;
  startDate?: InputMaybe<DateTimeFilterQueryInput>;
  statementStates?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
  totalPayout?: InputMaybe<NumberFilterInput>;
  totalRollover?: InputMaybe<NumberFilterInput>;
  transactionDate?: InputMaybe<DateTimeFilterQueryInput>;
  transactionId?: InputMaybe<FilterQueryInput>;
};

export type CommissionStatementLog = {
  __typename?: 'CommissionStatementLog';
  after?: Maybe<Scalars['JSONObject']>;
  before?: Maybe<Scalars['JSONObject']>;
  commissionStatementId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  fromStatus?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  toStatus?: Maybe<Scalars['String']>;
};

export type CommissionStatementLogFilterInput = {
  commissionStatements?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type CommissionStatementProducingAgentCategory = {
  __typename?: 'CommissionStatementProducingAgentCategory';
  agentId?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Float']>;
  carrierId?: Maybe<Scalars['ID']>;
  commissionEntryCategoryCode: Scalars['ID'];
  commissionStatementId: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
};

export type CommissionStatementState = {
  __typename?: 'CommissionStatementState';
  backgroundColor?: Maybe<Scalars['String']>;
  code: Scalars['ID'];
  color?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CommissionStatementWidgetForAgent = {
  __typename?: 'CommissionStatementWidgetForAgent';
  endDate?: Maybe<Scalars['BigInt']>;
  interval?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['BigInt']>;
  totalPaid?: Maybe<Scalars['Float']>;
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Scalars['String']>;
  agentLevels?: Maybe<Array<Maybe<AgentLevel>>>;
  backgroundLargeUrl?: Maybe<Scalars['String']>;
  backgroundSmallUrl?: Maybe<Scalars['String']>;
  carriers?: Maybe<Array<Maybe<Carrier>>>;
  ceoName?: Maybe<Scalars['String']>;
  ceoTitle?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  commissionCycleStates?: Maybe<Array<Maybe<CommissionCycleState>>>;
  commissionEntryCategories?: Maybe<Array<Maybe<CommissionEntryCategory>>>;
  commissionStatementStates?: Maybe<Array<Maybe<CommissionStatementState>>>;
  companyExtraInfo?: Maybe<CompanyExtraInfo>;
  defaultReplyToEmail?: Maybe<Scalars['String']>;
  defaultSenderEmail?: Maybe<Scalars['String']>;
  employmentMinDurationRequired?: Maybe<Scalars['Int']>;
  enabledEvents?: Maybe<Array<Maybe<Event>>>;
  frontendBaseUrl?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Group>>>;
  id: Scalars['ID'];
  isSetupCompleted?: Maybe<Scalars['Boolean']>;
  licenses?: Maybe<Array<Maybe<License>>>;
  logoLargeUrl?: Maybe<Scalars['String']>;
  logoSmallUrl?: Maybe<Scalars['String']>;
  maxHierarchyDepth?: Maybe<Scalars['Int']>;
  minExchangeSalePointsRequired?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  policyStates?: Maybe<Array<Maybe<PolicyState>>>;
  productTypes?: Maybe<Array<Maybe<ProductType>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  rootHierarchyAgent?: Maybe<UserCompanyRole>;
  rootHierarchyId?: Maybe<Scalars['ID']>;
  slogan?: Maybe<Scalars['String']>;
  systemFeatures?: Maybe<Array<Maybe<SystemFeature>>>;
  themePaletteError?: Maybe<Scalars['String']>;
  themePaletteInfo?: Maybe<Scalars['String']>;
  themePalettePrimary?: Maybe<Scalars['String']>;
  themePaletteSecondary?: Maybe<Scalars['String']>;
  themePaletteSuccess?: Maybe<Scalars['String']>;
  themePaletteWarning?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CompanyClickUpConfig = {
  __typename?: 'CompanyClickUpConfig';
  authenticationToken?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  listId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  webhookClientId?: Maybe<Scalars['String']>;
  webhookId?: Maybe<Scalars['String']>;
  webhookSecret?: Maybe<Scalars['String']>;
};

export type CompanyClickUpConfigInput = {
  authenticationToken?: InputMaybe<Scalars['String']>;
  listId?: InputMaybe<Scalars['String']>;
  webhookClientId?: InputMaybe<Scalars['String']>;
  webhookId?: InputMaybe<Scalars['String']>;
  webhookSecret?: InputMaybe<Scalars['String']>;
};

export type CompanyClickUpUser = {
  __typename?: 'CompanyClickUpUser';
  amsUserInfo?: Maybe<UserCompanyRole>;
  clickUpUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  userCompanyRoleId?: Maybe<Scalars['ID']>;
};

export type CompanyExtraInfo = {
  __typename?: 'CompanyExtraInfo';
  agentAgreement?: Maybe<Scalars['String']>;
  backgroundLargeUrl?: Maybe<Scalars['String']>;
  backgroundSmallUrl?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  fieldManualUrl?: Maybe<Scalars['String']>;
  helpDriveId?: Maybe<Scalars['String']>;
  logoLargeUrl?: Maybe<Scalars['String']>;
  logoSmallUrl?: Maybe<Scalars['String']>;
  managerSignUpAcknowledgement?: Maybe<Scalars['String']>;
  signedCommissionReassignmentFormUrl?: Maybe<Scalars['String']>;
  viewableFieldManualUrl?: Maybe<Scalars['String']>;
  w9FormUrl?: Maybe<Scalars['String']>;
};

export type CompanyPayrollProvider = {
  __typename?: 'CompanyPayrollProvider';
  companyId?: Maybe<Scalars['ID']>;
  fundingAccountNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  numberOfDaysForEffectiveDate?: Maybe<Scalars['Int']>;
  payProviderId?: Maybe<Scalars['ID']>;
};

export type CompanySystemFeature = {
  __typename?: 'CompanySystemFeature';
  companyId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  isActivable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  systemFeature?: Maybe<SystemFeature>;
  systemFeatureId: Scalars['ID'];
};

export type CompanySystemFeatureFilterInput = {
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type CompanySystemFeatureInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  systemFeatureId?: InputMaybe<Scalars['ID']>;
};

export type CompensationAnnuity = {
  __typename?: 'CompensationAnnuity';
  id: Scalars['ID'];
  marginPercentage: Scalars['Float'];
  product?: Maybe<Product>;
  productId: Scalars['ID'];
};

export type CompensationAnnuityInput = {
  id?: InputMaybe<Scalars['ID']>;
  marginPercentage: Scalars['Float'];
};

export type CompensationFileEntries = {
  __typename?: 'CompensationFileEntries';
  files?: Maybe<Array<Maybe<CompensationFileEntry>>>;
  totalFiles?: Maybe<Scalars['Int']>;
};

export type CompensationFileEntry = {
  __typename?: 'CompensationFileEntry';
  companyId?: Maybe<Scalars['ID']>;
  compensationPlanId?: Maybe<Scalars['ID']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['ID']>;
  fileUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  size?: Maybe<Scalars['BigInt']>;
};

export type CompensationFileEntryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CompensationFileInput = {
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  size: Scalars['BigInt'];
  tmpFileUrl: Scalars['String'];
};

export type CompensationGenerationalOverride = {
  __typename?: 'CompensationGenerationalOverride';
  excess: Scalars['Float'];
  firstYear: Scalars['Float'];
  generationNumber: Scalars['Int'];
  renewal: Scalars['Float'];
  service: Scalars['Float'];
};

export type CompensationGenerationalOverrideInput = {
  excess: Scalars['Float'];
  firstYear: Scalars['Float'];
  generationNumber: Scalars['Int'];
  renewal: Scalars['Float'];
  service: Scalars['Float'];
};

export type CompensationPlan = {
  __typename?: 'CompensationPlan';
  advanceRate: Scalars['Float'];
  applicableProducts?: Maybe<Array<Maybe<Product>>>;
  calculationMethod?: Maybe<CalculationMethod>;
  calculationMethodId?: Maybe<Scalars['ID']>;
  calculationPercentage?: Maybe<Scalars['Int']>;
  carrier?: Maybe<Carrier>;
  carrierId: Scalars['ID'];
  compensationAnnuities?: Maybe<Array<Maybe<CompensationAnnuity>>>;
  compensationProductReceivingCommissions?: Maybe<Array<Maybe<CompensationProductReceivingCommission>>>;
  compensationRows?: Maybe<Array<Maybe<CompensationRow>>>;
  compensationTemplate?: Maybe<CompensationTemplate>;
  compensationTemplateId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayingAge?: Maybe<Scalars['String']>;
  displayingProduct?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  excessFromYear: Scalars['Int'];
  excessToYear: Scalars['Int'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  fromAge: Scalars['Int'];
  generationalOverrides?: Maybe<Array<Maybe<CompensationGenerationalOverride>>>;
  id: Scalars['ID'];
  isAnnuity?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isDocumentsUpdatable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  productIds: Array<Maybe<Scalars['ID']>>;
  productType?: Maybe<ProductType>;
  productTypeId: Scalars['ID'];
  renewalFromYear: Scalars['Int'];
  renewalToYear: Scalars['Int'];
  serviceFromYear: Scalars['Int'];
  toAge: Scalars['Int'];
  totalPayout: Scalars['Float'];
};

export type CompensationPlanFilterInput = {
  carriers?: InputMaybe<FilterIdsInput>;
  productTypes?: InputMaybe<FilterIdsInput>;
  products?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type CompensationProductByYear = {
  __typename?: 'CompensationProductByYear';
  excess?: Maybe<Scalars['Float']>;
  fromYear?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  renewal?: Maybe<Scalars['Float']>;
  service?: Maybe<Scalars['Float']>;
  toYear?: Maybe<Scalars['Int']>;
};

export type CompensationProductByYearInput = {
  excess?: InputMaybe<Scalars['Float']>;
  fromYear?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  renewal?: InputMaybe<Scalars['Float']>;
  service?: InputMaybe<Scalars['Float']>;
  toYear?: InputMaybe<Scalars['Int']>;
};

export type CompensationProductReceivingCommission = {
  __typename?: 'CompensationProductReceivingCommission';
  excess?: Maybe<Scalars['Float']>;
  firstYear?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  payoutAdvanceCommissionCapAmount?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  productByYears?: Maybe<Array<Maybe<CompensationProductByYear>>>;
  productId: Scalars['ID'];
  receivingAdvanceCommissionCapAmount?: Maybe<Scalars['Float']>;
  receivingPercentage: Scalars['Float'];
};

export type CompensationProductReceivingCommissionInput = {
  excess?: InputMaybe<Scalars['Float']>;
  firstYear?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  payoutAdvanceCommissionCapAmount?: InputMaybe<Scalars['Float']>;
  productByYears?: InputMaybe<Array<InputMaybe<CompensationProductByYearInput>>>;
  receivingAdvanceCommissionCapAmount?: InputMaybe<Scalars['Float']>;
  receivingPercentage: Scalars['Float'];
};

export type CompensationRow = {
  __typename?: 'CompensationRow';
  agentLevelId: Scalars['ID'];
  excess: Scalars['Float'];
  firstYear: Scalars['Float'];
  generationalOverrideDepth: Scalars['Int'];
  renewal: Scalars['Float'];
  rollingBonus: Scalars['Float'];
  service: Scalars['Float'];
};

export type CompensationRowInput = {
  agentLevelId: Scalars['ID'];
  excess: Scalars['Float'];
  firstYear: Scalars['Float'];
  generationalOverrideDepth: Scalars['Int'];
  renewal: Scalars['Float'];
  rollingBonus: Scalars['Float'];
  service: Scalars['Float'];
};

export type CompensationTemplate = {
  __typename?: 'CompensationTemplate';
  advanceRate: Scalars['Float'];
  calculationMethodId?: Maybe<Scalars['ID']>;
  calculationPercentage?: Maybe<Scalars['Int']>;
  compensationRows?: Maybe<Array<Maybe<CompensationRow>>>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayingAge?: Maybe<Scalars['String']>;
  displayingProduct?: Maybe<Scalars['String']>;
  excessFromYear: Scalars['Int'];
  excessToYear: Scalars['Int'];
  fromAge: Scalars['Int'];
  generationalOverrides?: Maybe<Array<Maybe<CompensationGenerationalOverride>>>;
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  renewalFromYear: Scalars['Int'];
  renewalToYear: Scalars['Int'];
  serviceFromYear: Scalars['Int'];
  toAge: Scalars['Int'];
  totalPayout: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Config = {
  __typename?: 'Config';
  agentBankTransactionStates?: Maybe<Array<Maybe<AgentBankTransactionState>>>;
  agentTypes?: Maybe<Array<Maybe<AgentType>>>;
  carrierAppointmentStates?: Maybe<Array<Maybe<CarrierAppointmentState>>>;
  commissionCycleStates?: Maybe<Array<Maybe<CommissionCycleState>>>;
  company?: Maybe<Company>;
  cutOverDate?: Maybe<Scalars['DateTime']>;
  defaultPaginationRowsPerPage: Array<Maybe<Scalars['Int']>>;
  environment?: Maybe<Scalars['String']>;
  errorOmissionStates?: Maybe<Array<Maybe<ErrorOmissionState>>>;
  eventReservationStates?: Maybe<Array<Maybe<EventReservationState>>>;
  eventTicketAssigneeTypes?: Maybe<Array<Maybe<EventTicketAssigneeType>>>;
  falloutPolicyStates: Array<Maybe<Scalars['String']>>;
  isAutomationAllowed: Scalars['Boolean'];
  isDevelopment: Scalars['Boolean'];
  isShowLeaderboardOnStartup: Scalars['Boolean'];
  isSyncToMailChimpAllowed?: Maybe<Scalars['Boolean']>;
  isSystemInMaintenanceMode?: Maybe<Scalars['Boolean']>;
  isTourEntriesAllowed: Scalars['Boolean'];
  isUsingManualCommissionCalculation: Scalars['Boolean'];
  licenseStates?: Maybe<Array<Maybe<LicenseState>>>;
  messageTypes?: Maybe<Array<Maybe<MessageType>>>;
  paidPolicyStates: Array<Maybe<Scalars['String']>>;
  payoutStatuses?: Maybe<Array<PayoutStatus>>;
  pendingPolicyStates: Array<Maybe<Scalars['String']>>;
  profileStates?: Maybe<Array<Maybe<UserCompanyRoleState>>>;
  providers?: Maybe<Array<Maybe<Provider>>>;
  residenceTypes?: Maybe<Array<Maybe<ResidenceType>>>;
  states?: Maybe<Array<Maybe<State>>>;
  statuses?: Maybe<Array<Maybe<Status>>>;
  taskCategories?: Maybe<Array<Maybe<TaskCategory>>>;
  toastAutoHideDuration: Scalars['PositiveInt'];
  tokenizationKey?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  policyCount?: Maybe<Scalars['Int']>;
  ssn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedByUserAgent?: Maybe<UserCompanyRole>;
  zip?: Maybe<Scalars['String']>;
};

export type CustomerFilterInput = {
  dateOfBirth?: InputMaybe<DateTimeFilterQueryInput>;
  query?: InputMaybe<FilterQueryInput>;
  residentStates?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
  updatedAt?: InputMaybe<DateTimeFilterQueryInput>;
};

export type CustomerInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type DateFilterInput = {
  end?: InputMaybe<Scalars['Date']>;
  endValue?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['Date']>;
  startValue?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Scalars['Date']>;
  timePrefix?: InputMaybe<Scalars['String']>;
  timeValue?: InputMaybe<Scalars['Int']>;
};

export type DateFilterQueryInput = {
  operatorTypeId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<DateFilterInput>;
};

export type DateTimeFilterInput = {
  end?: InputMaybe<Scalars['DateTime']>;
  endValue?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  startValue?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Scalars['DateTime']>;
  timePrefix?: InputMaybe<Scalars['String']>;
  timeValue?: InputMaybe<Scalars['Int']>;
};

export type DateTimeFilterQueryInput = {
  operatorTypeId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<DateTimeFilterInput>;
};

export type DeliveryReceiptState = {
  __typename?: 'DeliveryReceiptState';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type DuplicateCommissionEntryFilterInput = {
  agentId?: InputMaybe<FilterQueryInput>;
  agentName?: InputMaybe<FilterQueryInput>;
  agents?: InputMaybe<FilterIdsInput>;
  baseshopMembersOf?: InputMaybe<FilterIdsInput>;
  carriers?: InputMaybe<FilterIdsInput>;
  commission?: InputMaybe<NumberFilterInput>;
  commissionRate?: InputMaybe<NumberFilterInput>;
  commissionRawBatches?: InputMaybe<FilterIdsInput>;
  downlinesOf?: InputMaybe<FilterIdsInput>;
  issueDate?: InputMaybe<DateTimeFilterQueryInput>;
  planDescription?: InputMaybe<FilterQueryInput>;
  policies?: InputMaybe<FilterIdsInput>;
  policyPremium?: InputMaybe<NumberFilterInput>;
  policyTargetPremium?: InputMaybe<NumberFilterInput>;
  policyWrittenDate?: InputMaybe<DateTimeFilterQueryInput>;
  query?: InputMaybe<FilterQueryInput>;
  rawBatches?: InputMaybe<FilterIdsInput>;
  splitPercentage?: InputMaybe<NumberFilterInput>;
  text?: InputMaybe<FilterQueryInput>;
  transactionDate?: InputMaybe<DateTimeFilterQueryInput>;
  transactionDescription?: InputMaybe<FilterQueryInput>;
};

export type EAndO = {
  __typename?: 'EAndO';
  agent?: Maybe<UserCompanyRole>;
  confirmingText?: Maybe<Scalars['String']>;
  dateReviewed?: Maybe<Scalars['DateTime']>;
  expirationDate: Scalars['DateTime'];
  fileName: Scalars['String'];
  fileSize?: Maybe<Scalars['BigInt']>;
  fileUrl: Scalars['String'];
  id: Scalars['ID'];
  isApprovable?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isDownloadable?: Maybe<Scalars['Boolean']>;
  isSubmitable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  issueDate: Scalars['DateTime'];
  policyNumber: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  statusDate?: Maybe<Scalars['DateTime']>;
  userCompanyRoleId: Scalars['ID'];
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  body: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name: Scalars['ID'];
  subject: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedByUserId?: Maybe<Scalars['String']>;
};

export type ErrorOmissionFilterInput = {
  errorOmissionStates?: InputMaybe<FilterIdsInput>;
  expirationDate?: InputMaybe<DateTimeFilterQueryInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type ErrorOmissionState = {
  __typename?: 'ErrorOmissionState';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  checkinInstruction?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  eventCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isEventManager?: Maybe<Scalars['Boolean']>;
  isLockdown?: Maybe<Scalars['Boolean']>;
  isRegistrationLockable?: Maybe<Scalars['Boolean']>;
  isReservationOwner?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  lockdownAt?: Maybe<Scalars['DateTime']>;
  lockdownBy?: Maybe<UserCompanyRole>;
  myTicket?: Maybe<EventTicket>;
  name: Scalars['String'];
  numberOfAssignedTickets?: Maybe<Scalars['Int']>;
  numberOfTickets?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
  registrationExpiryDate: Scalars['DateTime'];
  sessions?: Maybe<Array<Maybe<EventSession>>>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
};

export type EventFilterInput = {
  endDate?: InputMaybe<DateTimeFilterQueryInput>;
  isEnabled?: InputMaybe<FilterBooleanInput>;
  registrationExpiryDate?: InputMaybe<DateTimeFilterQueryInput>;
  startDate?: InputMaybe<DateTimeFilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type EventReservation = {
  __typename?: 'EventReservation';
  agent?: Maybe<UserCompanyRole>;
  agentId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  event?: Maybe<Event>;
  eventId: Scalars['ID'];
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isTicketManual?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  numberOfTickets?: Maybe<Scalars['Int']>;
  sessions?: Maybe<Array<Maybe<EventReservationSession>>>;
  status?: Maybe<Scalars['String']>;
  ticketManuals?: Maybe<Array<Maybe<EventReservationTicketManual>>>;
  tickets?: Maybe<Array<Maybe<EventTicket>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
};

export type EventReservationFilterInput = {
  events?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  states?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type EventReservationSession = {
  __typename?: 'EventReservationSession';
  eventReservationId: Scalars['ID'];
  eventSessionId: Scalars['ID'];
  id: Scalars['ID'];
};

export type EventReservationState = {
  __typename?: 'EventReservationState';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type EventReservationStateStat = {
  __typename?: 'EventReservationStateStat';
  code?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type EventReservationTicketManual = {
  __typename?: 'EventReservationTicketManual';
  eventReservationId: Scalars['ID'];
  id: Scalars['ID'];
  ticketNumber: Scalars['String'];
};

export type EventSession = {
  __typename?: 'EventSession';
  checkInCount?: Maybe<Scalars['Int']>;
  event?: Maybe<Event>;
  eventId: Scalars['ID'];
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  ticketCount?: Maybe<Scalars['Int']>;
};

export type EventSessionFilterInput = {
  events?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type EventTicket = {
  __typename?: 'EventTicket';
  assigneeAgent?: Maybe<UserCompanyRole>;
  assigneeEmail?: Maybe<Scalars['String']>;
  assigneeName?: Maybe<Scalars['String']>;
  assigneeType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  eventId?: Maybe<Scalars['ID']>;
  eventReservationId?: Maybe<Scalars['ID']>;
  eventSessions?: Maybe<Array<Maybe<EventSession>>>;
  guestEmail?: Maybe<Scalars['String']>;
  guestFirstName?: Maybe<Scalars['String']>;
  guestLastName?: Maybe<Scalars['String']>;
  guestName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAssignable?: Maybe<Scalars['Boolean']>;
  isAssigned?: Maybe<Scalars['Boolean']>;
  isTransferable?: Maybe<Scalars['Boolean']>;
  reservationAgent?: Maybe<UserCompanyRole>;
  ticketNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
};

export type EventTicketAssigneeType = {
  __typename?: 'EventTicketAssigneeType';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type EventTicketAssignmentStat = {
  __typename?: 'EventTicketAssignmentStat';
  code?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type EventTicketFilterInput = {
  assigneeTypes?: InputMaybe<FilterIdsInput>;
  events?: InputMaybe<FilterIdsInput>;
  isAssigned?: InputMaybe<FilterBooleanInput>;
  query?: InputMaybe<FilterQueryInput>;
  reservation?: InputMaybe<FilterIdsInput>;
  reservationOwners?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type EventTicketSession = {
  __typename?: 'EventTicketSession';
  assigneeAgentCode?: Maybe<Scalars['String']>;
  assigneeEmail?: Maybe<Scalars['String']>;
  assigneeName?: Maybe<Scalars['String']>;
  assigneeType?: Maybe<Scalars['String']>;
  checkedInAt?: Maybe<Scalars['DateTime']>;
  checkedInBy?: Maybe<UserCompanyRole>;
  eventSessionId: Scalars['ID'];
  eventTicketId: Scalars['ID'];
  id: Scalars['ID'];
  isCheckedIn?: Maybe<Scalars['Boolean']>;
  isCheckedInMarkable?: Maybe<Scalars['Boolean']>;
  isNotCheckedInMarkable?: Maybe<Scalars['Boolean']>;
  ticketNumber?: Maybe<Scalars['String']>;
};

export type EventTicketSessionFilterInput = {
  assigneeTypes?: InputMaybe<FilterIdsInput>;
  eventSessions?: InputMaybe<FilterIdsInput>;
  isAssigned?: InputMaybe<FilterBooleanInput>;
  isCheckedIn?: InputMaybe<FilterBooleanInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type EventTicketStateStat = {
  __typename?: 'EventTicketStateStat';
  code?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ExecuteRawSqlCommissionEntries = {
  __typename?: 'ExecuteRawSqlCommissionEntries';
  fields?: Maybe<Array<Maybe<ExecuteRawSqlCommissionEntriesField>>>;
  rows?: Maybe<Array<Maybe<CommissionEntryRawQuery>>>;
};

export type ExecuteRawSqlCommissionEntriesField = {
  __typename?: 'ExecuteRawSqlCommissionEntriesField';
  name?: Maybe<Scalars['String']>;
};

export type FederalTaxClassification = {
  __typename?: 'FederalTaxClassification';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type FieldValidationError = {
  __typename?: 'FieldValidationError';
  code: Scalars['String'];
  field: Scalars['String'];
  reason: Scalars['String'];
};

export type FileCategory = {
  __typename?: 'FileCategory';
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type FileCategoryFilterInput = {
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type FileEntries = {
  __typename?: 'FileEntries';
  fileEntries?: Maybe<Array<Maybe<FileEntry>>>;
  totalFileEntries?: Maybe<Scalars['Int']>;
};

export type FileEntry = {
  __typename?: 'FileEntry';
  companyId?: Maybe<Scalars['ID']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['ID']>;
  downloadUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isFolder?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  size?: Maybe<Scalars['BigInt']>;
};

export type FileEntryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FileInput = {
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  size: Scalars['BigInt'];
  tmpFileUrl: Scalars['String'];
};

export type FilterBooleanInput = {
  query?: InputMaybe<Scalars['String']>;
};

export type FilterIdInput = {
  query?: InputMaybe<Scalars['String']>;
};

export type FilterIdsInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type FilterQueryInput = {
  operatorTypeId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};

export type FolderInput = {
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['ID']>;
};

export type GenerationOverrideQualification = {
  __typename?: 'GenerationOverrideQualification';
  agent?: Maybe<UserCompanyRole>;
  agentId: Scalars['ID'];
  id: Scalars['ID'];
  isQualified?: Maybe<Scalars['Boolean']>;
  isQualifiedByPointValuesLast3Month?: Maybe<Scalars['Boolean']>;
  isQualifiedByPointValuesLastMonth?: Maybe<Scalars['Boolean']>;
  isQualifiedBySalesAndRecruitsLast3Month?: Maybe<Scalars['Boolean']>;
  isQualifiedBySalesAndRecruitsLastMonth?: Maybe<Scalars['Boolean']>;
  isQualifiedLast3Month?: Maybe<Scalars['Boolean']>;
  isQualifiedLastMonth?: Maybe<Scalars['Boolean']>;
  last3MonthBaseshopPoints?: Maybe<Scalars['Float']>;
  last3MonthRecruitCount?: Maybe<Scalars['Int']>;
  last3MonthSaleCount?: Maybe<Scalars['Float']>;
  lastMonthBaseshopPoints?: Maybe<Scalars['Float']>;
  lastMonthRecruitCount?: Maybe<Scalars['Int']>;
  lastMonthSaleCount?: Maybe<Scalars['Float']>;
  monthKey?: Maybe<Scalars['String']>;
};

export type GenerationOverrideQualificationFilterInput = {
  qualifyMonth?: InputMaybe<FilterQueryInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type GenerationOverrideQualificationSummary = {
  __typename?: 'GenerationOverrideQualificationSummary';
  isQualifiedLast3MonthQuantity?: Maybe<Scalars['Int']>;
  isQualifiedLastMonthQuantity?: Maybe<Scalars['Int']>;
  isQualifiedQuantity?: Maybe<Scalars['Int']>;
  totalLast3MonthBaseshopPoints?: Maybe<Scalars['Float']>;
  totalLast3MonthRecruitCount?: Maybe<Scalars['Int']>;
  totalLast3MonthSaleCount?: Maybe<Scalars['Float']>;
  totalLastMonthBaseshopPoints?: Maybe<Scalars['Float']>;
  totalLastMonthRecruitCount?: Maybe<Scalars['Int']>;
  totalLastMonthSaleCount?: Maybe<Scalars['Float']>;
};

export type GenerationOverrideRequirementWaiver = {
  __typename?: 'GenerationOverrideRequirementWaiver';
  agent?: Maybe<UserCompanyRole>;
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  createdByUserId?: Maybe<Scalars['ID']>;
  fromMonthKey?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  toMonthKey?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
  updatedByUserId?: Maybe<Scalars['ID']>;
};

export type GenerationOverrideWaiverFilterInput = {
  fromMonth?: InputMaybe<FilterQueryInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
  toMonth?: InputMaybe<FilterQueryInput>;
};

export type GoogleCloudFile = {
  __typename?: 'GoogleCloudFile';
  createdAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  fileUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Group = {
  __typename?: 'Group';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isSyncToMailChimpAllowed?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  numberOfUsers?: Maybe<Scalars['Int']>;
};

export type HelpArticle = {
  __typename?: 'HelpArticle';
  folderId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  viewUrl?: Maybe<Scalars['String']>;
};

export type HelpFolder = {
  __typename?: 'HelpFolder';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
};

export type HierarchyHistory = {
  __typename?: 'HierarchyHistory';
  actor?: Maybe<UserCompanyRole>;
  createdAt?: Maybe<Scalars['BigInt']>;
  createdByAgentId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  summaryOfChanges?: Maybe<Scalars['String']>;
};

export type HierarchyHistoryFilterInput = {
  endDate?: InputMaybe<DateTimeFilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type Invitation = {
  __typename?: 'Invitation';
  companyId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invitedByAgent?: Maybe<UserCompanyRole>;
  invitedByAgentId?: Maybe<Scalars['ID']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  recipientFirstName?: Maybe<Scalars['String']>;
  sentDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type InvitationFilterInput = {
  expirationDate?: InputMaybe<DateTimeFilterQueryInput>;
  invitedByAgent?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  sentDate?: InputMaybe<DateTimeFilterQueryInput>;
  states?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type Invitations = {
  __typename?: 'Invitations';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Invitation>>>;
};

export type LevelHistory = {
  __typename?: 'LevelHistory';
  agentLevel?: Maybe<AgentLevel>;
  agentLevelId: Scalars['ID'];
  effectiveDate: Scalars['DateTime'];
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  userCompanyRoleId: Scalars['ID'];
};

export type License = {
  __typename?: 'License';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type LicenseInfo = {
  __typename?: 'LicenseInfo';
  agent?: Maybe<UserCompanyRole>;
  confirmingText?: Maybe<Scalars['String']>;
  dateReviewed?: Maybe<Scalars['DateTime']>;
  expirationDate: Scalars['DateTime'];
  fileName: Scalars['String'];
  fileSize?: Maybe<Scalars['BigInt']>;
  fileUrl: Scalars['String'];
  id: Scalars['ID'];
  isApprovable?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isDownloadable?: Maybe<Scalars['Boolean']>;
  isSubmitable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  issueDate: Scalars['DateTime'];
  licenseId: Scalars['ID'];
  licenseNumber: Scalars['String'];
  licenseType?: Maybe<License>;
  npnNumber: Scalars['String'];
  residenceType: Scalars['String'];
  state: Scalars['String'];
  stateName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusDate?: Maybe<Scalars['DateTime']>;
  userCompanyRoleId: Scalars['ID'];
};

export type LicenseInfoFilterInput = {
  expirationDate?: InputMaybe<DateTimeFilterQueryInput>;
  licenseIds?: InputMaybe<FilterQueryInput>;
  licenseStates?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  residenceTypes?: InputMaybe<FilterIdsInput>;
  states?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type LicenseState = {
  __typename?: 'LicenseState';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type LifeSaleStat = {
  __typename?: 'LifeSaleStat';
  count?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  policyStateCode: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};

export type Link = {
  __typename?: 'Link';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  priority?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type Links = {
  __typename?: 'Links';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Link>>>;
};

export type Memo = {
  __typename?: 'Memo';
  attachmentUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isPublishable?: Maybe<Scalars['Boolean']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isUnpublishable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  isViewed?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  subject: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
  viewedAt?: Maybe<Scalars['DateTime']>;
};

export type MemoFilterInput = {
  isPublished?: InputMaybe<FilterBooleanInput>;
  publishedAt?: InputMaybe<DateTimeFilterQueryInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type MessageType = {
  __typename?: 'MessageType';
  code: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
};

export type MethodOfSubmission = {
  __typename?: 'MethodOfSubmission';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateCommissionAssignment?: Maybe<CommissionAssignment>;
  activateUserPushNotificationRegistration?: Maybe<UserPushNotificationRegistration>;
  addClickUpUser?: Maybe<Array<Maybe<CompanyClickUpUser>>>;
  approveAgents?: Maybe<Scalars['Int']>;
  approveApplication?: Maybe<UserCompanyRole>;
  approveCommissionAssignment?: Maybe<CommissionAssignment>;
  approveCommissionCycle?: Maybe<CommissionCycle>;
  approveDriverLicense?: Maybe<UserCompanyRole>;
  approveEAndO?: Maybe<EAndO>;
  approveLicenseInfo?: Maybe<LicenseInfo>;
  approvePolicies?: Maybe<Scalars['Int']>;
  approvePolicy?: Maybe<Policy>;
  assignEventTicket?: Maybe<EventTicket>;
  assignTask?: Maybe<Scalars['Int']>;
  calculateProfileItems?: Maybe<UserCompanyRole>;
  changeAvatar?: Maybe<User>;
  changeBackground?: Maybe<CompanyExtraInfo>;
  changeColor?: Maybe<Company>;
  changeLogo?: Maybe<CompanyExtraInfo>;
  changeUserPushNotificationRegistration?: Maybe<UserPushNotificationRegistration>;
  checkInFromKiosk?: Maybe<EventTicket>;
  closeTask?: Maybe<Scalars['Int']>;
  commissionAdjustmentReviewMarkAsCreated?: Maybe<Scalars['Int']>;
  commissionAdjustmentReviewMarkAsReviewed?: Maybe<Scalars['Int']>;
  convertAIGCommissionXMLtoCSV?: Maybe<Scalars['String']>;
  createAPCLWorkflowItem?: Maybe<WorkflowItem>;
  createAgentBankTransaction?: Maybe<AgentBankTransaction>;
  createAgentFileCategory?: Maybe<FileCategory>;
  createAgentFileEntries?: Maybe<Array<Maybe<AgentFileEntry>>>;
  createAgentLevel?: Maybe<AgentLevel>;
  createAgentMemo?: Maybe<Memo>;
  createApiKey?: Maybe<ApiKey>;
  createCarrier?: Maybe<Carrier>;
  createCarrierAppointment?: Maybe<CarrierAppointment>;
  createCommissionAdjustment?: Maybe<CommissionAdjustment>;
  createCommissionAdjustmentByStatement?: Maybe<CommissionAdjustment>;
  createCommissionAdjustmentCategory?: Maybe<CommissionAdjustmentCategory>;
  createCommissionCycle?: Maybe<CommissionCycle>;
  createCommissionEligibilityOverride?: Maybe<CommissionEligibilityCheckSuppression>;
  createCommissionEntryCategory?: Maybe<CommissionEntryCategoryCompany>;
  createCommissionRawBatch?: Maybe<CommissionRawBatch>;
  createCommissionStatementsFromFiles?: Maybe<Array<Maybe<CommissionStatement>>>;
  createCompany?: Maybe<Company>;
  createCompensationFiles?: Maybe<Array<Maybe<CompensationFileEntry>>>;
  createCompensationPlan?: Maybe<CompensationPlan>;
  createCompensationTemplate?: Maybe<CompensationTemplate>;
  createCustomer?: Maybe<Customer>;
  createEAndO?: Maybe<EAndO>;
  createEmailTemplate?: Maybe<EmailTemplate>;
  createEvent?: Maybe<Event>;
  createEventReservation?: Maybe<EventReservation>;
  createEventSession?: Maybe<EventSession>;
  createFiles?: Maybe<Array<Maybe<FileEntry>>>;
  createFolder?: Maybe<FileEntry>;
  createGenerationOverrideRequirementWaiver?: Maybe<GenerationOverrideRequirementWaiver>;
  createGroup?: Maybe<Group>;
  createInstructor?: Maybe<TrainingClassSessionInstructor>;
  createInvitation?: Maybe<Invitation>;
  createLevelHistory?: Maybe<LevelHistory>;
  createLicense?: Maybe<License>;
  createLicenseInfo?: Maybe<LicenseInfo>;
  createLink?: Maybe<Link>;
  createMaterialFiles?: Maybe<TrainingClassSessionFile>;
  createNote?: Maybe<Note>;
  createPaymentMode?: Maybe<PaymentMode>;
  createPolicy?: Maybe<Policy>;
  createPolicyExchangeSale?: Maybe<PolicyExchangeSale>;
  createPolicyNote?: Maybe<PolicyNote>;
  createPolicySplitSales?: Maybe<Array<Maybe<PolicySplitSale>>>;
  createPolicyStateCompany?: Maybe<PolicyStateCompany>;
  createPolicyTrainingSales?: Maybe<Array<Maybe<PolicyTrainingSale>>>;
  createProduct?: Maybe<Product>;
  createProductType?: Maybe<ProductType>;
  createProfilePaymentMethod?: Maybe<BraintreePaymentMethod>;
  createRole?: Maybe<Role>;
  createTask?: Maybe<Task>;
  createTaskCategoryCompany?: Maybe<TaskCategoryCompany>;
  createTaskComment?: Maybe<TaskComment>;
  createTaskCommentAttachment?: Maybe<TaskComment>;
  createTaskForSubmissionRequirementWarnings?: Maybe<Task>;
  createTourEntry?: Maybe<TourEntry>;
  createTrainingClass?: Maybe<TrainingClass>;
  createTrainingClassRegistrationWorkflowItem?: Maybe<WorkflowItem>;
  createTrainingClassSession?: Maybe<TrainingClassSession>;
  createTrainingClassSessionAssistant?: Maybe<Array<Maybe<UserCompanyRole>>>;
  createUser?: Maybe<UserCompanyRole>;
  createUserAccountLink?: Maybe<UserAccountLink>;
  createUserCompanyRole?: Maybe<UserCompanyRole>;
  createWorkflowItem?: Maybe<WorkflowItem>;
  createWorkflowItemComment?: Maybe<WorkflowItemComment>;
  createFileCategory?: Maybe<FileCategory>;
  deactivateCommissionAssignment?: Maybe<CommissionAssignment>;
  deactivateUserPushNotificationRegistration?: Maybe<UserPushNotificationRegistration>;
  declinePoliciesByCarrier?: Maybe<Scalars['Int']>;
  declinePolicyByCarrier?: Maybe<Policy>;
  deductCommissionStatement?: Maybe<CommissionStatement>;
  deductCommissionStatements?: Maybe<Array<Maybe<CommissionStatement>>>;
  deleteAgentBankTransaction?: Maybe<Scalars['Int']>;
  deleteAgentFileCategory?: Maybe<Scalars['Int']>;
  deleteAgentFileEntry?: Maybe<Scalars['Int']>;
  deleteAgentLevel?: Maybe<Scalars['Int']>;
  deleteAgentMemo?: Maybe<Scalars['Int']>;
  deleteAgents?: Maybe<Scalars['Int']>;
  deleteApiKey?: Maybe<Scalars['Int']>;
  deleteCarrier?: Maybe<Scalars['Int']>;
  deleteCarrierAppointment?: Maybe<Scalars['Int']>;
  deleteCommissionAdjustmentCategory?: Maybe<Scalars['Int']>;
  deleteCommissionAdjustmentReviewItem?: Maybe<Scalars['Int']>;
  deleteCommissionAdjustments?: Maybe<Scalars['Int']>;
  deleteCommissionAdjustmentsPreview?: Maybe<Scalars['Int']>;
  deleteCommissionCalculatedEntries?: Maybe<Scalars['Int']>;
  deleteCommissionCycle?: Maybe<Scalars['Int']>;
  deleteCommissionEligibilityOverride?: Maybe<Scalars['Int']>;
  deleteCommissionEntries?: Maybe<Scalars['Int']>;
  deleteCommissionEntryCategory?: Maybe<Scalars['Int']>;
  deleteCommissionRawBatch?: Maybe<Scalars['Int']>;
  deleteCommissionStatements?: Maybe<Scalars['Int']>;
  deleteCompensationFile?: Maybe<Scalars['Int']>;
  deleteCompensationPlan?: Maybe<Scalars['Int']>;
  deleteCompensationTemplate?: Maybe<Scalars['Int']>;
  deleteCustomer?: Maybe<Scalars['Int']>;
  deleteDuplicateCommissionEntries?: Maybe<Array<Maybe<CommissionEntry>>>;
  deleteEAndO?: Maybe<Scalars['Int']>;
  deleteEmailTemplate?: Maybe<Scalars['Int']>;
  deleteEvent?: Maybe<Scalars['Int']>;
  deleteEventReservation?: Maybe<Scalars['Int']>;
  deleteEventSession?: Maybe<Scalars['Int']>;
  deleteFileEntry?: Maybe<Scalars['Int']>;
  deleteGenerationOverrideRequirementWaiver?: Maybe<Scalars['Int']>;
  deleteGroup?: Maybe<Scalars['Int']>;
  deleteInstructor?: Maybe<TrainingClassSessionInstructor>;
  deleteInvitation?: Maybe<Scalars['Int']>;
  deleteLevelHistory?: Maybe<Scalars['Int']>;
  deleteLicense?: Maybe<Scalars['Int']>;
  deleteLicenseInfo?: Maybe<Scalars['Int']>;
  deleteLink?: Maybe<Scalars['String']>;
  deleteMaterialFile?: Maybe<TrainingClassSessionFile>;
  deleteNote?: Maybe<Scalars['Int']>;
  deletePaymentMode?: Maybe<Scalars['Int']>;
  deletePendingPolicies?: Maybe<Scalars['Int']>;
  deletePendingPolicy?: Maybe<Scalars['Int']>;
  deletePolicyExchangeSales?: Maybe<Scalars['Int']>;
  deletePolicyNote?: Maybe<Scalars['Int']>;
  deletePolicySplitSales?: Maybe<Scalars['Int']>;
  deletePolicyStateCompany?: Maybe<Scalars['Int']>;
  deletePolicyTrainingSale?: Maybe<Scalars['Int']>;
  deleteProduct?: Maybe<Scalars['Int']>;
  deleteProductType?: Maybe<Scalars['Int']>;
  deleteProfileDriverLicense?: Maybe<UserCompanyRole>;
  deleteProfilePaymentMethod?: Maybe<BraintreePaymentMethod>;
  deleteQuery?: Maybe<Scalars['ID']>;
  deleteReceipt?: Maybe<Policy>;
  deleteRole?: Maybe<Scalars['Int']>;
  deleteTask?: Maybe<Scalars['Int']>;
  deleteTaskCategoryCompany?: Maybe<Scalars['Int']>;
  deleteTaskComment?: Maybe<Scalars['Int']>;
  deleteTaskCommentAttachment?: Maybe<Scalars['Int']>;
  deleteTourEntry?: Maybe<Scalars['Int']>;
  deleteTrainingClass?: Maybe<Scalars['Int']>;
  deleteTrainingClassSession?: Maybe<Scalars['Int']>;
  deleteTrainingClassSessionAssistant?: Maybe<Scalars['Int']>;
  deleteTrainingSaleAppointment?: Maybe<Scalars['Int']>;
  deleteUserAccountLink?: Maybe<Scalars['Int']>;
  deleteUserCompanyRole?: Maybe<Scalars['Int']>;
  deleteWorkflowItem?: Maybe<Scalars['Int']>;
  deleteFileCategory?: Maybe<Scalars['Int']>;
  disableEvent?: Maybe<Event>;
  dryRecalculateCommissionEntry?: Maybe<CommissionEntry>;
  editCommissionAdjustmentReviewItem?: Maybe<Scalars['Int']>;
  editQuery?: Maybe<Scalars['Int']>;
  enableEvent?: Maybe<Event>;
  excludeCommissionAdjustments?: Maybe<Scalars['Int']>;
  excludeCommissionCalculatedEntries?: Maybe<Scalars['Int']>;
  excludeCommissionStatementsFromNACHA?: Maybe<Array<Maybe<CommissionStatement>>>;
  excludeUserGroups?: Maybe<Scalars['Int']>;
  executeRawSqlQuery?: Maybe<Scalars['Int']>;
  exportAgentAudit?: Maybe<Scalars['String']>;
  exportAgentsForDirectory?: Maybe<Scalars['String']>;
  exportCPAReport?: Maybe<Scalars['String']>;
  exportCarrierAppointments?: Maybe<Scalars['String']>;
  exportCheckinTickets?: Maybe<Scalars['String']>;
  exportCommissionAdjustmentGenerated?: Maybe<Scalars['String']>;
  exportCommissionAdjustments?: Maybe<Scalars['String']>;
  exportCommissionAdjustmentsFromRecalculation?: Maybe<Scalars['String']>;
  exportCommissionCalculatedEntries?: Maybe<Scalars['String']>;
  exportCommissionCalculatedEntriesForAgent?: Maybe<Scalars['String']>;
  exportCommissionCalculatedEntriesOnHold?: Maybe<Scalars['String']>;
  exportCommissionEntriesRecalculate?: Maybe<Scalars['String']>;
  exportCommissionEntryRawQuery?: Maybe<Scalars['String']>;
  exportCommissionStatements?: Maybe<Scalars['String']>;
  exportCustomers?: Maybe<Scalars['String']>;
  exportDataForManualCommissionCalculation?: Maybe<Scalars['String']>;
  exportEvent?: Maybe<Scalars['String']>;
  exportEventTickets?: Maybe<Scalars['String']>;
  exportForSkipio?: Maybe<Scalars['String']>;
  exportLicenseInfos?: Maybe<Scalars['String']>;
  exportPolicies?: Maybe<Scalars['String']>;
  exportProductionStats?: Maybe<Scalars['String']>;
  exportRecruitingStats?: Maybe<Scalars['String']>;
  exportRolesAndPrivileges?: Maybe<Scalars['String']>;
  exportTasks?: Maybe<Scalars['String']>;
  exportTransactionsForQuickBooks?: Maybe<Scalars['String']>;
  exportWinter2021CashBonus?: Maybe<Scalars['String']>;
  factoryReset?: Maybe<Scalars['Int']>;
  forceFieldManualReacknowledgement?: Maybe<Scalars['Int']>;
  forceMembershipReacknowledgement?: Maybe<Scalars['Int']>;
  generateNACHAFileForCommissionCycle?: Maybe<Scalars['String']>;
  holdCommissionCalculatedEntries?: Maybe<Scalars['Int']>;
  holdCommissionCalculatedEntry?: Maybe<CommissionCalculatedEntry>;
  holdCommissionEntries?: Maybe<Scalars['Int']>;
  holdCommissionEntry?: Maybe<Scalars['Int']>;
  importTransactionsToCommissionCycle?: Maybe<CommissionCycle>;
  includeCommissionAdjustments?: Maybe<Scalars['Int']>;
  includeCommissionCalculatedEntries?: Maybe<Scalars['Int']>;
  includeCommissionStatementsToNACHA?: Maybe<Array<Maybe<CommissionStatement>>>;
  includeUserGroups?: Maybe<Scalars['Int']>;
  lockdownEvent?: Maybe<Event>;
  makeCompensationPlanCopy?: Maybe<CompensationPlan>;
  markAgentsAsActivated?: Maybe<Scalars['Int']>;
  markAgentsAsInactivated?: Maybe<Scalars['Int']>;
  markAsDuplicatedCommissionEntries?: Maybe<Array<Maybe<CommissionEntry>>>;
  markAsExcludedCommissionEntries?: Maybe<Array<Maybe<CommissionEntry>>>;
  markAsFinalizedAgentBankTransaction?: Maybe<AgentBankTransaction>;
  markAsIncludedCommissionEntries?: Maybe<Array<Maybe<CommissionEntry>>>;
  markAsIncludedFromDuplicatedCommissionEntries?: Maybe<Array<Maybe<CommissionEntry>>>;
  markCommisisonStatementsExcludedFromSubmission?: Maybe<Array<Maybe<CommissionStatement>>>;
  markCommissionCycleAsComplete?: Maybe<CommissionCycle>;
  markCommissionCycleAsPaymentPending?: Maybe<CommissionCycle>;
  markCommissionStatementAsExcludedFromSubmission?: Maybe<CommissionStatement>;
  markCommissionStatementAsPaymentRejected?: Maybe<CommissionStatement>;
  markCommissionStatementAsPending?: Maybe<CommissionStatement>;
  markCommissionStatementAsReadyForSubmission?: Maybe<CommissionStatement>;
  markEventTicketSessionAsChecked?: Maybe<EventTicketSession>;
  markPoliciesAsCanceledByClient?: Maybe<Scalars['Int']>;
  markPoliciesAsDelivered?: Maybe<Scalars['Int']>;
  markPoliciesAsIssued?: Maybe<Scalars['Int']>;
  markPoliciesAsPaid?: Maybe<Scalars['Int']>;
  markPoliciesAsPaidByCarrier?: Maybe<Scalars['Int']>;
  markPoliciesAsTerminatedByCarrier?: Maybe<Scalars['Int']>;
  markPolicyAsCanceledByClient?: Maybe<Policy>;
  markPolicyAsClosed?: Maybe<Policy>;
  markPolicyAsDelivered?: Maybe<Policy>;
  markPolicyAsDuplicate?: Maybe<Policy>;
  markPolicyAsIssued?: Maybe<Policy>;
  markPolicyAsPaid?: Maybe<Policy>;
  markPolicyAsPaidByCarrier?: Maybe<Policy>;
  markPolicyAsTerminatedByCarrier?: Maybe<Policy>;
  markPolicyAsUnduplicated?: Maybe<Policy>;
  markReviewedCommissionAdjustmentReviewItem?: Maybe<Scalars['Int']>;
  markReviewedCommissionAdjustmentReviewItems?: Maybe<Scalars['Int']>;
  markUnreviewedCommissionAdjustmentReviewItem?: Maybe<Scalars['Int']>;
  markUnreviewedCommissionAdjustmentReviewItems?: Maybe<Scalars['Int']>;
  markWorkflowAsDisabled?: Maybe<Workflow>;
  markWorkflowAsEnabled?: Maybe<Workflow>;
  markWorkflowAsUserInitializationAllowed?: Maybe<Workflow>;
  markWorkflowAsUserInitializationDisallowed?: Maybe<Workflow>;
  mergeProduct?: Maybe<Scalars['Int']>;
  migrateIAA?: Maybe<Scalars['Int']>;
  moveItemToTrash?: Maybe<WorkflowItem>;
  obsoletePolicy?: Maybe<Policy>;
  obsoleteTask?: Maybe<Scalars['Int']>;
  overrideCommissionCalculatedEntry?: Maybe<CommissionCalculatedEntry>;
  overrideCommissionEntry?: Maybe<CommissionEntry>;
  processOverrideBonus?: Maybe<Scalars['Int']>;
  publishAgentMemo?: Maybe<Memo>;
  publishCommissionCycle?: Maybe<Array<Maybe<CommissionStatement>>>;
  publishCommissionStatements?: Maybe<Array<Maybe<CommissionStatement>>>;
  reacknowledgeMembershipAgreement?: Maybe<UserCompanyRole>;
  reassignCarrierAppointment?: Maybe<CarrierAppointment>;
  recalculateAgentLevelStats?: Maybe<Scalars['Int']>;
  recalculateAndAdjustCommissionCalculatedEntries?: Maybe<Scalars['Int']>;
  recalculateAndAdjustCommissionEntries?: Maybe<Scalars['Int']>;
  recalculateAndAdjustCommissionEntriesByIds?: Maybe<Scalars['Int']>;
  recalculateCommissionCalculatedEntry?: Maybe<CommissionCalculatedEntry>;
  recalculateCommissionCycle?: Maybe<Scalars['Int']>;
  recalculateCommissionEntries?: Maybe<Scalars['Int']>;
  recalculateCommissionEntry?: Maybe<CommissionEntry>;
  recalculateCommissionRawBatch?: Maybe<CommissionRawBatch>;
  recalculateCommissionStatements?: Maybe<Scalars['Int']>;
  recalculateEligibilityCommissionCalculatedEntries?: Maybe<Scalars['Int']>;
  recalculateHierarchicalValues?: Maybe<Scalars['Int']>;
  recalculatePayoutEligibility?: Maybe<Scalars['Int']>;
  recalculateProfileHealthScores?: Maybe<Scalars['Int']>;
  recalculateUnpaidEntries?: Maybe<Scalars['Int']>;
  recalculateWorkflowItemPermission?: Maybe<WorkflowItem>;
  recalculateWorkflowItemPermissions?: Maybe<Array<Maybe<WorkflowItem>>>;
  recallCommissionAssignment?: Maybe<CommissionAssignment>;
  refershAttendTrainingClass?: Maybe<Array<Maybe<WorkflowItemFieldValueAttendTrainingClassChecklist>>>;
  refreshAIGContractPrerequisiteList?: Maybe<WorkflowItemFieldValueAigContractList>;
  refreshActionPlanChecklist?: Maybe<WorkflowItemFieldValueActionPlanChecklist>;
  refreshAppointedList?: Maybe<Array<Maybe<WorkflowItemFieldValueAppointedList>>>;
  refreshGenerationalOverrideQualification?: Maybe<Scalars['Int']>;
  refreshLevelStatsForAgents?: Maybe<Scalars['Int']>;
  refreshPersonallyCloseIulSales?: Maybe<Array<Maybe<WorkflowItemFieldValuePersonallyCloseIulSale>>>;
  refreshPhase1UniversityClass?: Maybe<Array<Maybe<WorkflowItemFieldValuePhase1UniversityClassList>>>;
  refreshPhase2UniversityClass?: Maybe<Array<Maybe<WorkflowItemFieldValuePhase2UniversityClassList>>>;
  refreshSaleStatsForAgents?: Maybe<Scalars['Int']>;
  refreshStartBuildingChecklist?: Maybe<WorkflowItemFieldValueStartBuildingChecklist>;
  refreshTrainingClassRequirements?: Maybe<Array<Maybe<WorkflowItemFieldValueTrainingClassRequirement>>>;
  refreshTrainingSaleAppointmentList?: Maybe<Array<Maybe<WorkflowItemFieldValueFieldTrainingSaleAppointment>>>;
  refreshUniversityClass?: Maybe<Array<Maybe<WorkflowItemFieldValueUniversityClass>>>;
  regenerateMembershipAgreement?: Maybe<Scalars['Int']>;
  registerClickUpWebhook: Scalars['PositiveInt'];
  registerUserPushNotificationRegistration?: Maybe<UserPushNotificationRegistration>;
  rejectAgents?: Maybe<Scalars['Int']>;
  rejectApplication?: Maybe<UserCompanyRole>;
  rejectCommissionAssignment?: Maybe<CommissionAssignment>;
  rejectCommissionCycle?: Maybe<CommissionCycle>;
  rejectDriverLicense?: Maybe<UserCompanyRole>;
  rejectEAndO?: Maybe<EAndO>;
  rejectLicenseInfo?: Maybe<LicenseInfo>;
  rejectPolicies?: Maybe<Scalars['Int']>;
  rejectPoliciesByClient?: Maybe<Scalars['Int']>;
  rejectPolicy?: Maybe<Policy>;
  rejectPolicyByClient?: Maybe<Policy>;
  removeClickUpUser?: Maybe<Scalars['Int']>;
  removeClickUpWebhook: Scalars['PositiveInt'];
  removePolicyTrainingSaleAppointment?: Maybe<PolicyTrainingSale>;
  removeTransactionsFromCommissionCycle?: Maybe<CommissionCycle>;
  reportIssue?: Maybe<Task>;
  resendCheckinInstructionsToAllAttendees?: Maybe<Event>;
  resendInvitation?: Maybe<Scalars['Int']>;
  resetWorkflowItemValues?: Maybe<WorkflowItem>;
  resolveTask?: Maybe<Scalars['Int']>;
  returnCommissionAssignment?: Maybe<CommissionAssignment>;
  returnCommissionStatements?: Maybe<Array<Maybe<CommissionStatement>>>;
  returnPolicyToThePreviousStatus?: Maybe<Policy>;
  revokeUserPushNotificationRegistration?: Maybe<Scalars['Int']>;
  runCLI?: Maybe<Scalars['Int']>;
  saveAgentApplicationBackground?: Maybe<UserCompanyRole>;
  saveAgentApplicationEmployment?: Maybe<UserCompanyRole>;
  saveCommissionAdjustmentReviewItem?: Maybe<Scalars['Int']>;
  saveCommissionAdjustmentReviewItems?: Maybe<Scalars['Int']>;
  saveCommissionAdjustments?: Maybe<Scalars['Int']>;
  saveCommissionAssignment?: Maybe<CommissionAssignment>;
  saveCompensationPlanAnnuities?: Maybe<CompensationPlan>;
  saveCompensationPlanProductReceivingCommissions?: Maybe<CompensationPlan>;
  saveProfileAgentInformation?: Maybe<UserCompanyRole>;
  saveProfileContactInformation?: Maybe<UserCompanyRole>;
  saveProfileDriverLicense?: Maybe<UserCompanyRole>;
  saveProfileFieldManual?: Maybe<AgentExtraInfo>;
  saveProfileIsPushNotification?: Maybe<UserCompanyRole>;
  saveProfilePersonalInformation?: Maybe<UserCompanyRole>;
  saveQuery?: Maybe<Scalars['Int']>;
  saveSignUpApplicationPersonalInformation?: Maybe<UserCompanyRole>;
  saveTrainingSaleAppointment?: Maybe<Scalars['Int']>;
  saveWorkflowItemValues?: Maybe<WorkflowItem>;
  sendAdjustmentsToReview?: Maybe<Scalars['Int']>;
  sendBulkMessage?: Maybe<Scalars['Int']>;
  sendEmailToTrainingClassAttendees?: Maybe<Scalars['Int']>;
  sendInstructionEmailForTicketAssignee?: Maybe<Scalars['Int']>;
  sendResetPassword?: Maybe<Scalars['Int']>;
  sendTaskToClickUp?: Maybe<Task>;
  signUpWithInvitation?: Maybe<Invitation>;
  startTask?: Maybe<Scalars['Int']>;
  submitAgentApplication?: Maybe<UserCompanyRole>;
  submitCarrierAppointment?: Maybe<CarrierAppointment>;
  submitCommissionAssignment?: Maybe<CommissionAssignment>;
  submitCommissionCycle?: Maybe<CommissionCycle>;
  submitCommissionStatements?: Maybe<Array<Maybe<CommissionStatement>>>;
  submitContactRequest?: Maybe<Scalars['Int']>;
  submitDriverLicense?: Maybe<UserCompanyRole>;
  submitEAndO?: Maybe<EAndO>;
  submitLicenseInfo?: Maybe<LicenseInfo>;
  submitPendingPolicies?: Maybe<Scalars['Int']>;
  submitPendingPolicy?: Maybe<Policy>;
  submitPolicyDeliveryReceipt?: Maybe<Policy>;
  submitTransferAgent?: Maybe<UserCompanyRole>;
  syncANICOPolicy?: Maybe<Policy>;
  syncGroupToMailChimpById?: Maybe<Scalars['Boolean']>;
  syncGroupsToMailChimp?: Maybe<Scalars['String']>;
  takeWorkflowAction?: Maybe<WorkflowItem>;
  terminateAgent?: Maybe<UserCompanyRole>;
  terminateAgentWithCause?: Maybe<UserCompanyRole>;
  terminateAgents?: Maybe<Scalars['Int']>;
  terminateAgentsWithCause?: Maybe<Scalars['Int']>;
  terminateCarrierAppointment?: Maybe<CarrierAppointment>;
  transferEventTicket?: Maybe<Scalars['Int']>;
  unassignEventTicket?: Maybe<EventTicket>;
  unholdCommissionCalculatedEntries?: Maybe<Scalars['Int']>;
  unholdCommissionEntries?: Maybe<Scalars['Int']>;
  unholdCommissionEntry?: Maybe<Scalars['Int']>;
  unpublishedAgentMemo?: Maybe<Memo>;
  updateAgentBankTransaction?: Maybe<AgentBankTransaction>;
  updateAgentFileCategory?: Maybe<Scalars['Int']>;
  updateAgentFileEntry?: Maybe<Scalars['Int']>;
  updateAgentLevel?: Maybe<AgentLevel>;
  updateAgentMemo?: Maybe<Memo>;
  updateAssociateUser?: Maybe<UserCompanyRole>;
  updateCarrier?: Maybe<Carrier>;
  updateCarrierAppointment?: Maybe<CarrierAppointment>;
  updateClickUpConfig?: Maybe<Scalars['Int']>;
  updateClickUpUserDefault?: Maybe<Scalars['Int']>;
  updateCommissionAdjustment?: Maybe<CommissionAdjustment>;
  updateCommissionAdjustmentCategory?: Maybe<CommissionAdjustmentCategory>;
  updateCommissionCycle?: Maybe<CommissionCycle>;
  updateCommissionEntryCategory?: Maybe<CommissionEntryCategoryCompany>;
  updateCommissionOfPolicySplitSales?: Maybe<Array<Maybe<PolicySplitSale>>>;
  updateCommissionPayable?: Maybe<Policy>;
  updateCommissionStatementIsPayRegardlessAmount?: Maybe<Array<Maybe<CommissionStatement>>>;
  updateCommissionStatementTransactionInfo?: Maybe<CommissionStatement>;
  updateCompanyCeoInformation?: Maybe<Company>;
  updateCompanyInformation?: Maybe<Company>;
  updateCompanyMiscellaneousInfo?: Maybe<Company>;
  updateCompanySystemFeatures?: Maybe<Scalars['Int']>;
  updateCompensationFile?: Maybe<CompensationFileEntry>;
  updateCompensationPlan?: Maybe<CompensationPlan>;
  updateCompensationPlanCommission?: Maybe<CompensationPlan>;
  updateCompensationTemplate?: Maybe<CompensationTemplate>;
  updateCompensationTemplateCommission?: Maybe<CompensationTemplate>;
  updateCustomer?: Maybe<Customer>;
  updateDashboardSettings?: Maybe<Scalars['Int']>;
  updateEAndO?: Maybe<EAndO>;
  updateEmailCommunication?: Maybe<Company>;
  updateEmailTemplate?: Maybe<EmailTemplate>;
  updateEvent?: Maybe<Event>;
  updateEventReservation?: Maybe<EventReservation>;
  updateEventSession?: Maybe<EventSession>;
  updateFileEntry?: Maybe<FileEntry>;
  updateGenerationOverrideRequirementWaiver?: Maybe<GenerationOverrideRequirementWaiver>;
  updateGroup?: Maybe<Group>;
  updateLevelHistory?: Maybe<LevelHistory>;
  updateLicense?: Maybe<License>;
  updateLicenseInfo?: Maybe<LicenseInfo>;
  updateLink?: Maybe<Link>;
  updateMaterialFile?: Maybe<TrainingClassSessionFile>;
  updateNote?: Maybe<Note>;
  updatePaymentMode?: Maybe<PaymentMode>;
  updatePoliciesIsAutoUpdateFromDataFeed?: Maybe<Array<Maybe<Policy>>>;
  updatePolicy?: Maybe<Policy>;
  updatePolicyCustomer?: Maybe<Customer>;
  updatePolicyNote?: Maybe<Note>;
  updatePolicyOverriddenCompensationPlan?: Maybe<Scalars['Int']>;
  updatePolicyOwner?: Maybe<Customer>;
  updatePolicyStateCompany?: Maybe<Scalars['Int']>;
  updatePrivileges?: Maybe<Scalars['Int']>;
  updateProduct?: Maybe<Product>;
  updateProductType?: Maybe<ProductType>;
  updateProductionWidgetSettings?: Maybe<Scalars['Int']>;
  updateProfileCommissionSetting?: Maybe<UserCompanyRole>;
  updateProfileEmailPreferences?: Maybe<UserCompanyRole>;
  updateProfilePaymentMethod?: Maybe<BraintreePaymentMethod>;
  updateProfileSMSPreferences?: Maybe<UserCompanyRole>;
  updateReceipt?: Maybe<Policy>;
  updateRecruitingWidgetSettings?: Maybe<Scalars['Int']>;
  updateRole?: Maybe<Role>;
  updateSignUpTemplate?: Maybe<CompanyExtraInfo>;
  updateTask?: Maybe<Task>;
  updateTaskCategoryCompany?: Maybe<TaskCategoryCompany>;
  updateTaskComment?: Maybe<TaskComment>;
  updateTopRecruitersWidgetSettings?: Maybe<Scalars['Int']>;
  updateTourEntry?: Maybe<TourEntry>;
  updateTourVisit?: Maybe<Scalars['Int']>;
  updateTrainingClass?: Maybe<TrainingClass>;
  updateTrainingClassSession?: Maybe<TrainingClassSession>;
  updateUser?: Maybe<User>;
  updateUserCompanyRole?: Maybe<UserCompanyRole>;
  updateUserCompanyRoleStatus?: Maybe<UserCompanyRole>;
  validateFieldTrainingSaleAppointments?: Maybe<Scalars['Boolean']>;
  viewAgentMemoAcknowledgement?: Maybe<Scalars['String']>;
};


export type MutationActivateCommissionAssignmentArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationActivateUserPushNotificationRegistrationArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationAddClickUpUserArgs = {
  clickUpUserId?: InputMaybe<Scalars['ID']>;
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type MutationApproveAgentsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationApproveApplicationArgs = {
  agentLevelId?: InputMaybe<Scalars['ID']>;
  applicantSignature?: InputMaybe<Scalars['String']>;
  printedName?: InputMaybe<Scalars['String']>;
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type MutationApproveCommissionAssignmentArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationApproveCommissionCycleArgs = {
  id: Scalars['ID'];
};


export type MutationApproveDriverLicenseArgs = {
  userCompanyRoleId: Scalars['ID'];
};


export type MutationApproveEAndOArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationApproveLicenseInfoArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationApprovePoliciesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationApprovePolicyArgs = {
  id: Scalars['ID'];
  insuredIssueAge?: InputMaybe<Scalars['Int']>;
  issuedDate?: InputMaybe<Scalars['Date']>;
};


export type MutationAssignEventTicketArgs = {
  assigneeId?: InputMaybe<Scalars['ID']>;
  assigneeType: Scalars['String'];
  guestEmail?: InputMaybe<Scalars['String']>;
  guestFirstName?: InputMaybe<Scalars['String']>;
  guestLastName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationAssignTaskArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationCalculateProfileItemsArgs = {
  userCompanyRoleId: Scalars['ID'];
};


export type MutationChangeAvatarArgs = {
  tmpFileUrl: Scalars['String'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationChangeBackgroundArgs = {
  backgroundUrl?: InputMaybe<Scalars['String']>;
};


export type MutationChangeColorArgs = {
  themePaletteError?: InputMaybe<Scalars['String']>;
  themePaletteInfo?: InputMaybe<Scalars['String']>;
  themePalettePrimary?: InputMaybe<Scalars['String']>;
  themePaletteSecondary?: InputMaybe<Scalars['String']>;
  themePaletteSuccess?: InputMaybe<Scalars['String']>;
  themePaletteWarning?: InputMaybe<Scalars['String']>;
};


export type MutationChangeLogoArgs = {
  logoUrl?: InputMaybe<Scalars['String']>;
};


export type MutationChangeUserPushNotificationRegistrationArgs = {
  deviceName: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type MutationCheckInFromKioskArgs = {
  eventId: Scalars['ID'];
  lastName: Scalars['String'];
  sessionId: Scalars['ID'];
  ticketNumber: Scalars['String'];
};


export type MutationCloseTaskArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationCommissionAdjustmentReviewMarkAsCreatedArgs = {
  id: Scalars['ID'];
};


export type MutationCommissionAdjustmentReviewMarkAsReviewedArgs = {
  id: Scalars['ID'];
};


export type MutationConvertAigCommissionXmLtoCsvArgs = {
  fileUrl: Scalars['String'];
};


export type MutationCreateApclWorkflowItemArgs = {
  agentId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateAgentBankTransactionArgs = {
  agentId: Scalars['ID'];
  commissionStatementId?: InputMaybe<Scalars['ID']>;
  creditAmount: Scalars['Float'];
  debitAmount: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['BigInt']>;
  tmpFileUrl?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<Scalars['String']>;
};


export type MutationCreateAgentFileCategoryArgs = {
  name: Scalars['String'];
};


export type MutationCreateAgentFileEntriesArgs = {
  agentId: Scalars['ID'];
  files?: InputMaybe<Array<InputMaybe<AgentDocumentInput>>>;
};


export type MutationCreateAgentLevelArgs = {
  id?: InputMaybe<Scalars['ID']>;
  isCertifiedAgent?: InputMaybe<Scalars['Boolean']>;
  isStartOfManagerialLevel?: InputMaybe<Scalars['Boolean']>;
  isStartingLevel?: InputMaybe<Scalars['Boolean']>;
  level: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreateAgentMemoArgs = {
  description?: InputMaybe<Scalars['String']>;
  subject: Scalars['String'];
  tmpFileUrl: Scalars['String'];
};


export type MutationCreateApiKeyArgs = {
  description: Scalars['String'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationCreateCarrierArgs = {
  ftpServers?: InputMaybe<Array<InputMaybe<CarrierFtpInput>>>;
  isAllowManualUploadRawCommissionFile?: InputMaybe<Scalars['Boolean']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isFtpSyncEnabled?: InputMaybe<Scalars['Boolean']>;
  providerId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCarrierAppointmentArgs = {
  carrierId: Scalars['ID'];
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['BigInt']>;
  issueDate: Scalars['DateTime'];
  producerCode: Scalars['String'];
  rootProductTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  state: Scalars['String'];
  tmpFileUrl?: InputMaybe<Scalars['String']>;
  userCompanyRoleId: Scalars['ID'];
};


export type MutationCreateCommissionAdjustmentArgs = {
  adjustedAt: Scalars['DateTime'];
  agentId: Scalars['ID'];
  amount?: InputMaybe<Scalars['Float']>;
  categoryCode: Scalars['ID'];
  commissionCalculatedEntryId?: InputMaybe<Scalars['ID']>;
  commissionEntryId?: InputMaybe<Scalars['ID']>;
  excludeUntil?: InputMaybe<Scalars['DateTime']>;
  policyId?: InputMaybe<Scalars['ID']>;
  reason: Scalars['String'];
};


export type MutationCreateCommissionAdjustmentByStatementArgs = {
  adjustedAt: Scalars['DateTime'];
  agentId: Scalars['ID'];
  amount?: InputMaybe<Scalars['Float']>;
  categoryCode: Scalars['ID'];
  commissionStatementId: Scalars['ID'];
  policyId?: InputMaybe<Scalars['ID']>;
  reason: Scalars['String'];
};


export type MutationCreateCommissionAdjustmentCategoryArgs = {
  code: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationCreateCommissionCycleArgs = {
  batchIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  endDate: Scalars['BigInt'];
  processDate: Scalars['BigInt'];
  startDate: Scalars['BigInt'];
};


export type MutationCreateCommissionEligibilityOverrideArgs = {
  policyId: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationCreateCommissionEntryCategoryArgs = {
  backgroundColor: Scalars['String'];
  code: Scalars['ID'];
  priority: Scalars['Int'];
  textColor: Scalars['String'];
};


export type MutationCreateCommissionRawBatchArgs = {
  carrierId: Scalars['ID'];
  fileName: Scalars['String'];
  fileSize?: InputMaybe<Scalars['BigInt']>;
  reportTypeId: Scalars['ID'];
  tmpFileUrl: Scalars['String'];
};


export type MutationCreateCommissionStatementsFromFilesArgs = {
  commissionCycleId: Scalars['ID'];
  uploadedFiles?: InputMaybe<Array<InputMaybe<FileInput>>>;
};


export type MutationCreateCompanyArgs = {
  adminEmail: Scalars['String'];
  adminFirstName: Scalars['String'];
  adminLastName: Scalars['String'];
  companyAddress: Scalars['String'];
  companyCode: Scalars['String'];
  companyDomainName: Scalars['String'];
  companyName: Scalars['String'];
  companyPhone: Scalars['String'];
  companySlogan: Scalars['String'];
};


export type MutationCreateCompensationFilesArgs = {
  compensationPlanId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<Array<InputMaybe<CompensationFileInput>>>;
};


export type MutationCreateCompensationPlanArgs = {
  advanceRate: Scalars['Float'];
  calculationMethodId?: InputMaybe<Scalars['ID']>;
  calculationPercentage?: InputMaybe<Scalars['Int']>;
  carrierId: Scalars['ID'];
  compensationRows: Array<InputMaybe<CompensationRowInput>>;
  compensationTemplateId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  displayingAge?: InputMaybe<Scalars['String']>;
  displayingProduct?: InputMaybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  excessFromYear: Scalars['Int'];
  excessToYear: Scalars['Int'];
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  fromAge: Scalars['Int'];
  generationalOverrides: Array<InputMaybe<CompensationGenerationalOverrideInput>>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  productIds: Array<InputMaybe<Scalars['ID']>>;
  productTypeId: Scalars['ID'];
  renewalFromYear: Scalars['Int'];
  renewalToYear: Scalars['Int'];
  serviceFromYear: Scalars['Int'];
  toAge: Scalars['Int'];
  totalPayout: Scalars['Float'];
};


export type MutationCreateCompensationTemplateArgs = {
  advanceRate: Scalars['Float'];
  calculationMethodId?: InputMaybe<Scalars['ID']>;
  calculationPercentage?: InputMaybe<Scalars['Int']>;
  compensationRows: Array<InputMaybe<CompensationRowInput>>;
  description?: InputMaybe<Scalars['String']>;
  displayingAge?: InputMaybe<Scalars['String']>;
  displayingProduct?: InputMaybe<Scalars['String']>;
  excessFromYear: Scalars['Int'];
  excessToYear: Scalars['Int'];
  fromAge: Scalars['Int'];
  generationalOverrides: Array<InputMaybe<CompensationGenerationalOverrideInput>>;
  name: Scalars['String'];
  renewalFromYear: Scalars['Int'];
  renewalToYear: Scalars['Int'];
  serviceFromYear: Scalars['Int'];
  toAge: Scalars['Int'];
  totalPayout: Scalars['Float'];
};


export type MutationCreateCustomerArgs = {
  input?: InputMaybe<CustomerInput>;
};


export type MutationCreateEAndOArgs = {
  expirationDate: Scalars['DateTime'];
  fileName: Scalars['String'];
  fileSize?: InputMaybe<Scalars['BigInt']>;
  issueDate: Scalars['DateTime'];
  policyNumber: Scalars['String'];
  tmpFileUrl: Scalars['String'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationCreateEmailTemplateArgs = {
  body: Scalars['String'];
  name: Scalars['ID'];
  subject: Scalars['String'];
};


export type MutationCreateEventArgs = {
  checkinInstruction?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  eventCode: Scalars['String'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  password: Scalars['String'];
  registrationExpiryDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};


export type MutationCreateEventReservationArgs = {
  agentId: Scalars['ID'];
  eventId: Scalars['ID'];
  eventSessionIds: Array<InputMaybe<Scalars['ID']>>;
  isTicketManual?: InputMaybe<Scalars['Boolean']>;
  numberOfTickets?: InputMaybe<Scalars['Int']>;
  ticketNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreateEventSessionArgs = {
  eventId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateFilesArgs = {
  files?: InputMaybe<Array<InputMaybe<FileInput>>>;
  parentId?: InputMaybe<Scalars['ID']>;
  parentName?: InputMaybe<Scalars['String']>;
};


export type MutationCreateFolderArgs = {
  input?: InputMaybe<FolderInput>;
};


export type MutationCreateGenerationOverrideRequirementWaiverArgs = {
  agentId?: InputMaybe<Scalars['ID']>;
  fromMonth?: InputMaybe<Scalars['DateTime']>;
  toMonth?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCreateGroupArgs = {
  description?: InputMaybe<Scalars['String']>;
  isSyncToMailChimpAllowed?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationCreateInstructorArgs = {
  instructorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  trainingClassSessionId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateInvitationArgs = {
  email: Scalars['String'];
  recipientFirstName: Scalars['String'];
};


export type MutationCreateLevelHistoryArgs = {
  agentLevelId: Scalars['ID'];
  effectiveDate: Scalars['DateTime'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationCreateLicenseArgs = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationCreateLicenseInfoArgs = {
  expirationDate: Scalars['DateTime'];
  fileName: Scalars['String'];
  fileSize?: InputMaybe<Scalars['BigInt']>;
  issueDate: Scalars['DateTime'];
  licenseId: Scalars['ID'];
  licenseNumber: Scalars['String'];
  npnNumber: Scalars['String'];
  residenceType: Scalars['String'];
  state: Scalars['String'];
  tmpFileUrl: Scalars['String'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationCreateLinkArgs = {
  description: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  url: Scalars['String'];
};


export type MutationCreateMaterialFilesArgs = {
  files?: InputMaybe<Array<InputMaybe<FileInput>>>;
  trainingClassSessionId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateNoteArgs = {
  content: Scalars['String'];
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['BigInt']>;
  tmpFileUrl?: InputMaybe<Scalars['String']>;
  userCompanyRoleId: Scalars['ID'];
};


export type MutationCreatePaymentModeArgs = {
  aliases?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  code: Scalars['ID'];
  name: Scalars['String'];
  premiumMultiplier?: InputMaybe<Scalars['Int']>;
};


export type MutationCreatePolicyArgs = {
  customer?: InputMaybe<CustomerInput>;
  policyNumber: Scalars['String'];
};


export type MutationCreatePolicyExchangeSaleArgs = {
  exchangeSale?: InputMaybe<Scalars['ID']>;
  isExchangeSale?: InputMaybe<Scalars['Boolean']>;
  isExchangeSaleAcknowledged?: InputMaybe<Scalars['Boolean']>;
  oldExchangeSale?: InputMaybe<Scalars['ID']>;
  policyId?: InputMaybe<Scalars['ID']>;
  userAgentId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreatePolicyNoteArgs = {
  content: Scalars['String'];
  policyId: Scalars['ID'];
};


export type MutationCreatePolicySplitSalesArgs = {
  isSplitSale?: InputMaybe<Scalars['Boolean']>;
  oldSplitSales?: InputMaybe<Array<InputMaybe<PolicySplitSaleInput>>>;
  policyId: Scalars['ID'];
  splitSales?: InputMaybe<Array<InputMaybe<PolicySplitSaleInput>>>;
};


export type MutationCreatePolicyStateCompanyArgs = {
  backgroundColor: Scalars['String'];
  policyStateCode: Scalars['ID'];
  priority?: InputMaybe<Scalars['Int']>;
  textColor: Scalars['String'];
};


export type MutationCreatePolicyTrainingSalesArgs = {
  appointmentDate?: InputMaybe<Scalars['Date']>;
  comment?: InputMaybe<Scalars['String']>;
  isTrainingSale?: InputMaybe<Scalars['Boolean']>;
  policyId: Scalars['ID'];
  trainingSaleAppointmentId?: InputMaybe<Scalars['ID']>;
  trainingSales?: InputMaybe<Array<InputMaybe<PolicyTrainingSaleInput>>>;
};


export type MutationCreateProductArgs = {
  aliases?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  carrierId?: InputMaybe<Scalars['ID']>;
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  productTypeId?: InputMaybe<Scalars['ID']>;
  receivingAdvanceCommissionCapAmount?: InputMaybe<Scalars['Float']>;
};


export type MutationCreateProductTypeArgs = {
  aliases?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  code: Scalars['String'];
  comments?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isEAndORequired?: InputMaybe<Scalars['Boolean']>;
  licenseIds: Array<InputMaybe<Scalars['ID']>>;
  name: Scalars['String'];
  parentId: Scalars['ID'];
  usePremiumAsTarget?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateProfilePaymentMethodArgs = {
  extendedAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locality?: InputMaybe<Scalars['String']>;
  makeDefault?: InputMaybe<Scalars['Boolean']>;
  nonceFromTheClient?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
};


export type MutationCreateRoleArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateTaskArgs = {
  assigneeId?: InputMaybe<Scalars['ID']>;
  attachFiles?: InputMaybe<Array<InputMaybe<AttachFileInput>>>;
  belongsToBackOffice?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  currentUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  itemId?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  relatedId?: InputMaybe<Scalars['ID']>;
  reporterId?: InputMaybe<Scalars['ID']>;
  resolution?: InputMaybe<Scalars['String']>;
  screenShotImage?: InputMaybe<Array<InputMaybe<AttachFileInput>>>;
  subject: Scalars['String'];
  tableName?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTaskCategoryCompanyArgs = {
  defaultAssigneeId?: InputMaybe<Scalars['ID']>;
  selectedCarriers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  taskCategoryId: Scalars['ID'];
};


export type MutationCreateTaskCommentArgs = {
  attachFiles?: InputMaybe<Array<InputMaybe<AttachFileInput>>>;
  comment: Scalars['String'];
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};


export type MutationCreateTaskCommentAttachmentArgs = {
  attachFiles?: InputMaybe<Array<InputMaybe<AttachFileInput>>>;
  commentId: Scalars['ID'];
  state?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTaskForSubmissionRequirementWarningsArgs = {
  currentUrl?: InputMaybe<Scalars['String']>;
  itemId: Scalars['String'];
  warnings?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreateTourEntryArgs = {
  contentInMarkdown?: InputMaybe<Scalars['String']>;
  pagePath?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  selector?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTrainingClassArgs = {
  description?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTrainingClassRegistrationWorkflowItemArgs = {
  classSessionId: Scalars['ID'];
  trainingClassId: Scalars['ID'];
};


export type MutationCreateTrainingClassSessionArgs = {
  description?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  trainingClassId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateTrainingClassSessionAssistantArgs = {
  assistantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  trainingClassSessionId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateUserArgs = {
  dateOfBirth?: InputMaybe<Scalars['BigInt']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: Scalars['String'];
  isAgent?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  primaryPhone: Scalars['String'];
  roleId?: InputMaybe<Scalars['ID']>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  sponsorId?: InputMaybe<Scalars['ID']>;
  ssn: Scalars['String'];
};


export type MutationCreateUserAccountLinkArgs = {
  email: Scalars['String'];
  provider: Scalars['String'];
  uid: Scalars['String'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationCreateUserCompanyRoleArgs = {
  businessAddress1?: InputMaybe<Scalars['String']>;
  businessAddress2?: InputMaybe<Scalars['String']>;
  businessCity?: InputMaybe<Scalars['String']>;
  businessState?: InputMaybe<Scalars['String']>;
  businessZip?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['BigInt']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: Scalars['String'];
  homeAddress1: Scalars['String'];
  homeAddress2?: InputMaybe<Scalars['String']>;
  homeCity: Scalars['String'];
  homeState: Scalars['String'];
  homeZip: Scalars['String'];
  isBusinessAddressSameAsHome: Scalars['Boolean'];
  isHomeAddressDefaultMailing: Scalars['Boolean'];
  lastName: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  primaryPhone: Scalars['String'];
  secondaryPhone?: InputMaybe<Scalars['String']>;
  sponsorId?: InputMaybe<Scalars['ID']>;
  ssn: Scalars['String'];
};


export type MutationCreateWorkflowItemArgs = {
  workflowId: Scalars['ID'];
};


export type MutationCreateWorkflowItemCommentArgs = {
  comment?: InputMaybe<Scalars['String']>;
  fieldId?: InputMaybe<Scalars['ID']>;
  itemId: Scalars['ID'];
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationDeactivateCommissionAssignmentArgs = {
  confirmingText: Scalars['String'];
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationDeactivateUserPushNotificationRegistrationArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationDeclinePoliciesByCarrierArgs = {
  confirmingText: Scalars['String'];
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDeclinePolicyByCarrierArgs = {
  confirmingText: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationDeductCommissionStatementArgs = {
  deductionAmount: Scalars['Float'];
  id: Scalars['ID'];
};


export type MutationDeductCommissionStatementsArgs = {
  deductionPercentage: Scalars['Float'];
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationDeleteAgentBankTransactionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAgentFileCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAgentFileEntryArgs = {
  fileId: Scalars['ID'];
};


export type MutationDeleteAgentLevelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAgentMemoArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAgentsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDeleteApiKeyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCarrierArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCarrierAppointmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommissionAdjustmentCategoryArgs = {
  code: Scalars['ID'];
};


export type MutationDeleteCommissionAdjustmentReviewItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommissionAdjustmentsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDeleteCommissionCalculatedEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDeleteCommissionCycleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommissionEligibilityOverrideArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommissionEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDeleteCommissionEntryCategoryArgs = {
  code: Scalars['ID'];
};


export type MutationDeleteCommissionRawBatchArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommissionStatementsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDeleteCompensationFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompensationPlanArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompensationTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDuplicateCommissionEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDeleteEAndOArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationDeleteEmailTemplateArgs = {
  name: Scalars['ID'];
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEventReservationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEventSessionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFileEntryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGenerationOverrideRequirementWaiverArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInstructorArgs = {
  id?: InputMaybe<Scalars['ID']>;
  trainingClassSessionId?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLevelHistoryArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationDeleteLicenseArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLicenseInfoArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationDeleteLinkArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMaterialFileArgs = {
  id: Scalars['ID'];
  trainingClassSessionId?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteNoteArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationDeletePaymentModeArgs = {
  code: Scalars['ID'];
};


export type MutationDeletePendingPoliciesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDeletePendingPolicyArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePolicyExchangeSalesArgs = {
  policyId?: InputMaybe<Scalars['ID']>;
  userAgentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationDeletePolicyNoteArgs = {
  id: Scalars['ID'];
  policyId: Scalars['ID'];
};


export type MutationDeletePolicySplitSalesArgs = {
  policyId?: InputMaybe<Scalars['ID']>;
  userAgentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationDeletePolicyStateCompanyArgs = {
  code: Scalars['ID'];
};


export type MutationDeletePolicyTrainingSaleArgs = {
  policyId?: InputMaybe<Scalars['ID']>;
  userAgentId?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProfileDriverLicenseArgs = {
  fileName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationDeleteProfilePaymentMethodArgs = {
  paymentMethodToken?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteQueryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReceiptArgs = {
  policyId: Scalars['ID'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTaskArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDeleteTaskCategoryCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTaskCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTaskCommentAttachmentArgs = {
  id: Scalars['ID'];
  taskCommentId: Scalars['ID'];
};


export type MutationDeleteTourEntryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTrainingClassArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTrainingClassSessionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTrainingClassSessionAssistantArgs = {
  id?: InputMaybe<Scalars['ID']>;
  trainingClassSessionId?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteTrainingSaleAppointmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserAccountLinkArgs = {
  provider: Scalars['String'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationDeleteUserCompanyRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkflowItemArgs = {
  id: Scalars['ID'];
};


export type MutationDisableEventArgs = {
  id: Scalars['ID'];
};


export type MutationDryRecalculateCommissionEntryArgs = {
  dryRunOverriddenCommissionEntryCategoryCode?: InputMaybe<Scalars['ID']>;
  dryRunOverriddenCompensationPlanId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};


export type MutationEditCommissionAdjustmentReviewItemArgs = {
  adjustment: CommissionAdjustmentReviewItemEditInput;
};


export type MutationEditQueryArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  query: Scalars['String'];
};


export type MutationEnableEventArgs = {
  id: Scalars['ID'];
};


export type MutationExcludeCommissionAdjustmentsArgs = {
  commissionAdjustmentIds: Array<InputMaybe<Scalars['ID']>>;
  commissionStatementId: Scalars['ID'];
};


export type MutationExcludeCommissionCalculatedEntriesArgs = {
  commissionStatementId: Scalars['ID'];
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationExcludeCommissionStatementsFromNachaArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationExcludeUserGroupsArgs = {
  groupId: Scalars['ID'];
  userCompanyRoleIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationExecuteRawSqlQueryArgs = {
  query: Scalars['String'];
};


export type MutationExportAgentAuditArgs = {
  filters?: InputMaybe<AgentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type MutationExportAgentsForDirectoryArgs = {
  filters?: InputMaybe<AgentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type MutationExportCpaReportArgs = {
  filters?: InputMaybe<CommissionEntryFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type MutationExportCarrierAppointmentsArgs = {
  filters?: InputMaybe<CarrierAppointmentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type MutationExportCheckinTicketsArgs = {
  filters?: InputMaybe<EventTicketSessionFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type MutationExportCommissionAdjustmentGeneratedArgs = {
  commissionAdjustmentGenerated?: InputMaybe<Array<InputMaybe<CommissionAdjustmentGeneratedInput>>>;
};


export type MutationExportCommissionAdjustmentsArgs = {
  filters?: InputMaybe<CommissionAdjustmentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type MutationExportCommissionCalculatedEntriesArgs = {
  filters?: InputMaybe<CommissionCalculatedEntryFilterInput>;
};


export type MutationExportCommissionCalculatedEntriesForAgentArgs = {
  filters?: InputMaybe<CommissionCalculatedEntryFilterInput>;
};


export type MutationExportCommissionCalculatedEntriesOnHoldArgs = {
  filters?: InputMaybe<CommissionCalculatedEntryFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type MutationExportCommissionEntriesRecalculateArgs = {
  fields: Array<InputMaybe<Scalars['String']>>;
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationExportCommissionEntryRawQueryArgs = {
  commissionEntryRawQuery?: InputMaybe<Array<InputMaybe<CommissionEntryRawQueryInput>>>;
};


export type MutationExportCommissionStatementsArgs = {
  filters?: InputMaybe<CommissionStatementFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type MutationExportCustomersArgs = {
  filters?: InputMaybe<CustomerFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type MutationExportEventArgs = {
  id: Scalars['ID'];
};


export type MutationExportEventTicketsArgs = {
  filters?: InputMaybe<EventTicketFilterInput>;
};


export type MutationExportForSkipioArgs = {
  filters?: InputMaybe<AgentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type MutationExportLicenseInfosArgs = {
  filters?: InputMaybe<LicenseInfoFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type MutationExportPoliciesArgs = {
  filters?: InputMaybe<PolicyFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type MutationExportProductionStatsArgs = {
  filters?: InputMaybe<ProductionStatFilterInput>;
  metric?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  viewType?: InputMaybe<Scalars['String']>;
};


export type MutationExportRecruitingStatsArgs = {
  filters?: InputMaybe<RecruitingStatFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  viewType?: InputMaybe<Scalars['String']>;
};


export type MutationExportRolesAndPrivilegesArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type MutationExportTasksArgs = {
  filters?: InputMaybe<TaskFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  scope?: InputMaybe<Scalars['String']>;
};


export type MutationExportTransactionsForQuickBooksArgs = {
  commissionCycleId: Scalars['ID'];
};


export type MutationExportWinter2021CashBonusArgs = {
  filters?: InputMaybe<Winter2021StatFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  viewType?: InputMaybe<Scalars['String']>;
};


export type MutationForceFieldManualReacknowledgementArgs = {
  profileId: Scalars['ID'];
};


export type MutationForceMembershipReacknowledgementArgs = {
  profileId: Scalars['ID'];
};


export type MutationGenerateNachaFileForCommissionCycleArgs = {
  commissionCycleId: Scalars['ID'];
  deliveryDate: Scalars['String'];
  fileCreationDate: Scalars['String'];
  fileCreationTime: Scalars['String'];
};


export type MutationHoldCommissionCalculatedEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  onHoldReason?: InputMaybe<Scalars['String']>;
};


export type MutationHoldCommissionCalculatedEntryArgs = {
  id: Scalars['ID'];
  isOnHold?: InputMaybe<Scalars['Boolean']>;
};


export type MutationHoldCommissionEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  onHoldReason?: InputMaybe<Scalars['String']>;
};


export type MutationHoldCommissionEntryArgs = {
  id: Scalars['ID'];
  onHoldReason?: InputMaybe<Scalars['String']>;
};


export type MutationImportTransactionsToCommissionCycleArgs = {
  commissionCycleId: Scalars['ID'];
  tmpFileUrl: Scalars['String'];
};


export type MutationIncludeCommissionAdjustmentsArgs = {
  commissionAdjustmentIds: Array<InputMaybe<Scalars['ID']>>;
  commissionStatementId: Scalars['ID'];
};


export type MutationIncludeCommissionCalculatedEntriesArgs = {
  commissionStatementId: Scalars['ID'];
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationIncludeCommissionStatementsToNachaArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationIncludeUserGroupsArgs = {
  groupId: Scalars['ID'];
  userCompanyRoleIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationLockdownEventArgs = {
  id: Scalars['ID'];
};


export type MutationMakeCompensationPlanCopyArgs = {
  id: Scalars['ID'];
};


export type MutationMarkAgentsAsActivatedArgs = {
  confirmingText: Scalars['String'];
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkAgentsAsInactivatedArgs = {
  confirmingText: Scalars['String'];
  ids: Array<InputMaybe<Scalars['ID']>>;
  isBaseshopManagerActivationAllowed?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMarkAsDuplicatedCommissionEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkAsExcludedCommissionEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkAsFinalizedAgentBankTransactionArgs = {
  id: Scalars['ID'];
};


export type MutationMarkAsIncludedCommissionEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkAsIncludedFromDuplicatedCommissionEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkCommisisonStatementsExcludedFromSubmissionArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  reason: Scalars['String'];
};


export type MutationMarkCommissionCycleAsCompleteArgs = {
  id: Scalars['ID'];
};


export type MutationMarkCommissionCycleAsPaymentPendingArgs = {
  id: Scalars['ID'];
};


export type MutationMarkCommissionStatementAsExcludedFromSubmissionArgs = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationMarkCommissionStatementAsPaymentRejectedArgs = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationMarkCommissionStatementAsPendingArgs = {
  id: Scalars['ID'];
};


export type MutationMarkCommissionStatementAsReadyForSubmissionArgs = {
  id: Scalars['ID'];
};


export type MutationMarkEventTicketSessionAsCheckedArgs = {
  id: Scalars['ID'];
  isChecked: Scalars['Boolean'];
};


export type MutationMarkPoliciesAsCanceledByClientArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkPoliciesAsDeliveredArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkPoliciesAsIssuedArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkPoliciesAsPaidArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkPoliciesAsPaidByCarrierArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkPoliciesAsTerminatedByCarrierArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkPolicyAsCanceledByClientArgs = {
  id: Scalars['ID'];
};


export type MutationMarkPolicyAsClosedArgs = {
  id: Scalars['ID'];
};


export type MutationMarkPolicyAsDeliveredArgs = {
  id: Scalars['ID'];
};


export type MutationMarkPolicyAsDuplicateArgs = {
  duplicatePolicyId: Scalars['ID'];
  principalPolicyId: Scalars['ID'];
};


export type MutationMarkPolicyAsIssuedArgs = {
  id: Scalars['ID'];
};


export type MutationMarkPolicyAsPaidArgs = {
  id: Scalars['ID'];
};


export type MutationMarkPolicyAsPaidByCarrierArgs = {
  id: Scalars['ID'];
};


export type MutationMarkPolicyAsTerminatedByCarrierArgs = {
  id: Scalars['ID'];
};


export type MutationMarkPolicyAsUnduplicatedArgs = {
  duplicatePolicyId: Scalars['ID'];
  principalPolicyId: Scalars['ID'];
};


export type MutationMarkReviewedCommissionAdjustmentReviewItemArgs = {
  commissionAdjustmentReviewItemId: Scalars['ID'];
};


export type MutationMarkReviewedCommissionAdjustmentReviewItemsArgs = {
  commissionAdjustmentReviewItemIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkUnreviewedCommissionAdjustmentReviewItemArgs = {
  commissionAdjustmentReviewItemId: Scalars['ID'];
};


export type MutationMarkUnreviewedCommissionAdjustmentReviewItemsArgs = {
  commissionAdjustmentReviewItemIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationMarkWorkflowAsDisabledArgs = {
  workflowId: Scalars['ID'];
};


export type MutationMarkWorkflowAsEnabledArgs = {
  workflowId: Scalars['ID'];
};


export type MutationMarkWorkflowAsUserInitializationAllowedArgs = {
  workflowId: Scalars['ID'];
};


export type MutationMarkWorkflowAsUserInitializationDisallowedArgs = {
  workflowId: Scalars['ID'];
};


export type MutationMergeProductArgs = {
  sourceProductId?: InputMaybe<Scalars['ID']>;
  targetProductId?: InputMaybe<Scalars['ID']>;
};


export type MutationMigrateIaaArgs = {
  credential: Scalars['String'];
};


export type MutationMoveItemToTrashArgs = {
  id: Scalars['ID'];
  isInTrash?: InputMaybe<Scalars['Boolean']>;
};


export type MutationObsoletePolicyArgs = {
  confirmingText: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationObsoleteTaskArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationOverrideCommissionCalculatedEntryArgs = {
  id: Scalars['ID'];
  overriddenAdjustedPayout?: InputMaybe<Scalars['Float']>;
  overriddenExcessPercentage?: InputMaybe<Scalars['Float']>;
  overriddenFirstYearPercentage?: InputMaybe<Scalars['Float']>;
  overriddenGenerationalDepth?: InputMaybe<Scalars['Int']>;
  overriddenGenerationalOverrideNote?: InputMaybe<Scalars['String']>;
  overriddenGenerationalOverridePercentage?: InputMaybe<Scalars['Float']>;
  overriddenIsPaymentEligible?: InputMaybe<Scalars['Boolean']>;
  overriddenIsQualifiedForGenerationalOverride?: InputMaybe<Scalars['Boolean']>;
  overriddenNote?: InputMaybe<Scalars['String']>;
  overriddenPayout?: InputMaybe<Scalars['Float']>;
  overriddenPayoutPercentage?: InputMaybe<Scalars['Float']>;
  overriddenRenewalPercentage?: InputMaybe<Scalars['Float']>;
  overriddenRollingBonusPercentage?: InputMaybe<Scalars['Float']>;
  overriddenServicePercentage?: InputMaybe<Scalars['Float']>;
};


export type MutationOverrideCommissionEntryArgs = {
  id: Scalars['ID'];
  overriddenCarrierAppointmentId?: InputMaybe<Scalars['ID']>;
  overriddenCommissionEntryCategoryCode?: InputMaybe<Scalars['String']>;
  overriddenCustomerId?: InputMaybe<Scalars['ID']>;
  overriddenNote?: InputMaybe<Scalars['String']>;
  overriddenPolicyId?: InputMaybe<Scalars['ID']>;
  overriddenProductId?: InputMaybe<Scalars['ID']>;
  overriddenProductTypeId?: InputMaybe<Scalars['ID']>;
  overriddenUserAgentId?: InputMaybe<Scalars['ID']>;
};


export type MutationProcessOverrideBonusArgs = {
  tmpFileUrl: Scalars['String'];
};


export type MutationPublishAgentMemoArgs = {
  id: Scalars['ID'];
};


export type MutationPublishCommissionCycleArgs = {
  id: Scalars['ID'];
  isPublishing?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishCommissionStatementsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  isPublishing?: InputMaybe<Scalars['Boolean']>;
};


export type MutationReacknowledgeMembershipAgreementArgs = {
  printedName?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
};


export type MutationReassignCarrierAppointmentArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationRecalculateAndAdjustCommissionCalculatedEntriesArgs = {
  filters?: InputMaybe<CommissionCalculatedEntryFilterInput>;
};


export type MutationRecalculateAndAdjustCommissionEntriesArgs = {
  filters?: InputMaybe<CommissionEntryFilterInput>;
};


export type MutationRecalculateAndAdjustCommissionEntriesByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationRecalculateCommissionCalculatedEntryArgs = {
  id: Scalars['ID'];
};


export type MutationRecalculateCommissionCycleArgs = {
  id: Scalars['ID'];
};


export type MutationRecalculateCommissionEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationRecalculateCommissionEntryArgs = {
  id: Scalars['ID'];
};


export type MutationRecalculateCommissionRawBatchArgs = {
  id: Scalars['ID'];
  reimport?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRecalculateCommissionStatementsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationRecalculateEligibilityCommissionCalculatedEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationRecalculateWorkflowItemPermissionArgs = {
  itemId: Scalars['ID'];
};


export type MutationRecalculateWorkflowItemPermissionsArgs = {
  itemIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationRecallCommissionAssignmentArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationRefershAttendTrainingClassArgs = {
  itemFieldId: Scalars['ID'];
  workflowItemId: Scalars['ID'];
};


export type MutationRefreshAigContractPrerequisiteListArgs = {
  itemFieldId: Scalars['ID'];
  workflowItemId: Scalars['ID'];
};


export type MutationRefreshActionPlanChecklistArgs = {
  itemFieldId: Scalars['ID'];
  workflowItemId: Scalars['ID'];
};


export type MutationRefreshAppointedListArgs = {
  itemFieldId: Scalars['ID'];
  workflowItemId: Scalars['ID'];
};


export type MutationRefreshGenerationalOverrideQualificationArgs = {
  monthKeyFromReport?: InputMaybe<Scalars['String']>;
};


export type MutationRefreshLevelStatsForAgentsArgs = {
  agentIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationRefreshPersonallyCloseIulSalesArgs = {
  workflowItemId: Scalars['ID'];
};


export type MutationRefreshPhase1UniversityClassArgs = {
  workflowItemId: Scalars['ID'];
};


export type MutationRefreshPhase2UniversityClassArgs = {
  workflowItemId: Scalars['ID'];
};


export type MutationRefreshSaleStatsForAgentsArgs = {
  agentIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationRefreshStartBuildingChecklistArgs = {
  itemFieldId: Scalars['ID'];
  workflowItemId: Scalars['ID'];
};


export type MutationRefreshTrainingClassRequirementsArgs = {
  workflowItemFieldId: Scalars['ID'];
  workflowItemId: Scalars['ID'];
};


export type MutationRefreshTrainingSaleAppointmentListArgs = {
  itemFieldId: Scalars['ID'];
  workflowItemId: Scalars['ID'];
};


export type MutationRefreshUniversityClassArgs = {
  itemFieldId: Scalars['ID'];
  workflowItemId: Scalars['ID'];
};


export type MutationRegenerateMembershipAgreementArgs = {
  profileId: Scalars['ID'];
};


export type MutationRegisterUserPushNotificationRegistrationArgs = {
  deviceName: Scalars['String'];
  token: Scalars['String'];
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type MutationRejectAgentsArgs = {
  confirmingText: Scalars['String'];
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationRejectApplicationArgs = {
  applicantSignature?: InputMaybe<Scalars['String']>;
  printedName?: InputMaybe<Scalars['String']>;
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type MutationRejectCommissionAssignmentArgs = {
  confirmingText: Scalars['String'];
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationRejectCommissionCycleArgs = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationRejectDriverLicenseArgs = {
  driverLicenseConfirmingText: Scalars['String'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationRejectEAndOArgs = {
  confirmingText: Scalars['String'];
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationRejectLicenseInfoArgs = {
  confirmingText: Scalars['String'];
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationRejectPoliciesArgs = {
  confirmingText: Scalars['String'];
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationRejectPoliciesByClientArgs = {
  confirmingText: Scalars['String'];
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationRejectPolicyArgs = {
  confirmingText: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationRejectPolicyByClientArgs = {
  confirmingText: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationRemoveClickUpUserArgs = {
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type MutationRemovePolicyTrainingSaleAppointmentArgs = {
  policyId?: InputMaybe<Scalars['ID']>;
};


export type MutationRemoveTransactionsFromCommissionCycleArgs = {
  commissionCycleId: Scalars['ID'];
};


export type MutationReportIssueArgs = {
  attachFileName?: InputMaybe<Scalars['String']>;
  attachFileUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  currentUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  screenshot?: InputMaybe<AttachFileInput>;
  subject: Scalars['String'];
  technicalInfo?: InputMaybe<Scalars['JSONObject']>;
};


export type MutationResendCheckinInstructionsToAllAttendeesArgs = {
  id: Scalars['ID'];
};


export type MutationResendInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationResetWorkflowItemValuesArgs = {
  id: Scalars['ID'];
};


export type MutationResolveTaskArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationReturnCommissionAssignmentArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationReturnCommissionStatementsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationReturnPolicyToThePreviousStatusArgs = {
  id: Scalars['ID'];
};


export type MutationRevokeUserPushNotificationRegistrationArgs = {
  deviceName: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type MutationRunCliArgs = {
  argv?: InputMaybe<Scalars['JSONObject']>;
  scriptName: Scalars['String'];
};


export type MutationSaveAgentApplicationBackgroundArgs = {
  questions: Array<InputMaybe<AgentApplicationBackgroundQuestionInput>>;
};


export type MutationSaveAgentApplicationEmploymentArgs = {
  agentApplicationEmployments?: InputMaybe<Array<InputMaybe<AgentApplicationEmploymentInput>>>;
  agentApplicationReferences?: InputMaybe<Array<InputMaybe<AgentApplicationReferenceInput>>>;
};


export type MutationSaveCommissionAdjustmentReviewItemArgs = {
  commissionAdjustmentReviewItemId: Scalars['ID'];
};


export type MutationSaveCommissionAdjustmentReviewItemsArgs = {
  commissionAdjustmentReviewItemIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationSaveCommissionAdjustmentsArgs = {
  indexes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationSaveCommissionAssignmentArgs = {
  accountNumber: Scalars['String'];
  accountType: Scalars['String'];
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  base64Signature?: InputMaybe<Scalars['String']>;
  businessName?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  employerIdentificationNumber?: InputMaybe<Scalars['String']>;
  exemptFromFATCAReportingCode?: InputMaybe<Scalars['String']>;
  exemptPayeeCode?: InputMaybe<Scalars['String']>;
  federalTaxClassification: Scalars['String'];
  isAgreeTerms?: InputMaybe<Scalars['Boolean']>;
  isReassignCommission?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  ownershipType: Scalars['String'];
  printedName?: InputMaybe<Scalars['String']>;
  routingNumber: Scalars['String'];
  signedCommissionAssignmentFormFileName?: InputMaybe<Scalars['String']>;
  signedCommissionAssignmentFormFileSize?: InputMaybe<Scalars['BigInt']>;
  ssn?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  taxpayerType: Scalars['String'];
  tmpSignedCommissionAssignmentFormFileUrl?: InputMaybe<Scalars['String']>;
  tmpVoidedCheckUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tmpW9Urls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userCompanyRoleId: Scalars['ID'];
  voidedCheckFiles?: InputMaybe<Array<InputMaybe<CommissionAssignmentFileInput>>>;
  w9Files?: InputMaybe<Array<InputMaybe<CommissionAssignmentFileInput>>>;
  zip: Scalars['String'];
};


export type MutationSaveCompensationPlanAnnuitiesArgs = {
  compensationAnnuities: Array<InputMaybe<CompensationAnnuityInput>>;
  compensationPlanId: Scalars['ID'];
};


export type MutationSaveCompensationPlanProductReceivingCommissionsArgs = {
  compensationPlanId: Scalars['ID'];
  compensationProductReceivingCommissions: Array<InputMaybe<CompensationProductReceivingCommissionInput>>;
};


export type MutationSaveProfileAgentInformationArgs = {
  agentTypeId: Scalars['String'];
  id: Scalars['ID'];
  isAgent: Scalars['Boolean'];
  isCertified: Scalars['Boolean'];
  isCertifiedTrainer: Scalars['Boolean'];
  isCommissionUsedAsPaymentAllowed?: InputMaybe<Scalars['Boolean']>;
  isExcludedAsSponsor: Scalars['Boolean'];
  producingAgentEffectiveDate?: InputMaybe<Scalars['BigInt']>;
};


export type MutationSaveProfileContactInformationArgs = {
  businessAddress1?: InputMaybe<Scalars['String']>;
  businessAddress2?: InputMaybe<Scalars['String']>;
  businessCity?: InputMaybe<Scalars['String']>;
  businessState?: InputMaybe<Scalars['String']>;
  businessZip?: InputMaybe<Scalars['String']>;
  homeAddress1: Scalars['String'];
  homeAddress2?: InputMaybe<Scalars['String']>;
  homeCity: Scalars['String'];
  homeState: Scalars['String'];
  homeZip: Scalars['String'];
  id: Scalars['ID'];
  isBusinessAddressSameAsHome: Scalars['Boolean'];
  isHomeAddressDefaultMailing: Scalars['Boolean'];
};


export type MutationSaveProfileDriverLicenseArgs = {
  fileName: Scalars['String'];
  fileSize: Scalars['BigInt'];
  fileType: Scalars['String'];
  id: Scalars['ID'];
  tmpFileUrl: Scalars['String'];
};


export type MutationSaveProfileFieldManualArgs = {
  base64SignatureFieldManual?: InputMaybe<Scalars['String']>;
  isAgreeTermsFieldManual: Scalars['Boolean'];
  printedNameFieldManual: Scalars['String'];
  profileId: Scalars['ID'];
};


export type MutationSaveProfileIsPushNotificationArgs = {
  isPushNotification: Scalars['Boolean'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationSaveProfilePersonalInformationArgs = {
  dateOfBirth?: InputMaybe<Scalars['BigInt']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  primaryPhone: Scalars['String'];
  secondaryPhone?: InputMaybe<Scalars['String']>;
  ssn: Scalars['String'];
};


export type MutationSaveQueryArgs = {
  name: Scalars['String'];
  query: Scalars['String'];
};


export type MutationSaveSignUpApplicationPersonalInformationArgs = {
  businessAddress1?: InputMaybe<Scalars['String']>;
  businessAddress2?: InputMaybe<Scalars['String']>;
  businessCity?: InputMaybe<Scalars['String']>;
  businessState?: InputMaybe<Scalars['String']>;
  businessZip?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['BigInt']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: Scalars['String'];
  homeAddress1: Scalars['String'];
  homeAddress2?: InputMaybe<Scalars['String']>;
  homeCity: Scalars['String'];
  homeState: Scalars['String'];
  homeZip: Scalars['String'];
  isBusinessAddressSameAsHome: Scalars['Boolean'];
  isHomeAddressDefaultMailing: Scalars['Boolean'];
  lastName: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  primaryPhone: Scalars['String'];
  secondaryPhone?: InputMaybe<Scalars['String']>;
  sponsorId?: InputMaybe<Scalars['ID']>;
  ssn: Scalars['String'];
};


export type MutationSaveTrainingSaleAppointmentArgs = {
  comment?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['ID']>;
  policyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  traineeId?: InputMaybe<Scalars['ID']>;
  trainerId?: InputMaybe<Scalars['ID']>;
};


export type MutationSaveWorkflowItemValuesArgs = {
  id: Scalars['ID'];
  values?: InputMaybe<Array<InputMaybe<WorkflowItemValueInput>>>;
};


export type MutationSendAdjustmentsToReviewArgs = {
  adjustments: Array<InputMaybe<CommissionAdjustmentReviewItemInput>>;
  createdAt: Scalars['BigInt'];
  summary?: InputMaybe<Scalars['String']>;
  taskId: Scalars['Int'];
};


export type MutationSendBulkMessageArgs = {
  body: Scalars['String'];
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  isCopyMe?: InputMaybe<Scalars['Boolean']>;
  subject: Scalars['String'];
  targetUrl?: InputMaybe<Scalars['String']>;
  typeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationSendEmailToTrainingClassAttendeesArgs = {
  attachFiles?: InputMaybe<Array<InputMaybe<AttachFileInput>>>;
  attendeeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  body: Scalars['String'];
  isCopyMe?: InputMaybe<Scalars['Boolean']>;
  subject: Scalars['String'];
  trainingClassId: Scalars['ID'];
};


export type MutationSendInstructionEmailForTicketAssigneeArgs = {
  id: Scalars['ID'];
};


export type MutationSendResetPasswordArgs = {
  userCompanyRoleId: Scalars['ID'];
};


export type MutationSendTaskToClickUpArgs = {
  id: Scalars['ID'];
};


export type MutationSignUpWithInvitationArgs = {
  token: Scalars['ID'];
};


export type MutationStartTaskArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationSubmitAgentApplicationArgs = {
  applicantSignature?: InputMaybe<Scalars['String']>;
  invitationToken?: InputMaybe<Scalars['String']>;
  printedName?: InputMaybe<Scalars['String']>;
};


export type MutationSubmitCarrierAppointmentArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationSubmitCommissionAssignmentArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationSubmitCommissionCycleArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitCommissionStatementsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationSubmitContactRequestArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gReCaptchaToken?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};


export type MutationSubmitDriverLicenseArgs = {
  userCompanyRoleId: Scalars['ID'];
};


export type MutationSubmitEAndOArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationSubmitLicenseInfoArgs = {
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationSubmitPendingPoliciesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationSubmitPendingPolicyArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitPolicyDeliveryReceiptArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitTransferAgentArgs = {
  transferToAgentId: Scalars['ID'];
  transferType: Scalars['String'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationSyncAnicoPolicyArgs = {
  input?: InputMaybe<Scalars['JSONObject']>;
};


export type MutationSyncGroupToMailChimpByIdArgs = {
  groupId: Scalars['ID'];
};


export type MutationTakeWorkflowActionArgs = {
  actionId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  itemId: Scalars['ID'];
  signature?: InputMaybe<Scalars['String']>;
};


export type MutationTerminateAgentArgs = {
  confirmingCode: Scalars['String'];
  confirmingEffectiveDate?: InputMaybe<Scalars['DateTime']>;
  confirmingText: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationTerminateAgentWithCauseArgs = {
  confirmingCode: Scalars['String'];
  confirmingEffectiveDate?: InputMaybe<Scalars['DateTime']>;
  confirmingText: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationTerminateAgentsArgs = {
  confirmingCode: Scalars['String'];
  confirmingText: Scalars['String'];
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationTerminateAgentsWithCauseArgs = {
  confirmingCode: Scalars['String'];
  confirmingText: Scalars['String'];
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationTerminateCarrierAppointmentArgs = {
  id: Scalars['ID'];
  terminationDate: Scalars['DateTime'];
};


export type MutationTransferEventTicketArgs = {
  agentId?: InputMaybe<Scalars['ID']>;
  eventId?: InputMaybe<Scalars['ID']>;
  eventReservationId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};


export type MutationUnassignEventTicketArgs = {
  id: Scalars['ID'];
};


export type MutationUnholdCommissionCalculatedEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  onHoldReason?: InputMaybe<Scalars['String']>;
};


export type MutationUnholdCommissionEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  onHoldReason?: InputMaybe<Scalars['String']>;
};


export type MutationUnholdCommissionEntryArgs = {
  id: Scalars['ID'];
  onHoldReason?: InputMaybe<Scalars['String']>;
};


export type MutationUnpublishedAgentMemoArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateAgentBankTransactionArgs = {
  agentId: Scalars['ID'];
  commissionStatementId?: InputMaybe<Scalars['ID']>;
  creditAmount: Scalars['Float'];
  debitAmount: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  tmpFileUrl?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAgentFileCategoryArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateAgentFileEntryArgs = {
  categoryId?: InputMaybe<Scalars['ID']>;
  fileId: Scalars['ID'];
  fileName: Scalars['String'];
};


export type MutationUpdateAgentLevelArgs = {
  id: Scalars['ID'];
  isCertifiedAgent?: InputMaybe<Scalars['Boolean']>;
  isStartOfManagerialLevel?: InputMaybe<Scalars['Boolean']>;
  isStartingLevel?: InputMaybe<Scalars['Boolean']>;
  level: Scalars['String'];
  title: Scalars['String'];
};


export type MutationUpdateAgentMemoArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  subject: Scalars['String'];
  tmpFileUrl?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAssociateUserArgs = {
  id: Scalars['ID'];
  roleId: Scalars['ID'];
};


export type MutationUpdateCarrierArgs = {
  ftpServers?: InputMaybe<Array<InputMaybe<CarrierFtpInput>>>;
  id: Scalars['ID'];
  isAllowManualUploadRawCommissionFile?: InputMaybe<Scalars['Boolean']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isFtpSyncEnabled?: InputMaybe<Scalars['Boolean']>;
  providerId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCarrierAppointmentArgs = {
  carrierId: Scalars['ID'];
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  issueDate: Scalars['DateTime'];
  producerCode: Scalars['String'];
  rootProductTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  state: Scalars['String'];
  tmpFileUrl?: InputMaybe<Scalars['String']>;
  userCompanyRoleId: Scalars['ID'];
};


export type MutationUpdateClickUpConfigArgs = {
  authenticationToken?: InputMaybe<Scalars['String']>;
  listId?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateClickUpUserDefaultArgs = {
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateCommissionAdjustmentArgs = {
  adjustedAt: Scalars['DateTime'];
  agentId: Scalars['ID'];
  amount?: InputMaybe<Scalars['Float']>;
  categoryCode: Scalars['ID'];
  commissionCalculatedEntryId?: InputMaybe<Scalars['ID']>;
  commissionEntryId?: InputMaybe<Scalars['ID']>;
  excludeUntil?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  policyId?: InputMaybe<Scalars['ID']>;
  reason: Scalars['String'];
};


export type MutationUpdateCommissionAdjustmentCategoryArgs = {
  code: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationUpdateCommissionCycleArgs = {
  endDate: Scalars['BigInt'];
  id: Scalars['ID'];
  processDate: Scalars['BigInt'];
  startDate: Scalars['BigInt'];
};


export type MutationUpdateCommissionEntryCategoryArgs = {
  backgroundColor: Scalars['String'];
  code: Scalars['ID'];
  priority: Scalars['Int'];
  textColor: Scalars['String'];
};


export type MutationUpdateCommissionOfPolicySplitSalesArgs = {
  input?: InputMaybe<Array<InputMaybe<PolicySplitSaleInput>>>;
};


export type MutationUpdateCommissionPayableArgs = {
  effectiveDate?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['ID']>;
  isCommissionPayableToServicingAgent?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCommissionStatementIsPayRegardlessAmountArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isPayRegardlessAmount?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCommissionStatementTransactionInfoArgs = {
  id: Scalars['ID'];
  transactionDate: Scalars['DateTime'];
  transactionId: Scalars['String'];
};


export type MutationUpdateCompanyCeoInformationArgs = {
  ceoName?: InputMaybe<Scalars['String']>;
  ceoTitle?: InputMaybe<Scalars['String']>;
  code: Scalars['ID'];
};


export type MutationUpdateCompanyInformationArgs = {
  address?: InputMaybe<Scalars['String']>;
  code: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  slogan?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCompanyMiscellaneousInfoArgs = {
  code: Scalars['ID'];
  employmentMinDurationRequired?: InputMaybe<Scalars['Int']>;
  googleCalendarId?: InputMaybe<Scalars['String']>;
  helpDriveId?: InputMaybe<Scalars['String']>;
  maxHierarchyDepth: Scalars['Int'];
  minExchangeSalePointsRequired: Scalars['Int'];
  rootHierarchyId: Scalars['ID'];
  tmpFieldManualUrl?: InputMaybe<Scalars['String']>;
  tmpSignedCommissionReassignmentFormUrl?: InputMaybe<Scalars['String']>;
  tmpW9FormUrl?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCompanySystemFeaturesArgs = {
  features?: InputMaybe<Array<InputMaybe<CompanySystemFeatureInput>>>;
};


export type MutationUpdateCompensationFileArgs = {
  input?: InputMaybe<CompensationFileEntryInput>;
};


export type MutationUpdateCompensationPlanArgs = {
  advanceRate: Scalars['Float'];
  calculationMethodId?: InputMaybe<Scalars['ID']>;
  calculationPercentage?: InputMaybe<Scalars['Int']>;
  carrierId: Scalars['ID'];
  compensationRows: Array<InputMaybe<CompensationRowInput>>;
  compensationTemplateId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  displayingAge?: InputMaybe<Scalars['String']>;
  displayingProduct?: InputMaybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  excessFromYear: Scalars['Int'];
  excessToYear: Scalars['Int'];
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  fromAge: Scalars['Int'];
  generationalOverrides: Array<InputMaybe<CompensationGenerationalOverrideInput>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  productIds: Array<InputMaybe<Scalars['ID']>>;
  productTypeId: Scalars['ID'];
  renewalFromYear: Scalars['Int'];
  renewalToYear: Scalars['Int'];
  serviceFromYear: Scalars['Int'];
  toAge: Scalars['Int'];
  totalPayout: Scalars['Float'];
};


export type MutationUpdateCompensationPlanCommissionArgs = {
  compensationRows: Array<InputMaybe<CompensationRowInput>>;
  generationalOverrides: Array<InputMaybe<CompensationGenerationalOverrideInput>>;
  id: Scalars['ID'];
  totalPayout: Scalars['Float'];
};


export type MutationUpdateCompensationTemplateArgs = {
  advanceRate: Scalars['Float'];
  calculationMethodId?: InputMaybe<Scalars['ID']>;
  calculationPercentage?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  displayingAge?: InputMaybe<Scalars['String']>;
  displayingProduct?: InputMaybe<Scalars['String']>;
  excessFromYear: Scalars['Int'];
  excessToYear: Scalars['Int'];
  fromAge: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  renewalFromYear: Scalars['Int'];
  renewalToYear: Scalars['Int'];
  serviceFromYear: Scalars['Int'];
  toAge: Scalars['Int'];
};


export type MutationUpdateCompensationTemplateCommissionArgs = {
  compensationRows: Array<InputMaybe<CompensationRowInput>>;
  generationalOverrides: Array<InputMaybe<CompensationGenerationalOverrideInput>>;
  id: Scalars['ID'];
  totalPayout: Scalars['Float'];
};


export type MutationUpdateCustomerArgs = {
  customer: CustomerInput;
};


export type MutationUpdateDashboardSettingsArgs = {
  input?: InputMaybe<Array<InputMaybe<WidgetUserInput>>>;
};


export type MutationUpdateEAndOArgs = {
  expirationDate: Scalars['DateTime'];
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  issueDate: Scalars['DateTime'];
  policyNumber: Scalars['String'];
  tmpFileUrl?: InputMaybe<Scalars['String']>;
  userCompanyRoleId: Scalars['ID'];
};


export type MutationUpdateEmailCommunicationArgs = {
  code: Scalars['ID'];
  defaultReplyToEmail?: InputMaybe<Scalars['String']>;
  defaultSenderEmail?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateEmailTemplateArgs = {
  body: Scalars['String'];
  name: Scalars['ID'];
  subject: Scalars['String'];
};


export type MutationUpdateEventArgs = {
  checkinInstruction?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  eventCode: Scalars['String'];
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  password: Scalars['String'];
  registrationExpiryDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};


export type MutationUpdateEventReservationArgs = {
  agentId: Scalars['ID'];
  eventId: Scalars['ID'];
  eventSessionIds: Array<InputMaybe<Scalars['ID']>>;
  id: Scalars['ID'];
  isTicketManual?: InputMaybe<Scalars['Boolean']>;
  numberOfTickets?: InputMaybe<Scalars['Int']>;
  ticketNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdateEventSessionArgs = {
  eventId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  ticketCount?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateFileEntryArgs = {
  input?: InputMaybe<FileEntryInput>;
};


export type MutationUpdateGenerationOverrideRequirementWaiverArgs = {
  agentId?: InputMaybe<Scalars['ID']>;
  fromMonth?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  toMonth?: InputMaybe<Scalars['DateTime']>;
};


export type MutationUpdateGroupArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isSyncToMailChimpAllowed?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationUpdateLevelHistoryArgs = {
  agentLevelId: Scalars['ID'];
  effectiveDate: Scalars['DateTime'];
  id: Scalars['ID'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationUpdateLicenseArgs = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationUpdateLicenseInfoArgs = {
  expirationDate: Scalars['DateTime'];
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  issueDate: Scalars['DateTime'];
  licenseId: Scalars['ID'];
  licenseNumber: Scalars['String'];
  npnNumber: Scalars['String'];
  residenceType: Scalars['String'];
  state: Scalars['String'];
  tmpFileUrl?: InputMaybe<Scalars['String']>;
  userCompanyRoleId: Scalars['ID'];
};


export type MutationUpdateLinkArgs = {
  description: Scalars['String'];
  id: Scalars['ID'];
  priority?: InputMaybe<Scalars['Int']>;
  url: Scalars['String'];
};


export type MutationUpdateMaterialFileArgs = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  trainingClassSessionId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateNoteArgs = {
  content: Scalars['String'];
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  tmpFileUrl?: InputMaybe<Scalars['String']>;
  userCompanyRoleId: Scalars['ID'];
};


export type MutationUpdatePaymentModeArgs = {
  aliases?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  code: Scalars['String'];
  name: Scalars['String'];
  originalCode: Scalars['ID'];
  premiumMultiplier: Scalars['Int'];
};


export type MutationUpdatePoliciesIsAutoUpdateFromDataFeedArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  isAutoUpdateFromDataFeed?: InputMaybe<Scalars['Boolean']>;
  isTheAnnualizedPremiumLocked?: InputMaybe<Scalars['Boolean']>;
  isTheSplitSalesLocked?: InputMaybe<Scalars['Boolean']>;
  isTheTargetPremiumLocked?: InputMaybe<Scalars['Boolean']>;
  isTheWritingAgentLocked?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdatePolicyArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<PolicyInput>;
};


export type MutationUpdatePolicyCustomerArgs = {
  customer: CustomerInput;
  id: Scalars['ID'];
};


export type MutationUpdatePolicyNoteArgs = {
  content: Scalars['String'];
  id: Scalars['ID'];
  policyId: Scalars['ID'];
};


export type MutationUpdatePolicyOverriddenCompensationPlanArgs = {
  overriddenCompensationPlanId?: InputMaybe<Scalars['ID']>;
  policyId: Scalars['ID'];
};


export type MutationUpdatePolicyOwnerArgs = {
  id: Scalars['ID'];
  isTheOwnerTheInsured?: InputMaybe<Scalars['Boolean']>;
  owner: CustomerInput;
};


export type MutationUpdatePolicyStateCompanyArgs = {
  backgroundColor: Scalars['String'];
  code: Scalars['ID'];
  priority: Scalars['Int'];
  textColor: Scalars['String'];
};


export type MutationUpdatePrivilegesArgs = {
  rolePrivileges: Array<InputMaybe<RolePrivilegeInput>>;
};


export type MutationUpdateProductArgs = {
  aliases?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  carrierId?: InputMaybe<Scalars['ID']>;
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  productTypeId?: InputMaybe<Scalars['ID']>;
  receivingAdvanceCommissionCapAmount?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateProductTypeArgs = {
  aliases?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  code: Scalars['String'];
  comments?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isEAndORequired?: InputMaybe<Scalars['Boolean']>;
  licenseIds: Array<InputMaybe<Scalars['ID']>>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['ID']>;
  usePremiumAsTarget?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateProductionWidgetSettingsArgs = {
  count: Scalars['Int'];
  interval: Scalars['String'];
  type: Scalars['String'];
};


export type MutationUpdateProfileCommissionSettingArgs = {
  areGenerationalOverrideRulesApplied: Scalars['Boolean'];
  commissionOnHoldNote?: InputMaybe<Scalars['String']>;
  defaultStatementDeductionPercentage?: InputMaybe<Scalars['Float']>;
  isCommissionOnHold: Scalars['Boolean'];
  isGenerationalOverridesDisqualified: Scalars['Boolean'];
  isVested: Scalars['Boolean'];
  userCompanyRoleId: Scalars['ID'];
  vestedSinceDate?: InputMaybe<Scalars['DateTime']>;
};


export type MutationUpdateProfileEmailPreferencesArgs = {
  isUsingDailyDigestEmail: Scalars['Boolean'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationUpdateProfilePaymentMethodArgs = {
  extendedAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locality?: InputMaybe<Scalars['String']>;
  makeDefault?: InputMaybe<Scalars['Boolean']>;
  paymentMethodToken?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProfileSmsPreferencesArgs = {
  isSubscribedToSMS: Scalars['Boolean'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationUpdateReceiptArgs = {
  policyId: Scalars['ID'];
  uploadedFiles?: InputMaybe<Array<InputMaybe<PolicyDeliveryReceiptFileInput>>>;
};


export type MutationUpdateRecruitingWidgetSettingsArgs = {
  count: Scalars['Int'];
  interval: Scalars['String'];
  isOnlyActiveAgent: Scalars['Boolean'];
};


export type MutationUpdateRoleArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateSignUpTemplateArgs = {
  agentAgreement?: InputMaybe<Scalars['String']>;
  code: Scalars['ID'];
  managerSignUpAcknowledgement?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateTaskArgs = {
  assigneeId?: InputMaybe<Scalars['ID']>;
  belongsToBackOffice?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  currentUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  devTaskId?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  priority?: InputMaybe<Scalars['Int']>;
  relatedId?: InputMaybe<Scalars['ID']>;
  reporterId?: InputMaybe<Scalars['ID']>;
  resolution?: InputMaybe<Scalars['String']>;
  subject: Scalars['String'];
};


export type MutationUpdateTaskCategoryCompanyArgs = {
  defaultAssigneeId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  selectedCarriers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationUpdateTaskCommentArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationUpdateTopRecruitersWidgetSettingsArgs = {
  count: Scalars['Int'];
  interval: Scalars['String'];
};


export type MutationUpdateTourEntryArgs = {
  contentInMarkdown?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  pagePath?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  selector?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateTourVisitArgs = {
  visitedTourEntryIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationUpdateTrainingClassArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateTrainingClassSessionArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  trainingClassId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  isProfileCompleted?: InputMaybe<Scalars['Boolean']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  roleId: Scalars['ID'];
};


export type MutationUpdateUserCompanyRoleArgs = {
  businessAddress1?: InputMaybe<Scalars['String']>;
  businessAddress2?: InputMaybe<Scalars['String']>;
  businessCity?: InputMaybe<Scalars['String']>;
  businessState?: InputMaybe<Scalars['String']>;
  businessZip?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['BigInt']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: Scalars['String'];
  homeAddress1: Scalars['String'];
  homeAddress2?: InputMaybe<Scalars['String']>;
  homeCity: Scalars['String'];
  homeState: Scalars['String'];
  homeZip: Scalars['String'];
  id: Scalars['ID'];
  isBusinessAddressSameAsHome: Scalars['Boolean'];
  isHomeAddressDefaultMailing: Scalars['Boolean'];
  lastName: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  primaryPhone: Scalars['String'];
  secondaryPhone?: InputMaybe<Scalars['String']>;
  sponsorId?: InputMaybe<Scalars['ID']>;
  ssn: Scalars['String'];
};


export type MutationUpdateUserCompanyRoleStatusArgs = {
  confirmText?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  userCompanyRoleId: Scalars['ID'];
};


export type MutationValidateFieldTrainingSaleAppointmentsArgs = {
  policyIds: Array<InputMaybe<Scalars['ID']>>;
  trainerId: Scalars['ID'];
  workflowItemId: Scalars['ID'];
};


export type MutationViewAgentMemoAcknowledgementArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type NewBusiness = {
  __typename?: 'NewBusiness';
  agentCode?: Maybe<Scalars['String']>;
  agentId: Scalars['ID'];
  agentName?: Maybe<Scalars['String']>;
  totalNumber?: Maybe<Scalars['Float']>;
  totalNumberOfPoliciesInLast07Days?: Maybe<Scalars['Float']>;
  totalNumberOfPoliciesInLast14Days?: Maybe<Scalars['Float']>;
  totalNumberOfPoliciesInLast21Days?: Maybe<Scalars['Float']>;
  totalNumberOfPoliciesInLast31Days?: Maybe<Scalars['Float']>;
  totalNumberOfPoliciesInLast45Days?: Maybe<Scalars['Float']>;
  totalNumberOfPoliciesInLast60Days?: Maybe<Scalars['Float']>;
  totalNumberOfPoliciesInLast90Days?: Maybe<Scalars['Float']>;
  totalPoint?: Maybe<Scalars['Float']>;
};

export type NewBusinessFilterInput = {
  agents?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
  totalNumber?: InputMaybe<NumberFilterInput>;
  totalNumber07Days?: InputMaybe<NumberFilterInput>;
  totalNumber14Days?: InputMaybe<NumberFilterInput>;
  totalNumber21Days?: InputMaybe<NumberFilterInput>;
  totalNumber31Days?: InputMaybe<NumberFilterInput>;
  totalNumber45Days?: InputMaybe<NumberFilterInput>;
  totalNumber60Days?: InputMaybe<NumberFilterInput>;
  totalNumber90Days?: InputMaybe<NumberFilterInput>;
  totalPoint?: InputMaybe<NumberFilterInput>;
};

export type Note = {
  __typename?: 'Note';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  creator?: Maybe<UserCompanyRole>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['BigInt']>;
  fileUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isDownloadable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  userCompanyRoleId: Scalars['ID'];
};

export type NumberFilterInput = {
  operatorTypeId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<NumberFilterQueryInput>;
};

export type NumberFilterQueryInput = {
  end?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type OwnershipType = {
  __typename?: 'OwnershipType';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type PaginateAgentFileEntry = {
  __typename?: 'PaginateAgentFileEntry';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<AgentFileEntry>>>;
};

export type PaginatedActivities = {
  __typename?: 'PaginatedActivities';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Activity>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedAgentBankTransactions = {
  __typename?: 'PaginatedAgentBankTransactions';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<AgentBankTransaction>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedAgentBanks = {
  __typename?: 'PaginatedAgentBanks';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<AgentBank>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedAgentLevels = {
  __typename?: 'PaginatedAgentLevels';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<AgentLevel>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCalculationMethods = {
  __typename?: 'PaginatedCalculationMethods';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CalculationMethod>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCarrierAppointments = {
  __typename?: 'PaginatedCarrierAppointments';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CarrierAppointment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCarriers = {
  __typename?: 'PaginatedCarriers';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Carrier>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCommissionAdjustmentCategories = {
  __typename?: 'PaginatedCommissionAdjustmentCategories';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CommissionAdjustmentCategory>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCommissionAdjustmentReview = {
  __typename?: 'PaginatedCommissionAdjustmentReview';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<CommissionAdjustmentReview>>>;
};

export type PaginatedCommissionAdjustmentReviewItem = {
  __typename?: 'PaginatedCommissionAdjustmentReviewItem';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<CommissionAdjustmentReviewItem>>>;
};

export type PaginatedCommissionAdjustments = {
  __typename?: 'PaginatedCommissionAdjustments';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CommissionAdjustment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCommissionAssignments = {
  __typename?: 'PaginatedCommissionAssignments';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CommissionAssignment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCommissionCalculatedEntriesOnHold = {
  __typename?: 'PaginatedCommissionCalculatedEntriesOnHold';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CommissionCalculatedEntriesOnHold>>>;
  total?: Maybe<Scalars['Int']>;
  totalRows?: Maybe<Scalars['JSONObject']>;
};

export type PaginatedCommissionCalculatedEntry = {
  __typename?: 'PaginatedCommissionCalculatedEntry';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CommissionCalculatedEntry>>>;
  summary?: Maybe<CommissionCalculatedEntrySummary>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCommissionCycle = {
  __typename?: 'PaginatedCommissionCycle';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CommissionCycle>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCommissionEntry = {
  __typename?: 'PaginatedCommissionEntry';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CommissionEntry>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCommissionEntryCategories = {
  __typename?: 'PaginatedCommissionEntryCategories';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CommissionEntryCategory>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCommissionRawBatch = {
  __typename?: 'PaginatedCommissionRawBatch';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CommissionRawBatch>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCommissionStatementLogs = {
  __typename?: 'PaginatedCommissionStatementLogs';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CommissionStatementLog>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCommissionStatements = {
  __typename?: 'PaginatedCommissionStatements';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CommissionStatement>>>;
  totalPayout?: Maybe<Scalars['Float']>;
};

export type PaginatedCompanySystemFeature = {
  __typename?: 'PaginatedCompanySystemFeature';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CompanySystemFeature>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCompensationPlans = {
  __typename?: 'PaginatedCompensationPlans';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CompensationPlan>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCompensationTemplates = {
  __typename?: 'PaginatedCompensationTemplates';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CompensationTemplate>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedCustomer = {
  __typename?: 'PaginatedCustomer';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Customer>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedEmailTemplates = {
  __typename?: 'PaginatedEmailTemplates';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<EmailTemplate>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedErrorOmissions = {
  __typename?: 'PaginatedErrorOmissions';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<EAndO>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedEventReservations = {
  __typename?: 'PaginatedEventReservations';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<EventReservation>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedEventSessions = {
  __typename?: 'PaginatedEventSessions';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<EventSession>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedEventTicketSessions = {
  __typename?: 'PaginatedEventTicketSessions';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<EventTicketSession>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedEventTickets = {
  __typename?: 'PaginatedEventTickets';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<EventTicket>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedEvents = {
  __typename?: 'PaginatedEvents';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Event>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedFileCategory = {
  __typename?: 'PaginatedFileCategory';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<FileCategory>>>;
};

export type PaginatedGenerationOverrideRequirementWaiver = {
  __typename?: 'PaginatedGenerationOverrideRequirementWaiver';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<GenerationOverrideRequirementWaiver>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedGenerationOverridesQualification = {
  __typename?: 'PaginatedGenerationOverridesQualification';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<GenerationOverrideQualification>>>;
  sum?: Maybe<GenerationOverrideQualificationSummary>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedGoogleCloudFiles = {
  __typename?: 'PaginatedGoogleCloudFiles';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<GoogleCloudFile>>>;
};

export type PaginatedGroups = {
  __typename?: 'PaginatedGroups';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Group>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedHierarchyHistory = {
  __typename?: 'PaginatedHierarchyHistory';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<HierarchyHistory>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedLicenseInfos = {
  __typename?: 'PaginatedLicenseInfos';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<LicenseInfo>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedLicenses = {
  __typename?: 'PaginatedLicenses';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<License>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedMemos = {
  __typename?: 'PaginatedMemos';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Memo>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedNewBusinesses = {
  __typename?: 'PaginatedNewBusinesses';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<NewBusiness>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedPaymentMode = {
  __typename?: 'PaginatedPaymentMode';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<PaymentMode>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedPolicies = {
  __typename?: 'PaginatedPolicies';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Policy>>>;
  total?: Maybe<Scalars['Int']>;
  totalRows?: Maybe<PolicyTotalRow>;
};

export type PaginatedPolicyCommissionTrackerReport = {
  __typename?: 'PaginatedPolicyCommissionTrackerReport';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<PolicyCommissionTrackerReportRow>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedPolicyStates = {
  __typename?: 'PaginatedPolicyStates';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<PolicyState>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedProductTypes = {
  __typename?: 'PaginatedProductTypes';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<ProductType>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedProductionStats = {
  __typename?: 'PaginatedProductionStats';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<ProductionStat>>>;
  sum?: Maybe<ProductionStatSummary>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedProducts = {
  __typename?: 'PaginatedProducts';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Product>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedRecruitingStats = {
  __typename?: 'PaginatedRecruitingStats';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<RecruitingStat>>>;
  sum?: Maybe<RecruitingStatSummary>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedRoles = {
  __typename?: 'PaginatedRoles';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Role>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedTaskCategoryCompany = {
  __typename?: 'PaginatedTaskCategoryCompany';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<TaskCategoryCompany>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedTasks = {
  __typename?: 'PaginatedTasks';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Task>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedTourEntries = {
  __typename?: 'PaginatedTourEntries';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<TourEntry>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedTrainingClassSessionRegisteredAgent = {
  __typename?: 'PaginatedTrainingClassSessionRegisteredAgent';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<TrainingClassSessionRegisteredAgent>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedTrainingClassSessions = {
  __typename?: 'PaginatedTrainingClassSessions';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<TrainingClassSession>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedTrainingClasses = {
  __typename?: 'PaginatedTrainingClasses';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<TrainingClass>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedUserCompanyRoles = {
  __typename?: 'PaginatedUserCompanyRoles';
  count: Scalars['Int'];
  max?: Maybe<Scalars['Float']>;
  rows?: Maybe<Array<Maybe<UserCompanyRole>>>;
  total?: Maybe<Scalars['Int']>;
  totalAllRowLifeSaleStats?: Maybe<Array<Maybe<LifeSaleStat>>>;
};

export type PaginatedUserGroups = {
  __typename?: 'PaginatedUserGroups';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<UserGroup>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<User>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedWinter2021CashBonusBaseshops = {
  __typename?: 'PaginatedWinter2021CashBonusBaseshops';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Winter2021CashBonusBaseshop>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedWinter2021CashBonusPersonals = {
  __typename?: 'PaginatedWinter2021CashBonusPersonals';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Winter2021CashBonusPersonal>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedWorkflowItems = {
  __typename?: 'PaginatedWorkflowItems';
  count: Scalars['Int'];
  rows?: Maybe<Array<Maybe<WorkflowItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedWorkflows = {
  __typename?: 'PaginatedWorkflows';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Workflow>>>;
};

export type PaymentMode = {
  __typename?: 'PaymentMode';
  aliases?: Maybe<Array<Maybe<PaymentModeAlias>>>;
  code: Scalars['ID'];
  name: Scalars['String'];
  premiumMultiplier?: Maybe<Scalars['Int']>;
};

export type PaymentModeAlias = {
  __typename?: 'PaymentModeAlias';
  alias: Scalars['String'];
  id: Scalars['ID'];
};

export type PayoutStatus = {
  __typename?: 'PayoutStatus';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Policy = {
  __typename?: 'Policy';
  annualizedPremium?: Maybe<Scalars['Float']>;
  carrier?: Maybe<Carrier>;
  carrierId?: Maybe<Scalars['ID']>;
  carrierStatus?: Maybe<Scalars['String']>;
  carrierStatusAt?: Maybe<Scalars['DateTime']>;
  companyId?: Maybe<Scalars['ID']>;
  compensationPlan?: Maybe<CompensationPlan>;
  compensationPlanId?: Maybe<Scalars['ID']>;
  completeRequirementCount?: Maybe<Scalars['Int']>;
  completeRequirementPercentage?: Maybe<Scalars['Float']>;
  confirmingText?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  customerId: Scalars['ID'];
  deliveryReceipts?: Maybe<Array<Maybe<PolicyDeliveryReceiptFile>>>;
  duplicateWithPolicy?: Maybe<Policy>;
  effectiveDate?: Maybe<Scalars['BigInt']>;
  estimatedTargetPremium?: Maybe<Scalars['Float']>;
  exchangeSale?: Maybe<PolicyExchangeSale>;
  exchangeSaleCommissionPercentage?: Maybe<Scalars['Int']>;
  faceAmount?: Maybe<Scalars['Float']>;
  hasDeliveryReceipt?: Maybe<Scalars['Boolean']>;
  hasReceivedCommission?: Maybe<Scalars['Boolean']>;
  histories?: Maybe<Array<Maybe<PolicyHistory>>>;
  id: Scalars['ID'];
  insuredIssueAge?: Maybe<Scalars['Int']>;
  isAdjustmentViewable?: Maybe<Scalars['Boolean']>;
  isApprovable?: Maybe<Scalars['Boolean']>;
  isAutoUpdateFromDataFeed?: Maybe<Scalars['Boolean']>;
  isCarrierDeclinedMarkable?: Maybe<Scalars['Boolean']>;
  isCarrierTerminatedMarkable?: Maybe<Scalars['Boolean']>;
  isClientCanceledMarkable?: Maybe<Scalars['Boolean']>;
  isClientRejectedMarkable?: Maybe<Scalars['Boolean']>;
  isClosable?: Maybe<Scalars['Boolean']>;
  isCommissionAnalyzerViewable?: Maybe<Scalars['Boolean']>;
  isCommissionPayableToServicingAgent?: Maybe<Scalars['Boolean']>;
  isCommissionSettingViewable?: Maybe<Scalars['Boolean']>;
  isCreatedBySystem?: Maybe<Scalars['Boolean']>;
  isCustomerDetailsViewable?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isDeliveredMarkable?: Maybe<Scalars['Boolean']>;
  isDuplicate?: Maybe<Scalars['Boolean']>;
  isDuplicateMarkable?: Maybe<Scalars['Boolean']>;
  isExchangeSale?: Maybe<Scalars['Boolean']>;
  isExchangeSaleAcknowledged?: Maybe<Scalars['Boolean']>;
  isExchangeSaleUpdatable?: Maybe<Scalars['Boolean']>;
  isFieldTrainingSaleUpdatable?: Maybe<Scalars['Boolean']>;
  isHierarchyViewable?: Maybe<Scalars['Boolean']>;
  isNewCustomer?: Maybe<Scalars['Boolean']>;
  isNewOwner?: Maybe<Scalars['Boolean']>;
  isPaidMarkable?: Maybe<Scalars['Boolean']>;
  isPayoutCommissionViewable?: Maybe<Scalars['Boolean']>;
  isPolicyAutoUpdateEditable?: Maybe<Scalars['Boolean']>;
  isPolicyHistoryViewable?: Maybe<Scalars['Boolean']>;
  isPolicyNotesManager?: Maybe<Scalars['Boolean']>;
  isPolicyNotesViewable?: Maybe<Scalars['Boolean']>;
  isReceiptDeletable?: Maybe<Scalars['Boolean']>;
  isReceiptDownloadable?: Maybe<Scalars['Boolean']>;
  isReceiptUpdatable?: Maybe<Scalars['Boolean']>;
  isReceivingCommissionViewable?: Maybe<Scalars['Boolean']>;
  isRejectable?: Maybe<Scalars['Boolean']>;
  isReturnToThePreviousStatusAllowed?: Maybe<Scalars['Boolean']>;
  isSplitSale?: Maybe<Scalars['Boolean']>;
  isSubmittable?: Maybe<Scalars['Boolean']>;
  isTargetPremiumUpdatable?: Maybe<Scalars['Boolean']>;
  isTheAnnualizedPremiumLocked?: Maybe<Scalars['Boolean']>;
  isTheOwnerTheInsured?: Maybe<Scalars['Boolean']>;
  isTheSplitSalesLocked?: Maybe<Scalars['Boolean']>;
  isTheTargetPremiumLocked?: Maybe<Scalars['Boolean']>;
  isTheWritingAgentLocked?: Maybe<Scalars['Boolean']>;
  isTrainingSale?: Maybe<Scalars['Boolean']>;
  isUnduplicatedMarkable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  isViewable?: Maybe<Scalars['Boolean']>;
  isWritingAgentUpdatable?: Maybe<Scalars['Boolean']>;
  issuedDate?: Maybe<Scalars['Date']>;
  methodOfSubmission?: Maybe<Scalars['ID']>;
  outstandingRequirementCount?: Maybe<Scalars['Int']>;
  overriddenCompensationPlan?: Maybe<CompensationPlan>;
  overriddenCompensationPlanId?: Maybe<Scalars['ID']>;
  owner?: Maybe<Customer>;
  ownerId?: Maybe<Scalars['ID']>;
  paymentMode?: Maybe<Scalars['ID']>;
  paymentModeItem?: Maybe<PaymentMode>;
  pointValue?: Maybe<Scalars['Float']>;
  policyNumber?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['ID']>;
  productType?: Maybe<ProductType>;
  productTypeId?: Maybe<Scalars['ID']>;
  receiptReviewedDate?: Maybe<Scalars['DateTime']>;
  receiptStatus?: Maybe<Scalars['String']>;
  receiptSubmittedDate?: Maybe<Scalars['DateTime']>;
  receiptUpdatedDate?: Maybe<Scalars['DateTime']>;
  requirements?: Maybe<Array<Maybe<PolicyRequirement>>>;
  servicingAgent?: Maybe<UserCompanyRole>;
  servicingAgentId?: Maybe<Scalars['ID']>;
  splitPercentage?: Maybe<Scalars['Float']>;
  splitSales?: Maybe<Array<Maybe<PolicySplitSale>>>;
  stateName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['ID']>;
  targetPremium?: Maybe<Scalars['Float']>;
  totalRequirementCount?: Maybe<Scalars['Int']>;
  trainingSales?: Maybe<Array<Maybe<PolicyTrainingSale>>>;
  userAgent?: Maybe<UserCompanyRole>;
  userAgentId?: Maybe<Scalars['ID']>;
  writtenDate?: Maybe<Scalars['Date']>;
  writtenState?: Maybe<Scalars['String']>;
};

export type PolicyCommissionTrackerReportRow = {
  __typename?: 'PolicyCommissionTrackerReportRow';
  carrier?: Maybe<Carrier>;
  customer?: Maybe<Customer>;
  excessRenewal?: Maybe<CommissionPayoutByType>;
  firstYear?: Maybe<CommissionPayoutByType>;
  id: Scalars['ID'];
  margin?: Maybe<CommissionPayoutMargin>;
  policyNumber?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productType?: Maybe<ProductType>;
  service?: Maybe<CommissionPayoutByType>;
  targetPremium?: Maybe<Scalars['Float']>;
};

export type PolicyDeliveryReceiptFile = {
  __typename?: 'PolicyDeliveryReceiptFile';
  receiptFileName?: Maybe<Scalars['String']>;
  receiptFileSize?: Maybe<Scalars['BigInt']>;
  receiptFileUrl?: Maybe<Scalars['String']>;
};

export type PolicyDeliveryReceiptFileInput = {
  name: Scalars['String'];
  size: Scalars['BigInt'];
  tmpFileUrl: Scalars['String'];
};

export type PolicyExchangeSale = {
  __typename?: 'PolicyExchangeSale';
  policyId: Scalars['ID'];
  userAgent?: Maybe<UserCompanyRole>;
  userAgentId: Scalars['ID'];
};

export type PolicyFilterInput = {
  agentTypes?: InputMaybe<FilterIdsInput>;
  agents?: InputMaybe<FilterIdsInput>;
  annualizedPremium?: InputMaybe<NumberFilterInput>;
  baseshopMembersOf?: InputMaybe<FilterIdsInput>;
  carriers?: InputMaybe<FilterIdsInput>;
  delivered?: InputMaybe<FilterBooleanInput>;
  deliveryReceiptStates?: InputMaybe<FilterIdsInput>;
  downlinesOf?: InputMaybe<FilterIdsInput>;
  estimatedTargetPremium?: InputMaybe<NumberFilterInput>;
  hasOutstandingRequirements?: InputMaybe<FilterBooleanInput>;
  hasReceivedCommission?: InputMaybe<FilterBooleanInput>;
  isAutoUpdate?: InputMaybe<FilterBooleanInput>;
  isDuplicate?: InputMaybe<FilterBooleanInput>;
  isExchangeSale?: InputMaybe<FilterBooleanInput>;
  isFieldTrainingSale?: InputMaybe<FilterBooleanInput>;
  issuedDate?: InputMaybe<DateFilterQueryInput>;
  myExchangeSales?: InputMaybe<FilterBooleanInput>;
  myPolicies?: InputMaybe<FilterBooleanInput>;
  policyStates?: InputMaybe<FilterIdsInput>;
  premium?: InputMaybe<NumberFilterInput>;
  productTypes?: InputMaybe<FilterIdsInput>;
  products?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  receiptReviewedDate?: InputMaybe<DateTimeFilterQueryInput>;
  receiptSubmittedDate?: InputMaybe<DateTimeFilterQueryInput>;
  successStatus?: InputMaybe<FilterBooleanInput>;
  text?: InputMaybe<FilterQueryInput>;
  writtenDate?: InputMaybe<DateFilterQueryInput>;
  writtenStates?: InputMaybe<FilterIdsInput>;
};

export type PolicyHierarchy = {
  __typename?: 'PolicyHierarchy';
  depth?: Maybe<Scalars['Int']>;
  hierarchyAgent?: Maybe<UserCompanyRole>;
  hierarchyAgentId: Scalars['ID'];
  hierarchyAgentLevel?: Maybe<AgentLevel>;
  hierarchyAgentLevelId: Scalars['ID'];
  id: Scalars['ID'];
  policyId: Scalars['ID'];
  userAgentId: Scalars['ID'];
  userAgentType: Scalars['String'];
};

export type PolicyHistory = {
  __typename?: 'PolicyHistory';
  after?: Maybe<Scalars['JSONObject']>;
  before?: Maybe<Scalars['JSONObject']>;
  carrierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCompanyRole>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fromStatus?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  policyId: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
};

export type PolicyInput = {
  annualizedPremium?: InputMaybe<Scalars['Float']>;
  carrierId: Scalars['ID'];
  confirmingText?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['ID']>;
  estimatedTargetPremium?: InputMaybe<Scalars['Float']>;
  faceAmount?: InputMaybe<Scalars['Float']>;
  insuredIssueAge?: InputMaybe<Scalars['Int']>;
  issuedDate?: InputMaybe<Scalars['Date']>;
  methodOfSubmission: Scalars['ID'];
  paymentMode: Scalars['ID'];
  pointValue?: InputMaybe<Scalars['Float']>;
  policyNumber: Scalars['String'];
  premium?: InputMaybe<Scalars['Float']>;
  productId: Scalars['ID'];
  productTypeId: Scalars['ID'];
  servicingAgentId?: InputMaybe<Scalars['ID']>;
  targetPremium?: InputMaybe<Scalars['Float']>;
  userAgentId: Scalars['ID'];
  writtenDate: Scalars['Date'];
  writtenState: Scalars['String'];
};

export type PolicyNote = {
  __typename?: 'PolicyNote';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  creator?: Maybe<UserCompanyRole>;
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  policyId: Scalars['ID'];
};

export type PolicyPayoutCommissionEntry = {
  __typename?: 'PolicyPayoutCommissionEntry';
  adjustedPayout?: Maybe<Scalars['Float']>;
  adjustmentCategoryCode?: Maybe<Scalars['String']>;
  adjustmentReason?: Maybe<Scalars['String']>;
  commissionAdjustmentId?: Maybe<Scalars['ID']>;
  commissionCycleId?: Maybe<Scalars['ID']>;
  commissionEntryCategoryCode?: Maybe<Scalars['String']>;
  commissionEntryId?: Maybe<Scalars['ID']>;
  commissionStatementId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  payout?: Maybe<Scalars['Float']>;
  recipientAgent?: Maybe<UserCompanyRole>;
  recipientAgentCode?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  transactionNumber?: Maybe<Scalars['String']>;
};

export type PolicyReceivedCommissionEntry = {
  __typename?: 'PolicyReceivedCommissionEntry';
  commission?: Maybe<Scalars['Float']>;
  commissionEntryCategoryCode?: Maybe<Scalars['String']>;
  commissionRate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  producingAgent?: Maybe<UserCompanyRole>;
  producingAgentCode?: Maybe<Scalars['String']>;
  producingAgentName?: Maybe<Scalars['String']>;
  splitPercentage?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
};

export type PolicyRequirement = {
  __typename?: 'PolicyRequirement';
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  receivedDate?: Maybe<Scalars['DateTime']>;
  requestedDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  uniqueId?: Maybe<Scalars['String']>;
};

export type PolicySplitSale = {
  __typename?: 'PolicySplitSale';
  id?: Maybe<Scalars['ID']>;
  percentage?: Maybe<Scalars['Float']>;
  policyId: Scalars['ID'];
  userAgent?: Maybe<UserCompanyRole>;
  userAgentId: Scalars['ID'];
};

export type PolicySplitSaleInput = {
  percentage?: InputMaybe<Scalars['Float']>;
  userAgentId: Scalars['ID'];
};

export type PolicyStatBySuccessStatus = {
  __typename?: 'PolicyStatBySuccessStatus';
  amount?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type PolicyState = {
  __typename?: 'PolicyState';
  backgroundColor?: Maybe<Scalars['String']>;
  code: Scalars['ID'];
  color?: Maybe<Scalars['String']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  policyStateCompany?: Maybe<PolicyStateCompany>;
  priority?: Maybe<Scalars['Int']>;
};

export type PolicyStateCompany = {
  __typename?: 'PolicyStateCompany';
  backgroundColor?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  policyStateCode: Scalars['ID'];
  priority?: Maybe<Scalars['Int']>;
};

export type PolicyTotalRow = {
  __typename?: 'PolicyTotalRow';
  annualizedPremium?: Maybe<Scalars['Float']>;
  faceAmount?: Maybe<Scalars['Float']>;
  hasReceivedCommission?: Maybe<Scalars['Int']>;
  insuredIssueAge?: Maybe<Scalars['Float']>;
  pointValue?: Maybe<Scalars['Float']>;
  policyNumber?: Maybe<Scalars['Int']>;
  premium?: Maybe<Scalars['Float']>;
  targetPremium?: Maybe<Scalars['Float']>;
};

export type PolicyTrainingSale = {
  __typename?: 'PolicyTrainingSale';
  isTrainer?: Maybe<Scalars['Boolean']>;
  policyId: Scalars['ID'];
  trainingSaleAppointment?: Maybe<TrainingSaleAppointment>;
  userAgent?: Maybe<UserCompanyRole>;
  userAgentId: Scalars['ID'];
};

export type PolicyTrainingSaleInput = {
  isTrainer?: InputMaybe<Scalars['Boolean']>;
  userAgentId: Scalars['ID'];
};

export type Privilege = {
  __typename?: 'Privilege';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  aliases?: Maybe<Array<Maybe<ProductAlias>>>;
  carrier?: Maybe<Carrier>;
  carrierId: Scalars['ID'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  productType?: Maybe<ProductType>;
  productTypeId: Scalars['ID'];
  receivingAdvanceCommissionCapAmount?: Maybe<Scalars['Float']>;
};

export type ProductAlias = {
  __typename?: 'ProductAlias';
  alias: Scalars['String'];
  id: Scalars['ID'];
};

export type ProductFilterInput = {
  carriers?: InputMaybe<FilterIdsInput>;
  productTypes?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type ProductType = {
  __typename?: 'ProductType';
  aliases?: Maybe<Array<Maybe<ProductAlias>>>;
  childrenIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  code?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAnnuity?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isEAndORequired?: Maybe<Scalars['Boolean']>;
  isRootProductType?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  licenseCodes: Array<Maybe<Scalars['ID']>>;
  licenses?: Maybe<Array<Maybe<License>>>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  rootProductTypeId?: Maybe<Scalars['ID']>;
  usePremiumAsTarget?: Maybe<Scalars['Boolean']>;
};

export type ProductionByStatus = {
  __typename?: 'ProductionByStatus';
  code?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type ProductionByTime = {
  __typename?: 'ProductionByTime';
  date?: Maybe<Scalars['DateTime']>;
  x?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['Float']>;
};

export type ProductionStat = {
  __typename?: 'ProductionStat';
  agent: UserCompanyRole;
  agentId: Scalars['ID'];
  perCategory?: Maybe<Array<Maybe<ProductionStatPerCategory>>>;
  perStatus?: Maybe<Array<Maybe<ProductionStatPerStatus>>>;
  totalPolicyCount?: Maybe<Scalars['Float']>;
  totalPolicyPointValues?: Maybe<Scalars['Float']>;
};

export type ProductionStatFilterInput = {
  agents?: InputMaybe<FilterIdsInput>;
  annualizedPremium?: InputMaybe<NumberFilterInput>;
  baseshopMembersOf?: InputMaybe<FilterIdsInput>;
  carriers?: InputMaybe<FilterIdsInput>;
  delivered?: InputMaybe<FilterBooleanInput>;
  deliveryReceiptStates?: InputMaybe<FilterIdsInput>;
  downlinesOf?: InputMaybe<FilterIdsInput>;
  estimatedTargetPremium?: InputMaybe<NumberFilterInput>;
  hasOutstandingRequirements?: InputMaybe<FilterBooleanInput>;
  hasReceivedCommission?: InputMaybe<FilterBooleanInput>;
  isDuplicate?: InputMaybe<FilterBooleanInput>;
  issuedDate?: InputMaybe<DateFilterQueryInput>;
  myPolicies?: InputMaybe<FilterBooleanInput>;
  policyStates?: InputMaybe<FilterIdsInput>;
  premium?: InputMaybe<NumberFilterInput>;
  productTypes?: InputMaybe<FilterIdsInput>;
  products?: InputMaybe<FilterIdsInput>;
  receiptReviewedDate?: InputMaybe<DateTimeFilterQueryInput>;
  receiptSubmittedDate?: InputMaybe<DateTimeFilterQueryInput>;
  writtenDate?: InputMaybe<DateFilterQueryInput>;
  writtenStates?: InputMaybe<FilterIdsInput>;
};

export type ProductionStatPerCategory = {
  __typename?: 'ProductionStatPerCategory';
  category: Scalars['ID'];
  totalPolicyCount?: Maybe<Scalars['Float']>;
  totalPolicyPointValues?: Maybe<Scalars['Float']>;
};

export type ProductionStatPerStatus = {
  __typename?: 'ProductionStatPerStatus';
  status: Scalars['ID'];
  totalPolicyCount?: Maybe<Scalars['Float']>;
  totalPolicyPointValues?: Maybe<Scalars['Float']>;
};

export type ProductionStatSummary = {
  __typename?: 'ProductionStatSummary';
  byCategory?: Maybe<Array<Maybe<ProductionStatPerCategory>>>;
  byStatus?: Maybe<Array<Maybe<ProductionStatPerStatus>>>;
  totalPolicyCount?: Maybe<Scalars['Float']>;
  totalPolicyPointValues?: Maybe<Scalars['Float']>;
};

export type ProductionSuccessRate = {
  __typename?: 'ProductionSuccessRate';
  displayDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  potentialSuccessRate?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  successRate?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  value?: Maybe<Array<Maybe<PolicyStatBySuccessStatus>>>;
};

export type ProfileDocumentFilterInput = {
  documentCategory?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type ProfileHierarchy = {
  __typename?: 'ProfileHierarchy';
  entries?: Maybe<Array<Maybe<UserCompanyRole>>>;
  rootAgent?: Maybe<UserCompanyRole>;
};

export type Provider = {
  __typename?: 'Provider';
  id: Scalars['ID'];
  largeLogoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shortName: Scalars['String'];
  smallLogoUrl?: Maybe<Scalars['String']>;
};

export type ProxiedLink = {
  __typename?: 'ProxiedLink';
  id?: Maybe<Scalars['ID']>;
  targetUrl: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  config?: Maybe<Config>;
  executeRawSqlCommissionEntries?: Maybe<ExecuteRawSqlCommissionEntries>;
  generateCommissionStatementForDownloading?: Maybe<Scalars['String']>;
  getAIGGoogleCloudFiles?: Maybe<PaginatedGoogleCloudFiles>;
  getAPCLWorkflowItems?: Maybe<PaginatedWorkflowItems>;
  getAccountTypes?: Maybe<Array<Maybe<AccountType>>>;
  getActivities?: Maybe<PaginatedActivities>;
  getActivity?: Maybe<Activity>;
  getActivityCategories?: Maybe<Array<Maybe<ActivityCategory>>>;
  getAgentAudit?: Maybe<PaginatedUserCompanyRoles>;
  getAgentBank?: Maybe<AgentBank>;
  getAgentBankTransaction?: Maybe<AgentBankTransaction>;
  getAgentBankTransactionTypes?: Maybe<Array<Maybe<AgentBankTransactionType>>>;
  getAgentBankTransactions?: Maybe<PaginatedAgentBankTransactions>;
  getAgentBanks?: Maybe<PaginatedAgentBanks>;
  getAgentExtraInfo?: Maybe<AgentExtraInfo>;
  getAgentFileCategories?: Maybe<Array<Maybe<FileCategory>>>;
  getAgentFileCategoriesForList?: Maybe<PaginatedFileCategory>;
  getAgentFileCategory?: Maybe<FileCategory>;
  getAgentFileEntries?: Maybe<PaginateAgentFileEntry>;
  getAgentLevel?: Maybe<AgentLevel>;
  getAgentLevels?: Maybe<PaginatedAgentLevels>;
  getAgentLevelsForNewAgent?: Maybe<Array<Maybe<AgentLevel>>>;
  getAgentLevelsForNewHistory?: Maybe<Array<Maybe<AgentLevel>>>;
  getAgentLicensingStates?: Maybe<Array<Maybe<AgentLicensingState>>>;
  getAgentMemo?: Maybe<Memo>;
  getAgentMemoAcknowledgements?: Maybe<PaginatedMemos>;
  getAgentMemos?: Maybe<PaginatedMemos>;
  getAgents?: Maybe<PaginatedUserCompanyRoles>;
  getAgentsByIds?: Maybe<Array<Maybe<UserCompanyRole>>>;
  getAgentsForDirectory?: Maybe<PaginatedUserCompanyRoles>;
  getAgentsForPipeline?: Maybe<PaginatedUserCompanyRoles>;
  getAllRoles?: Maybe<PaginatedRoles>;
  getAllTourEntries?: Maybe<PaginatedTourEntries>;
  getAnalyzedPolicyPayoutCommissionEntries?: Maybe<Array<Maybe<PolicyPayoutCommissionEntry>>>;
  getAnalyzedPolicyReceivedCommissionEntries?: Maybe<Array<Maybe<PolicyReceivedCommissionEntry>>>;
  getApiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  getApplicant?: Maybe<UserCompanyRole>;
  getApplicants?: Maybe<PaginatedUserCompanyRoles>;
  getApplicantsContract?: Maybe<Scalars['String']>;
  getAssociatedUsers?: Maybe<PaginatedUserCompanyRoles>;
  getAutomationCustomToken?: Maybe<Scalars['String']>;
  getAvailableTrainingClasses?: Maybe<PaginatedTrainingClasses>;
  getBrandingCompanyExtraInfo?: Maybe<CompanyExtraInfo>;
  getCPAReport?: Maybe<PaginatedCommissionEntry>;
  getCalculationMethod?: Maybe<CalculationMethod>;
  getCalculationMethods?: Maybe<PaginatedCalculationMethods>;
  getCalendarEvents?: Maybe<Array<Maybe<CalendarEvent>>>;
  getCarrier?: Maybe<Carrier>;
  getCarrierAppointment?: Maybe<CarrierAppointment>;
  getCarrierAppointments?: Maybe<PaginatedCarrierAppointments>;
  getCarrierReceivingCommissionReportTypes?: Maybe<Array<Maybe<CarrierReceivingCommissionReportType>>>;
  getCarriers?: Maybe<PaginatedCarriers>;
  getCarriersForSelection?: Maybe<Array<Maybe<Carrier>>>;
  getCarriersForUploadCommissionRawBatch?: Maybe<Array<Maybe<Carrier>>>;
  getChildren?: Maybe<Array<Maybe<UserCompanyRole>>>;
  getClickUpAttachmentUrl?: Maybe<Scalars['String']>;
  getCommissionAdjustment?: Maybe<CommissionAdjustment>;
  getCommissionAdjustmentCategories?: Maybe<PaginatedCommissionAdjustmentCategories>;
  getCommissionAdjustmentCategory?: Maybe<CommissionAdjustmentCategory>;
  getCommissionAdjustmentReview?: Maybe<CommissionAdjustmentReview>;
  getCommissionAdjustmentReviewItem?: Maybe<CommissionAdjustmentReviewItem>;
  getCommissionAdjustmentReviewItems?: Maybe<PaginatedCommissionAdjustmentReviewItem>;
  getCommissionAdjustmentReviews?: Maybe<PaginatedCommissionAdjustmentReview>;
  getCommissionAdjustments?: Maybe<PaginatedCommissionAdjustments>;
  getCommissionAdjustmentsByStatement?: Maybe<PaginatedCommissionAdjustments>;
  getCommissionAssignment?: Maybe<CommissionAssignment>;
  getCommissionAssignmentExtraInfo?: Maybe<CommissionAssignmentExtraInfo>;
  getCommissionAssignmentStates?: Maybe<Array<Maybe<CommissionAssignmentStates>>>;
  getCommissionCalculatedEntries?: Maybe<PaginatedCommissionCalculatedEntry>;
  getCommissionCalculatedEntriesForAgent?: Maybe<PaginatedCommissionCalculatedEntry>;
  getCommissionCalculatedEntriesOnHold?: Maybe<PaginatedCommissionCalculatedEntriesOnHold>;
  getCommissionCalculatedEntry?: Maybe<CommissionCalculatedEntry>;
  getCommissionCalculatedEntryForAgent?: Maybe<CommissionCalculatedEntry>;
  getCommissionCycle?: Maybe<CommissionCycle>;
  getCommissionCycles?: Maybe<PaginatedCommissionCycle>;
  getCommissionCyclesByIds?: Maybe<Array<Maybe<CommissionCycle>>>;
  getCommissionEligibilityOverrides?: Maybe<Array<Maybe<CommissionEligibilityCheckSuppression>>>;
  getCommissionEntries?: Maybe<PaginatedCommissionEntry>;
  getCommissionEntriesByIds?: Maybe<Array<Maybe<CommissionEntry>>>;
  getCommissionEntriesOfBatch?: Maybe<PaginatedCommissionEntry>;
  getCommissionEntry?: Maybe<CommissionEntry>;
  getCommissionEntryCategories?: Maybe<Array<Maybe<CommissionEntryCategory>>>;
  getCommissionEntryCategoriesForList?: Maybe<PaginatedCommissionEntryCategories>;
  getCommissionEntryCategory?: Maybe<CommissionEntryCategory>;
  getCommissionOverviewStats?: Maybe<CommissionOverviewStats>;
  getCommissionProcessStates?: Maybe<Array<Maybe<CommissionProcessState>>>;
  getCommissionRawBatch?: Maybe<CommissionRawBatch>;
  getCommissionRawBatchEntriesPerCategory?: Maybe<Array<Maybe<CommissionRawBatchEntriesPerCategory>>>;
  getCommissionRawBatchEntriesPerStatus?: Maybe<Array<Maybe<CommissionRawBatchEntriesPerStatus>>>;
  getCommissionRawBatchTopProducingAgents?: Maybe<Array<Maybe<CommissionRawBatchTopProducingAgent>>>;
  getCommissionRawBatches?: Maybe<PaginatedCommissionRawBatch>;
  getCommissionStatement?: Maybe<CommissionStatement>;
  getCommissionStatementDownloadUrl?: Maybe<Scalars['String']>;
  getCommissionStatementLogs?: Maybe<PaginatedCommissionStatementLogs>;
  getCommissionStatementStates?: Maybe<Array<Maybe<CommissionStatementState>>>;
  getCommissionStatementWidgetForAgent?: Maybe<Array<Maybe<CommissionStatementWidgetForAgent>>>;
  getCommissionStatements?: Maybe<PaginatedCommissionStatements>;
  getCommissionStatementsByIds?: Maybe<Array<Maybe<CommissionStatement>>>;
  getCommissionStatementsForAgent?: Maybe<PaginatedCommissionStatements>;
  getCompany?: Maybe<Company>;
  getCompanyApprovedLicensingStates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  getCompanyByCode?: Maybe<Company>;
  getCompanyClickUpConfig?: Maybe<CompanyClickUpConfig>;
  getCompanyClickUpUsers?: Maybe<Array<Maybe<CompanyClickUpUser>>>;
  getCompanyExtraInfo?: Maybe<CompanyExtraInfo>;
  getCompanyPayrollProvider?: Maybe<CompanyPayrollProvider>;
  getCompanySystemFeatures?: Maybe<PaginatedCompanySystemFeature>;
  getCompensationPlan?: Maybe<CompensationPlan>;
  getCompensationPlans?: Maybe<PaginatedCompensationPlans>;
  getCompensationTemplate?: Maybe<CompensationTemplate>;
  getCompensationTemplates?: Maybe<PaginatedCompensationTemplates>;
  getCustomerById?: Maybe<Customer>;
  getCustomerByInsuredName?: Maybe<Customer>;
  getCustomers?: Maybe<PaginatedCustomer>;
  getDashboardSettings?: Maybe<Array<Maybe<WidgetUser>>>;
  getDefaultReport?: Maybe<Report>;
  getDeliveryReceiptStates?: Maybe<Array<Maybe<DeliveryReceiptState>>>;
  getDescendants?: Maybe<Array<Maybe<UserCompanyRole>>>;
  getDownlines?: Maybe<Array<Maybe<UserCompanyRole>>>;
  getDuplicateCommissionEntriesOfBatch?: Maybe<PaginatedCommissionEntry>;
  getEAndO?: Maybe<EAndO>;
  getEAndOs?: Maybe<PaginatedErrorOmissions>;
  getEmailTemplate?: Maybe<EmailTemplate>;
  getEmailTemplates?: Maybe<PaginatedEmailTemplates>;
  getEvent?: Maybe<Event>;
  getEventReservation?: Maybe<EventReservation>;
  getEventReservationStateStats?: Maybe<Array<Maybe<EventReservationStateStat>>>;
  getEventReservations?: Maybe<PaginatedEventReservations>;
  getEventReservationsByEvent?: Maybe<Array<Maybe<EventReservation>>>;
  getEventReservationsByIds?: Maybe<PaginatedEventReservations>;
  getEventSession?: Maybe<EventSession>;
  getEventSessions?: Maybe<PaginatedEventSessions>;
  getEventTicket?: Maybe<EventTicket>;
  getEventTicketAssignmentStats?: Maybe<Array<Maybe<EventTicketAssignmentStat>>>;
  getEventTicketSessions?: Maybe<PaginatedEventTicketSessions>;
  getEventTicketStateStats?: Maybe<Array<Maybe<EventTicketStateStat>>>;
  getEventTickets?: Maybe<PaginatedEventTickets>;
  getEvents?: Maybe<PaginatedEvents>;
  getExcludedCommissionEntriesOfBatch?: Maybe<PaginatedCommissionEntry>;
  getFederalTaxClassifications?: Maybe<Array<Maybe<FederalTaxClassification>>>;
  getFieldTrainingSaleAppointments?: Maybe<Array<Maybe<Policy>>>;
  getFileEntriesByNode?: Maybe<FileEntries>;
  getFilesByPlan?: Maybe<CompensationFileEntries>;
  getGenerationOverrideRequirementWaiver?: Maybe<GenerationOverrideRequirementWaiver>;
  getGenerationOverrideRequirementWaivers?: Maybe<PaginatedGenerationOverrideRequirementWaiver>;
  getGenerationOverridesQualification?: Maybe<PaginatedGenerationOverridesQualification>;
  getGroup?: Maybe<Group>;
  getGroups?: Maybe<PaginatedGroups>;
  getHelpFolders?: Maybe<Array<Maybe<HelpFolder>>>;
  getHierarchyHistories?: Maybe<PaginatedHierarchyHistory>;
  getHierarchyHistory?: Maybe<HierarchyHistory>;
  getInvitation?: Maybe<Invitation>;
  getInvitations?: Maybe<Invitations>;
  getLevelHistories?: Maybe<Array<Maybe<LevelHistory>>>;
  getLevelHistory?: Maybe<LevelHistory>;
  getLicense?: Maybe<License>;
  getLicenseInfo?: Maybe<LicenseInfo>;
  getLicenseInfos?: Maybe<PaginatedLicenseInfos>;
  getLicenses?: Maybe<PaginatedLicenses>;
  getLicensesForSelection?: Maybe<Array<Maybe<License>>>;
  getLinkById?: Maybe<Link>;
  getLinks?: Maybe<Links>;
  getManageCarrierAppointments?: Maybe<PaginatedCarrierAppointments>;
  getManageCommissionAssignments?: Maybe<PaginatedCommissionAssignments>;
  getManageErrorOmissions?: Maybe<PaginatedErrorOmissions>;
  getManageLicenseInfos?: Maybe<PaginatedLicenseInfos>;
  getMatchingTrainingSaleAppointments?: Maybe<TrainingSaleAppointments>;
  getMembershipAgreement?: Maybe<Scalars['String']>;
  getMethodOfSubmissions?: Maybe<Array<Maybe<MethodOfSubmission>>>;
  getNewBusinesses?: Maybe<PaginatedNewBusinesses>;
  getNotVisitedTourEntries?: Maybe<Array<Maybe<TourEntry>>>;
  getNote?: Maybe<Note>;
  getNotes?: Maybe<Array<Maybe<Note>>>;
  getOwnershipTypes?: Maybe<Array<Maybe<OwnershipType>>>;
  getPagePathTourEntries?: Maybe<Array<Maybe<TourEntry>>>;
  getPaymentMode?: Maybe<PaymentMode>;
  getPaymentModes?: Maybe<Array<Maybe<PaymentMode>>>;
  getPaymentModesForList?: Maybe<PaginatedPaymentMode>;
  getPolicies?: Maybe<PaginatedPolicies>;
  getPoliciesByIds?: Maybe<Array<Maybe<Policy>>>;
  getPolicy?: Maybe<Policy>;
  getPolicyCustomerDetails?: Maybe<Policy>;
  getPolicyExchangeSalesByPolicyId?: Maybe<Array<Maybe<PolicyExchangeSale>>>;
  getPolicyForRegister?: Maybe<Policy>;
  getPolicyHierarchy?: Maybe<Array<Maybe<PolicyHierarchy>>>;
  getPolicyNotes?: Maybe<Array<Maybe<PolicyNote>>>;
  getPolicySplitSalesByPolicyId?: Maybe<Array<Maybe<PolicySplitSale>>>;
  getPolicyState?: Maybe<PolicyState>;
  getPolicyStates?: Maybe<Array<Maybe<PolicyState>>>;
  getPolicyStatesForList?: Maybe<PaginatedPolicyStates>;
  getPolicyTrackingReport?: Maybe<PaginatedPolicyCommissionTrackerReport>;
  getPolicyTrainingSalesByPolicyId?: Maybe<Array<Maybe<PolicyTrainingSale>>>;
  getPreviousCommissionCycle?: Maybe<CommissionCycle>;
  getPrivileges?: Maybe<Array<Maybe<Privilege>>>;
  getProduct?: Maybe<Product>;
  getProductType?: Maybe<ProductType>;
  getProductTypes?: Maybe<PaginatedProductTypes>;
  getProductionByStatus?: Maybe<Array<Maybe<ProductionByStatus>>>;
  getProductionByTime?: Maybe<Array<Maybe<ProductionByTime>>>;
  getProductionStats?: Maybe<PaginatedProductionStats>;
  getProductionSuccessfulRateStats?: Maybe<Array<Maybe<ProductionSuccessRate>>>;
  getProducts?: Maybe<PaginatedProducts>;
  getProductsByIds?: Maybe<Array<Maybe<Product>>>;
  getProfileHierarchy?: Maybe<ProfileHierarchy>;
  getProfilePaymentMethod?: Maybe<BraintreeBillingAddress>;
  getProfilePaymentMethods?: Maybe<Array<Maybe<BraintreePaymentMethod>>>;
  getProxiedLink?: Maybe<ProxiedLink>;
  getQueries?: Maybe<Array<Maybe<RawSqlQuery>>>;
  getRawBatchesByIds?: Maybe<Array<Maybe<CommissionRawBatch>>>;
  getReceivingAccounts?: Maybe<Array<Maybe<ReceivingAccount>>>;
  getRecruitingStats?: Maybe<PaginatedRecruitingStats>;
  getRegisteredAgents?: Maybe<PaginatedTrainingClassSessionRegisteredAgent>;
  getRelationships?: Maybe<Array<Maybe<State>>>;
  getResidenceTypes?: Maybe<Array<Maybe<ResidenceType>>>;
  getRole?: Maybe<Role>;
  getRootAgentHierarchy?: Maybe<UserCompanyRole>;
  getSession?: Maybe<Session>;
  getSponsorFromInvitationToken?: Maybe<UserCompanyRole>;
  getStartableWorkflows?: Maybe<Array<Maybe<Workflow>>>;
  getStates?: Maybe<Array<Maybe<State>>>;
  getStaticReportGroups?: Maybe<Array<Maybe<StaticReportGroup>>>;
  getStatuses?: Maybe<Array<Maybe<Status>>>;
  getSubmissionRequirementWarnings?: Maybe<Array<Maybe<Scalars['String']>>>;
  getSubmissionRequirementWarningsTaskCreated?: Maybe<Scalars['String']>;
  getTask?: Maybe<Task>;
  getTaskCategories?: Maybe<Array<Maybe<TaskCategory>>>;
  getTaskCategoryCompanies?: Maybe<PaginatedTaskCategoryCompany>;
  getTaskCategoryCompany?: Maybe<TaskCategoryCompany>;
  getTaskComments?: Maybe<Array<Maybe<TaskComment>>>;
  getTaskStates?: Maybe<Array<Maybe<TaskState>>>;
  getTasks?: Maybe<PaginatedTasks>;
  getTasksByProfile?: Maybe<PaginatedTasks>;
  getTaxpayerTypes?: Maybe<Array<Maybe<TaxpayerType>>>;
  getTourEntries?: Maybe<Array<Maybe<TourEntry>>>;
  getTourEntry?: Maybe<TourEntry>;
  getTrainingClassById?: Maybe<TrainingClass>;
  getTrainingClassRegistrationWorkflowItems?: Maybe<PaginatedWorkflowItems>;
  getTrainingClassSessionById?: Maybe<TrainingClassSession>;
  getTrainingClassSessions?: Maybe<PaginatedTrainingClassSessions>;
  getTrainingClassSessionsByItem?: Maybe<Array<Maybe<TrainingClassSession>>>;
  getTrainingClasses?: Maybe<PaginatedTrainingClasses>;
  getTrainingSaleAppointment?: Maybe<TrainingSaleAppointment>;
  getTrainingSaleAppointments?: Maybe<TrainingSaleAppointments>;
  getUniversalSearch?: Maybe<Array<Maybe<UniversalSearch>>>;
  getUser?: Maybe<User>;
  getUserCompanyRole?: Maybe<UserCompanyRole>;
  getUserCompanyRoleStates?: Maybe<Array<Maybe<UserCompanyRoleState>>>;
  getUsers?: Maybe<PaginatedUsers>;
  getUsersByGroupId?: Maybe<PaginatedUserGroups>;
  getWidgetActivities?: Maybe<PaginatedActivities>;
  getWidgetTasks?: Maybe<PaginatedTasks>;
  getWinter2021CashBonusBaseshops?: Maybe<PaginatedWinter2021CashBonusBaseshops>;
  getWinter2021CashBonusPersonals?: Maybe<PaginatedWinter2021CashBonusPersonals>;
  getWorkflow?: Maybe<Workflow>;
  getWorkflowAgentPickerOptions?: Maybe<PaginatedUserCompanyRoles>;
  getWorkflowFieldRadioOptions?: Maybe<Array<Maybe<WorkflowFieldRadioOption>>>;
  getWorkflowItem?: Maybe<WorkflowItem>;
  getWorkflowItemComments?: Maybe<Array<Maybe<WorkflowItemComment>>>;
  getWorkflowItemHistories?: Maybe<Array<Maybe<WorkflowItemHistory>>>;
  getWorkflowItemPrivileges?: Maybe<Array<Maybe<WorkflowItemPrivilege>>>;
  getWorkflowItemValidationErrors?: Maybe<Array<Maybe<WorkflowItemField>>>;
  getWorkflowItems?: Maybe<PaginatedWorkflowItems>;
  getWorkflowStates?: Maybe<Array<Maybe<WorkflowState>>>;
  getWorkflowStatsByItemCategory?: Maybe<Array<Maybe<WorkflowItemCategoryStat>>>;
  getWorkflowWidgetActivities?: Maybe<PaginatedActivities>;
  getWorkflowWidgetPendingItems?: Maybe<PaginatedWorkflowItems>;
  getWorkflowWidgetTasks?: Maybe<PaginatedTasks>;
  getWorkflows?: Maybe<PaginatedWorkflows>;
  isAPCLChecklistExisting?: Maybe<Scalars['Boolean']>;
  isPromoteBaseshopAgent?: Maybe<Scalars['Boolean']>;
  searchFileEntries?: Maybe<FileEntries>;
  searchHelpArticles?: Maybe<Array<Maybe<HelpArticle>>>;
  searchSponsor?: Maybe<UserCompanyRole>;
  validateEventForKiosk?: Maybe<Event>;
};


export type QueryExecuteRawSqlCommissionEntriesArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryGenerateCommissionStatementForDownloadingArgs = {
  id: Scalars['ID'];
};


export type QueryGetAigGoogleCloudFilesArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetApclWorkflowItemsArgs = {
  filters?: InputMaybe<WorkflowItemFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetActivitiesArgs = {
  filters?: InputMaybe<ActivityFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetActivityArgs = {
  id: Scalars['ID'];
};


export type QueryGetAgentAuditArgs = {
  filters?: InputMaybe<AgentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAgentBankArgs = {
  agentId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAgentBankTransactionArgs = {
  id: Scalars['ID'];
};


export type QueryGetAgentBankTransactionsArgs = {
  filters?: InputMaybe<AgentBankTransactionFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAgentBanksArgs = {
  filters?: InputMaybe<AgentBankFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAgentExtraInfoArgs = {
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAgentFileCategoriesForListArgs = {
  filters?: InputMaybe<FileCategoryFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAgentFileCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryGetAgentFileEntriesArgs = {
  agentId: Scalars['ID'];
  filters?: InputMaybe<ProfileDocumentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAgentLevelArgs = {
  id: Scalars['ID'];
};


export type QueryGetAgentLevelsArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAgentLevelsForNewAgentArgs = {
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAgentLevelsForNewHistoryArgs = {
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAgentMemoArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAgentMemoAcknowledgementsArgs = {
  filters?: InputMaybe<AgentMemoFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAgentMemosArgs = {
  filters?: InputMaybe<MemoFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAgentsArgs = {
  filters?: InputMaybe<AgentFilterInput>;
  isEntireHierarchySearch?: InputMaybe<Scalars['Boolean']>;
  isStrictSearch?: InputMaybe<Scalars['Boolean']>;
  isTrainingSaleAppointment?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAgentsByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryGetAgentsForDirectoryArgs = {
  filters?: InputMaybe<AgentFilterInput>;
  isStrictSearch?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  viewTypeForAgent?: InputMaybe<Scalars['String']>;
};


export type QueryGetAgentsForPipelineArgs = {
  agentViewType?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AgentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllRolesArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllTourEntriesArgs = {
  filters?: InputMaybe<TourEntryFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAnalyzedPolicyPayoutCommissionEntriesArgs = {
  policyId: Scalars['ID'];
};


export type QueryGetAnalyzedPolicyReceivedCommissionEntriesArgs = {
  policyId: Scalars['ID'];
};


export type QueryGetApiKeysArgs = {
  userCompanyRoleId: Scalars['ID'];
};


export type QueryGetApplicantArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetApplicantsArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetApplicantsContractArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAssociatedUsersArgs = {
  agentLevelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  eventId?: InputMaybe<Scalars['ID']>;
  excludeUserCompanyRoleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  isBackOfficeUserOnly?: InputMaybe<Scalars['Boolean']>;
  isBaseshopRequired?: InputMaybe<Scalars['Boolean']>;
  isEntireHierarchySearch?: InputMaybe<Scalars['Boolean']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']>;
  isOnlyActiveUser?: InputMaybe<Scalars['Boolean']>;
  isOnlyCertifiedTrainer?: InputMaybe<Scalars['Boolean']>;
  isReservationOwner?: InputMaybe<Scalars['Boolean']>;
  isStrictSearch?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  privilegeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  query?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAutomationCustomTokenArgs = {
  email: Scalars['String'];
};


export type QueryGetAvailableTrainingClassesArgs = {
  filters?: InputMaybe<TrainingClassFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCpaReportArgs = {
  filters?: InputMaybe<CommissionEntryFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCalculationMethodArgs = {
  id: Scalars['ID'];
};


export type QueryGetCalculationMethodsArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCalendarEventsArgs = {
  timeMax?: InputMaybe<Scalars['DateTime']>;
  timeMin?: InputMaybe<Scalars['DateTime']>;
};


export type QueryGetCarrierArgs = {
  id: Scalars['ID'];
};


export type QueryGetCarrierAppointmentArgs = {
  carrierId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  producerCode?: InputMaybe<Scalars['String']>;
};


export type QueryGetCarrierAppointmentsArgs = {
  filters?: InputMaybe<CarrierAppointmentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetCarriersArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetChildrenArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetClickUpAttachmentUrlArgs = {
  taskCommentAttachmentId: Scalars['ID'];
  taskCommentId: Scalars['ID'];
  taskId: Scalars['ID'];
};


export type QueryGetCommissionAdjustmentArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommissionAdjustmentCategoriesArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionAdjustmentCategoryArgs = {
  code: Scalars['ID'];
};


export type QueryGetCommissionAdjustmentReviewArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommissionAdjustmentReviewItemArgs = {
  commissionAdjustmentReviewItemId: Scalars['ID'];
};


export type QueryGetCommissionAdjustmentReviewItemsArgs = {
  filters?: InputMaybe<CommissionAdjustmentReviewItemListFilterInput>;
  id: Scalars['ID'];
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionAdjustmentReviewsArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionAdjustmentsArgs = {
  filters?: InputMaybe<CommissionAdjustmentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionAdjustmentsByStatementArgs = {
  commissionStatementId: Scalars['ID'];
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionAssignmentArgs = {
  id?: InputMaybe<Scalars['ID']>;
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetCommissionAssignmentExtraInfoArgs = {
  commissionAssignmentId?: InputMaybe<Scalars['ID']>;
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetCommissionCalculatedEntriesArgs = {
  filters?: InputMaybe<CommissionCalculatedEntryFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionCalculatedEntriesForAgentArgs = {
  filters?: InputMaybe<CommissionCalculatedEntryFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionCalculatedEntriesOnHoldArgs = {
  filters?: InputMaybe<CommissionCalculatedEntryFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionCalculatedEntryArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommissionCalculatedEntryForAgentArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommissionCycleArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommissionCyclesArgs = {
  filters?: InputMaybe<CommissionCycleFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionCyclesByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryGetCommissionEligibilityOverridesArgs = {
  policyId?: InputMaybe<Scalars['ID']>;
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetCommissionEntriesArgs = {
  filters?: InputMaybe<CommissionEntryFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionEntriesByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryGetCommissionEntriesOfBatchArgs = {
  filters?: InputMaybe<CommissionEntryFilterInput>;
  id: Scalars['ID'];
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionEntryArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommissionEntryCategoriesForListArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionEntryCategoryArgs = {
  code: Scalars['ID'];
  isCreatingNewCommissionEntryCategoryCompany?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetCommissionOverviewStatsArgs = {
  filters?: InputMaybe<CommissionOverviewFilterInput>;
};


export type QueryGetCommissionRawBatchArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommissionRawBatchEntriesPerCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommissionRawBatchEntriesPerStatusArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommissionRawBatchTopProducingAgentsArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommissionRawBatchesArgs = {
  filters?: InputMaybe<CommissionRawBatchFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionStatementArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommissionStatementDownloadUrlArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommissionStatementLogsArgs = {
  filters?: InputMaybe<CommissionStatementLogFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionStatementsArgs = {
  filters?: InputMaybe<CommissionStatementFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCommissionStatementsByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryGetCommissionStatementsForAgentArgs = {
  filters?: InputMaybe<CommissionStatementFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCompanyApprovedLicensingStatesArgs = {
  filters?: InputMaybe<ApprovedLicensingMapFilterInput>;
};


export type QueryGetCompanyByCodeArgs = {
  code: Scalars['ID'];
};


export type QueryGetCompanySystemFeaturesArgs = {
  filters?: InputMaybe<CompanySystemFeatureFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCompensationPlanArgs = {
  id: Scalars['ID'];
};


export type QueryGetCompensationPlansArgs = {
  filters?: InputMaybe<CompensationPlanFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};


export type QueryGetCompensationTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryGetCompensationTemplatesArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCustomerByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetCustomerByInsuredNameArgs = {
  insuredName: Scalars['String'];
};


export type QueryGetCustomersArgs = {
  filters?: InputMaybe<CustomerFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetDefaultReportArgs = {
  reportTemplateId: Scalars['ID'];
};


export type QueryGetDescendantsArgs = {
  filters?: InputMaybe<AgentFilterInput>;
  hierarchyHistoryId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  isProfile?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetDownlinesArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetDuplicateCommissionEntriesOfBatchArgs = {
  filters?: InputMaybe<DuplicateCommissionEntryFilterInput>;
  id: Scalars['ID'];
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetEAndOArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetEAndOsArgs = {
  filters?: InputMaybe<ErrorOmissionFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetEmailTemplateArgs = {
  name: Scalars['ID'];
};


export type QueryGetEmailTemplatesArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetEventArgs = {
  id: Scalars['ID'];
};


export type QueryGetEventReservationArgs = {
  id: Scalars['ID'];
};


export type QueryGetEventReservationStateStatsArgs = {
  filters?: InputMaybe<EventReservationFilterInput>;
};


export type QueryGetEventReservationsArgs = {
  filters?: InputMaybe<EventReservationFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetEventReservationsByEventArgs = {
  eventId: Scalars['ID'];
};


export type QueryGetEventReservationsByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryGetEventSessionArgs = {
  id: Scalars['ID'];
};


export type QueryGetEventSessionsArgs = {
  filters?: InputMaybe<EventSessionFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetEventTicketArgs = {
  id: Scalars['ID'];
};


export type QueryGetEventTicketAssignmentStatsArgs = {
  filters?: InputMaybe<EventTicketFilterInput>;
};


export type QueryGetEventTicketSessionsArgs = {
  filters?: InputMaybe<EventTicketSessionFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetEventTicketStateStatsArgs = {
  filters?: InputMaybe<EventTicketFilterInput>;
};


export type QueryGetEventTicketsArgs = {
  filters?: InputMaybe<EventTicketFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetEventsArgs = {
  filters?: InputMaybe<EventFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetExcludedCommissionEntriesOfBatchArgs = {
  filters?: InputMaybe<CommissionEntryFilterInput>;
  id: Scalars['ID'];
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetFieldTrainingSaleAppointmentsArgs = {
  appointmentDate?: InputMaybe<Scalars['String']>;
  excludeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  policyNumber?: InputMaybe<Scalars['String']>;
  trainerId: Scalars['ID'];
  workflowItemId: Scalars['ID'];
};


export type QueryGetFileEntriesByNodeArgs = {
  parentId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetFilesByPlanArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetGenerationOverrideRequirementWaiverArgs = {
  id: Scalars['ID'];
};


export type QueryGetGenerationOverrideRequirementWaiversArgs = {
  filters?: InputMaybe<GenerationOverrideWaiverFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetGenerationOverridesQualificationArgs = {
  filters?: InputMaybe<GenerationOverrideQualificationFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGetGroupsArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetHierarchyHistoriesArgs = {
  filters?: InputMaybe<HierarchyHistoryFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetHierarchyHistoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetInvitationArgs = {
  id: Scalars['ID'];
};


export type QueryGetInvitationsArgs = {
  filters?: InputMaybe<InvitationFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetLevelHistoriesArgs = {
  userCompanyRoleId: Scalars['ID'];
};


export type QueryGetLevelHistoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetLicenseArgs = {
  id: Scalars['ID'];
};


export type QueryGetLicenseInfoArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetLicenseInfosArgs = {
  filters?: InputMaybe<LicenseInfoFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetLicensesArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetLinkByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetLinksArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetManageCarrierAppointmentsArgs = {
  filters?: InputMaybe<CarrierAppointmentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetManageCommissionAssignmentsArgs = {
  filters?: InputMaybe<CommissionAssignmentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetManageErrorOmissionsArgs = {
  filters?: InputMaybe<ErrorOmissionFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetManageLicenseInfosArgs = {
  filters?: InputMaybe<LicenseInfoFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetMatchingTrainingSaleAppointmentsArgs = {
  filters?: InputMaybe<InvitationFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  traineeId?: InputMaybe<Scalars['ID']>;
  trainerId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetMembershipAgreementArgs = {
  profileId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetNewBusinessesArgs = {
  filters?: InputMaybe<NewBusinessFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetNotVisitedTourEntriesArgs = {
  pagePath?: InputMaybe<Scalars['String']>;
};


export type QueryGetNoteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetNotesArgs = {
  createdDateOrder?: InputMaybe<Scalars['String']>;
  userCompanyRoleId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetPaymentModeArgs = {
  code: Scalars['ID'];
};


export type QueryGetPaymentModesForListArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPoliciesArgs = {
  filters?: InputMaybe<PolicyFilterInput>;
  isWritingAgentOnly?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  viewType?: InputMaybe<Scalars['String']>;
};


export type QueryGetPoliciesByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryGetPolicyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  policyNumber?: InputMaybe<Scalars['ID']>;
};


export type QueryGetPolicyCustomerDetailsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  policyNumber?: InputMaybe<Scalars['ID']>;
};


export type QueryGetPolicyExchangeSalesByPolicyIdArgs = {
  policyId: Scalars['ID'];
};


export type QueryGetPolicyForRegisterArgs = {
  policyNumber: Scalars['String'];
};


export type QueryGetPolicyHierarchyArgs = {
  policyId: Scalars['ID'];
  userAgentId: Scalars['ID'];
  userAgentType: Scalars['String'];
};


export type QueryGetPolicyNotesArgs = {
  createdDateOrder?: InputMaybe<Scalars['String']>;
  policyId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetPolicySplitSalesByPolicyIdArgs = {
  policyId: Scalars['ID'];
};


export type QueryGetPolicyStateArgs = {
  code: Scalars['ID'];
  isCreatingNewPolicyStateCompany?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetPolicyStatesForListArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPolicyTrackingReportArgs = {
  filters?: InputMaybe<PolicyFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPolicyTrainingSalesByPolicyIdArgs = {
  policyId: Scalars['ID'];
};


export type QueryGetPrivilegesArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductArgs = {
  carrierId?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetProductTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetProductTypesArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetProductionByStatusArgs = {
  filters?: InputMaybe<PolicyFilterInput>;
  viewType?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductionByTimeArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<PolicyFilterInput>;
  interval?: InputMaybe<Scalars['String']>;
  policyDateType?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  viewType?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductionStatsArgs = {
  filters?: InputMaybe<ProductionStatFilterInput>;
  metric?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  viewType?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductionSuccessfulRateStatsArgs = {
  count?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<PolicyFilterInput>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
  viewType?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductsArgs = {
  excludedIds?: InputMaybe<Scalars['ID']>;
  filters?: InputMaybe<ProductFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetProductsByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryGetProfileHierarchyArgs = {
  hierarchyHistoryId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};


export type QueryGetProfilePaymentMethodArgs = {
  paymentMethodToken?: InputMaybe<Scalars['String']>;
  viewerId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetProfilePaymentMethodsArgs = {
  viewerId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetProxiedLinkArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetRawBatchesByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryGetRecruitingStatsArgs = {
  filters?: InputMaybe<RecruitingStatFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  viewType?: InputMaybe<Scalars['String']>;
};


export type QueryGetRegisteredAgentsArgs = {
  filters?: InputMaybe<RegisteredAgentsFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  trainingClassSessionId: Scalars['ID'];
};


export type QueryGetRoleArgs = {
  id: Scalars['ID'];
};


export type QueryGetSponsorFromInvitationTokenArgs = {
  token: Scalars['String'];
};


export type QueryGetSubmissionRequirementWarningsArgs = {
  policyId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetSubmissionRequirementWarningsTaskCreatedArgs = {
  currentUrl?: InputMaybe<Scalars['String']>;
  policyId: Scalars['ID'];
};


export type QueryGetTaskArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetTaskCategoryCompaniesArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTaskCategoryCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetTaskCommentsArgs = {
  createdDateOrder?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetTasksArgs = {
  filters?: InputMaybe<TaskFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  policyId?: InputMaybe<Scalars['ID']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  userKind?: InputMaybe<Scalars['String']>;
};


export type QueryGetTasksByProfileArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  profileId: Scalars['ID'];
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTourEntriesArgs = {
  pagePath?: InputMaybe<Scalars['String']>;
};


export type QueryGetTourEntryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetTrainingClassByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetTrainingClassRegistrationWorkflowItemsArgs = {
  filters?: InputMaybe<WorkflowItemFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTrainingClassSessionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetTrainingClassSessionsArgs = {
  filters?: InputMaybe<TrainingClassSessionFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  trainingClassId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetTrainingClassSessionsByItemArgs = {
  filters?: InputMaybe<TrainingClassSessionFilterInput>;
  workflowItemId: Scalars['ID'];
};


export type QueryGetTrainingClassesArgs = {
  filters?: InputMaybe<TrainingClassFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTrainingSaleAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryGetTrainingSaleAppointmentsArgs = {
  filters?: InputMaybe<TrainingSaleAppointmentFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetUniversalSearchArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserCompanyRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetUsersArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetUsersByGroupIdArgs = {
  agentLevelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  agentStateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  groupId: Scalars['ID'];
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetWinter2021CashBonusBaseshopsArgs = {
  filters?: InputMaybe<Winter2021StatFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetWinter2021CashBonusPersonalsArgs = {
  filters?: InputMaybe<Winter2021StatFilterInput>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetWorkflowArgs = {
  id: Scalars['ID'];
};


export type QueryGetWorkflowAgentPickerOptionsArgs = {
  isBaseshopRequired?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
  workflowId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetWorkflowFieldRadioOptionsArgs = {
  workflowId: Scalars['ID'];
};


export type QueryGetWorkflowItemArgs = {
  id: Scalars['ID'];
};


export type QueryGetWorkflowItemCommentsArgs = {
  fieldId?: InputMaybe<Scalars['ID']>;
  itemId: Scalars['ID'];
};


export type QueryGetWorkflowItemHistoriesArgs = {
  itemId: Scalars['ID'];
};


export type QueryGetWorkflowItemPrivilegesArgs = {
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  workflowItemId?: InputMaybe<Scalars['ID']>;
  workflowRoleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryGetWorkflowItemValidationErrorsArgs = {
  id: Scalars['ID'];
};


export type QueryGetWorkflowItemsArgs = {
  filters?: InputMaybe<WorkflowItemFilterInput>;
  hasDedicatedPage?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryGetWorkflowStatsByItemCategoryArgs = {
  workflowId: Scalars['ID'];
};


export type QueryGetWorkflowWidgetActivitiesArgs = {
  workflowId: Scalars['ID'];
};


export type QueryGetWorkflowWidgetPendingItemsArgs = {
  workflowId: Scalars['ID'];
};


export type QueryGetWorkflowWidgetTasksArgs = {
  workflowId: Scalars['ID'];
};


export type QueryGetWorkflowsArgs = {
  filters?: InputMaybe<WorkflowFilterInput>;
  hasDedicatedPage?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  rowsPerPage?: InputMaybe<Scalars['Int']>;
};


export type QueryIsPromoteBaseshopAgentArgs = {
  childId: Scalars['ID'];
  parentId?: InputMaybe<Scalars['ID']>;
};


export type QuerySearchFileEntriesArgs = {
  input?: InputMaybe<FolderInput>;
};


export type QuerySearchHelpArticlesArgs = {
  folderId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QuerySearchSponsorArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryValidateEventForKioskArgs = {
  eventCode: Scalars['String'];
  password: Scalars['String'];
};

export type RawSqlQuery = {
  __typename?: 'RawSqlQuery';
  createdAt?: Maybe<Scalars['BigInt']>;
  createdByUser?: Maybe<UserCompanyRole>;
  createdByUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  query: Scalars['String'];
  updatedAt?: Maybe<Scalars['BigInt']>;
  updatedByUser?: Maybe<UserCompanyRole>;
  updatedByUserId?: Maybe<Scalars['ID']>;
};

export type ReceivingAccount = {
  __typename?: 'ReceivingAccount';
  companyId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type RecruitingStat = {
  __typename?: 'RecruitingStat';
  agent: UserCompanyRole;
  agentId: Scalars['ID'];
  appointedCount?: Maybe<Scalars['Int']>;
  completedExchangeSaleCount?: Maybe<Scalars['Int']>;
  errorOmissionApprovedCount?: Maybe<Scalars['Int']>;
  licensedCount?: Maybe<Scalars['Int']>;
  policyCount?: Maybe<Scalars['Int']>;
  recruitCount?: Maybe<Scalars['Int']>;
  sponsorIds?: Maybe<Scalars['String']>;
  totalRecruits?: Maybe<Scalars['Int']>;
};

export type RecruitingStatFilterInput = {
  agentLevels?: InputMaybe<AgentFilterAgentLevelInput>;
  agents?: InputMaybe<FilterIdsInput>;
  dateApplied?: InputMaybe<DateTimeFilterQueryInput>;
  numberOfSoldPolicies?: InputMaybe<NumberFilterInput>;
  recruitCount?: InputMaybe<NumberFilterInput>;
  totalRecruits?: InputMaybe<NumberFilterInput>;
};

export type RecruitingStatSummary = {
  __typename?: 'RecruitingStatSummary';
  totalAppointedCount?: Maybe<Scalars['Float']>;
  totalCompletedExchangeSaleCount?: Maybe<Scalars['Float']>;
  totalErrorOmissionApprovedCount?: Maybe<Scalars['Float']>;
  totalLicensedCount?: Maybe<Scalars['Float']>;
  totalPolicyCount?: Maybe<Scalars['Float']>;
  totalRecruitCount?: Maybe<Scalars['Float']>;
  totalRecruits?: Maybe<Scalars['Float']>;
};

export type RegisteredAgentsFilterInput = {
  agents?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  states?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type Relationshop = {
  __typename?: 'Relationshop';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type Report = {
  __typename?: 'Report';
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type ReportColumn = {
  __typename?: 'ReportColumn';
  align?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  formatOptions?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  selected?: Maybe<Scalars['Boolean']>;
  sortable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type ResidenceType = {
  __typename?: 'ResidenceType';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  privileges?: Maybe<Array<Maybe<Privilege>>>;
};

export type RolePrivilegeInput = {
  isAllowed: Scalars['Boolean'];
  privilegeId: Scalars['ID'];
  roleId: Scalars['ID'];
};

export type Session = {
  __typename?: 'Session';
  isImpersonateFailure?: Maybe<Scalars['Boolean']>;
  isLinksManager?: Maybe<Scalars['Boolean']>;
  privileges?: Maybe<Array<Maybe<Privilege>>>;
  role?: Maybe<Role>;
  user?: Maybe<User>;
  userCompanyRole?: Maybe<UserCompanyRole>;
};

export type State = {
  __typename?: 'State';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type StaticReport = {
  __typename?: 'StaticReport';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type StaticReportGroup = {
  __typename?: 'StaticReportGroup';
  children?: Maybe<Array<Maybe<StaticReport>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Status = {
  __typename?: 'Status';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type SystemFeature = {
  __typename?: 'SystemFeature';
  description: Scalars['String'];
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  assigneeAgent?: Maybe<UserCompanyRole>;
  assigneeId?: Maybe<Scalars['ID']>;
  belongsToBackOffice?: Maybe<Scalars['Boolean']>;
  category?: Maybe<TaskCategory>;
  categoryId?: Maybe<Scalars['ID']>;
  clickupTaskStatus?: Maybe<Scalars['String']>;
  comparisonValues?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  devTaskId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isAssignable?: Maybe<Scalars['Boolean']>;
  isClosable?: Maybe<Scalars['Boolean']>;
  isCommentable?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isLinkToItem?: Maybe<Scalars['Boolean']>;
  isResolvable?: Maybe<Scalars['Boolean']>;
  isSendTaskToClickUpAllowed?: Maybe<Scalars['Boolean']>;
  isStartable?: Maybe<Scalars['Boolean']>;
  isSystem?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  isWarning?: Maybe<Scalars['Boolean']>;
  itemLink?: Maybe<Scalars['String']>;
  itemText?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  relatedAgent?: Maybe<UserCompanyRole>;
  relatedId?: Maybe<Scalars['ID']>;
  reporterAgent?: Maybe<UserCompanyRole>;
  reporterId?: Maybe<Scalars['ID']>;
  resolution?: Maybe<Scalars['String']>;
  resolutionWaitingDurationInDays?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  technicalInfo?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedByUserAgent?: Maybe<UserCompanyRole>;
};

export type TaskCategory = {
  __typename?: 'TaskCategory';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPublicVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type TaskCategoryCompany = {
  __typename?: 'TaskCategoryCompany';
  assigneeAgent?: Maybe<UserCompanyRole>;
  carrier?: Maybe<Array<Maybe<TaskCategoryCompanyCarrier>>>;
  category?: Maybe<TaskCategory>;
  defaultAssigneeId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  taskCategoryId: Scalars['ID'];
};

export type TaskCategoryCompanyCarrier = {
  __typename?: 'TaskCategoryCompanyCarrier';
  carrierId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  taskCategoryCompanyId?: Maybe<Scalars['ID']>;
};

export type TaskComment = {
  __typename?: 'TaskComment';
  attachments?: Maybe<Array<Maybe<TaskCommentAttachment>>>;
  comment: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<UserCompanyRole>;
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
};

export type TaskCommentAttachment = {
  __typename?: 'TaskCommentAttachment';
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<UserCompanyRole>;
  fileUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  size?: Maybe<Scalars['BigInt']>;
  state?: Maybe<Scalars['String']>;
  taskCommentId: Scalars['ID'];
};

export type TaskFilterInput = {
  assignees?: InputMaybe<FilterIdsInput>;
  dueDate?: InputMaybe<DateTimeFilterQueryInput>;
  policies?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  reporters?: InputMaybe<FilterIdsInput>;
  taskCategories?: InputMaybe<FilterIdsInput>;
  taskStatuses?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
  workflows?: InputMaybe<FilterIdsInput>;
};

export type TaskState = {
  __typename?: 'TaskState';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type TaxpayerType = {
  __typename?: 'TaxpayerType';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type TimeZoneDate = {
  __typename?: 'TimeZoneDate';
  date?: Maybe<Scalars['DateTime']>;
  dateTime?: Maybe<Scalars['DateTime']>;
  timeZone?: Maybe<Scalars['String']>;
};

export type TopChargebackAgent = {
  __typename?: 'TopChargebackAgent';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type TopChargebackBaseshop = {
  __typename?: 'TopChargebackBaseshop';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type TopProfitableAgent = {
  __typename?: 'TopProfitableAgent';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  paidAmount?: Maybe<Scalars['Float']>;
  profitAmount?: Maybe<Scalars['Float']>;
  receivedAmount?: Maybe<Scalars['Float']>;
};

export type TopProfitableBaseshop = {
  __typename?: 'TopProfitableBaseshop';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  paidAmount?: Maybe<Scalars['Float']>;
  profitAmount?: Maybe<Scalars['Float']>;
  receivedAmount?: Maybe<Scalars['Float']>;
};

export type TotalCommissionAmount = {
  __typename?: 'TotalCommissionAmount';
  totalNetCommissionAmount?: Maybe<Scalars['Float']>;
  totalPayoutCommissionAmount?: Maybe<Scalars['Float']>;
  totalReceivingCommissionAmount?: Maybe<Scalars['Float']>;
};

export type TourEntry = {
  __typename?: 'TourEntry';
  contentInMarkdown?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeletable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  isVisited?: Maybe<Scalars['Boolean']>;
  pagePath?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  selector?: Maybe<Scalars['String']>;
};

export type TourEntryFilterInput = {
  pagePath?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type TrainingClass = {
  __typename?: 'TrainingClass';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  description?: Maybe<Scalars['String']>;
  htmlDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAssistant?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isInstructor?: Maybe<Scalars['Boolean']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  isStartable?: Maybe<Scalars['Boolean']>;
  isViewable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  registeredClassWorkflowItem?: Maybe<WorkflowItem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
};

export type TrainingClassFilterInput = {
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type TrainingClassSession = {
  __typename?: 'TrainingClassSession';
  assistants?: Maybe<Array<Maybe<UserCompanyRole>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCompanyRole>;
  description?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<TrainingClassSessionFile>>>;
  htmlDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructors?: Maybe<Array<Maybe<UserCompanyRole>>>;
  isAssistantsEditable?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isInstructorsEditable?: Maybe<Scalars['Boolean']>;
  isViewable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  numberOfRegisteredAgent?: Maybe<Scalars['Int']>;
  registeredWorkflowItem?: Maybe<WorkflowItem>;
  trainingClass?: Maybe<TrainingClass>;
  trainingClassId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCompanyRole>;
};

export type TrainingClassSessionFile = {
  __typename?: 'TrainingClassSessionFile';
  contentType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  trainingClassSessionId?: Maybe<Scalars['Int']>;
};

export type TrainingClassSessionFilterInput = {
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type TrainingClassSessionInstructor = {
  __typename?: 'TrainingClassSessionInstructor';
  agentCode?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type TrainingClassSessionRegisteredAgent = {
  __typename?: 'TrainingClassSessionRegisteredAgent';
  agent?: Maybe<UserCompanyRole>;
  agentId?: Maybe<Scalars['ID']>;
  completedRequirements?: Maybe<Scalars['Int']>;
  totalRequirements?: Maybe<Scalars['Int']>;
  workflowItem?: Maybe<WorkflowItem>;
  workflowItemId: Scalars['ID'];
};

export type TrainingSaleAppointment = {
  __typename?: 'TrainingSaleAppointment';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['BigInt']>;
  createdBy?: Maybe<UserCompanyRole>;
  createdByUserId?: Maybe<Scalars['BigInt']>;
  date?: Maybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  policies?: Maybe<Array<Maybe<Policy>>>;
  policyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  trainee?: Maybe<UserCompanyRole>;
  traineeId?: Maybe<Scalars['ID']>;
  trainer?: Maybe<UserCompanyRole>;
  trainerId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['BigInt']>;
  updatedBy?: Maybe<UserCompanyRole>;
  updatedByUserId?: Maybe<Scalars['BigInt']>;
};

export type TrainingSaleAppointmentFilterInput = {
  date?: InputMaybe<DateTimeFilterQueryInput>;
  policies?: InputMaybe<FilterIdsInput>;
  text?: InputMaybe<FilterQueryInput>;
  trainee?: InputMaybe<FilterIdsInput>;
  trainer?: InputMaybe<FilterIdsInput>;
};

export type TrainingSaleAppointments = {
  __typename?: 'TrainingSaleAppointments';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<TrainingSaleAppointment>>>;
};

export type UniversalSearch = {
  __typename?: 'UniversalSearch';
  carrierAppointment?: Maybe<CarrierAppointment>;
  commissionStatement?: Maybe<CommissionStatement>;
  eAndO?: Maybe<EAndO>;
  event?: Maybe<Event>;
  fileEntry?: Maybe<FileEntry>;
  policy?: Maybe<Policy>;
  task?: Maybe<Task>;
  typeName?: Maybe<Scalars['String']>;
  userCompanyRole?: Maybe<UserCompanyRole>;
};

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyCode?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  initials: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  isUserCreatable?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastLoggedInAt?: Maybe<Scalars['BigInt']>;
  name: Scalars['String'];
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['ID']>;
};

export type UserAccountLink = {
  __typename?: 'UserAccountLink';
  email: Scalars['String'];
  id: Scalars['ID'];
  provider: Scalars['String'];
  providerData: Scalars['String'];
  uid: Scalars['String'];
  userId: Scalars['ID'];
};

export type UserCompanyRole = {
  __typename?: 'UserCompanyRole';
  accountLinks?: Maybe<Array<Maybe<UserAccountLink>>>;
  activeChildrenCount?: Maybe<Scalars['BigInt']>;
  activeDescendantCount?: Maybe<Scalars['BigInt']>;
  agentCode?: Maybe<Scalars['String']>;
  agentExtraInfo?: Maybe<AgentExtraInfo>;
  agentLevel?: Maybe<AgentLevel>;
  agentLevelId?: Maybe<Scalars['ID']>;
  agentTypeId?: Maybe<Scalars['String']>;
  agreedToTOS?: Maybe<Scalars['Boolean']>;
  ancestorIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  areGenerationalOverrideRulesApplied?: Maybe<Scalars['Boolean']>;
  avatarUrl?: Maybe<Scalars['String']>;
  baseshopAgentCount?: Maybe<Scalars['Int']>;
  baseshopAgentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  baseshopAgentLevels?: Maybe<Scalars['String']>;
  businessAddress1?: Maybe<Scalars['String']>;
  businessAddress2?: Maybe<Scalars['String']>;
  businessCity?: Maybe<Scalars['String']>;
  businessState?: Maybe<Scalars['String']>;
  businessZip?: Maybe<Scalars['String']>;
  canImpersonate?: Maybe<Scalars['Boolean']>;
  canRecalculateToAdjustCommissionEntries?: Maybe<Scalars['Boolean']>;
  canRunReports?: Maybe<Scalars['Boolean']>;
  carrierAppointment?: Maybe<Array<Maybe<CarrierAppointment>>>;
  childrenCount?: Maybe<Scalars['Int']>;
  childrenIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  commissionOnHoldCreatedAt?: Maybe<Scalars['DateTime']>;
  commissionOnHoldCreatedBy?: Maybe<UserCompanyRole>;
  commissionOnHoldNote?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  dateApplied?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['BigInt']>;
  dateReviewed?: Maybe<Scalars['DateTime']>;
  descendantAgentLevels?: Maybe<Scalars['String']>;
  descendantCount?: Maybe<Scalars['Int']>;
  descendantIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  directDeposit?: Maybe<CommissionAssignment>;
  email?: Maybe<Scalars['String']>;
  employments?: Maybe<Array<Maybe<AgentApplicationEmployment>>>;
  eos?: Maybe<Array<Maybe<EAndO>>>;
  eosPolicyNumber?: Maybe<EAndO>;
  exchangeSales?: Maybe<Array<Maybe<Policy>>>;
  falloutLifeCount?: Maybe<Scalars['Float']>;
  falloutLifePoints?: Maybe<Scalars['Float']>;
  fax?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hasDownlines?: Maybe<Scalars['Boolean']>;
  hierarchicalDepth?: Maybe<Scalars['Int']>;
  histories?: Maybe<Array<Maybe<UserCompanyRoleHistory>>>;
  homeAddress1?: Maybe<Scalars['String']>;
  homeAddress2?: Maybe<Scalars['String']>;
  homeCity?: Maybe<Scalars['String']>;
  homeState?: Maybe<Scalars['String']>;
  homeZip?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inactiveBySystem?: Maybe<Scalars['Boolean']>;
  inforceLifeCount?: Maybe<Scalars['Float']>;
  inforceLifePoints?: Maybe<Scalars['Float']>;
  initials?: Maybe<Scalars['String']>;
  isAccountBalanceEnabled?: Maybe<Scalars['Boolean']>;
  isActivable?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActiveStatus?: Maybe<Scalars['Boolean']>;
  isAgent?: Maybe<Scalars['Boolean']>;
  isAgentChecklistManager?: Maybe<Scalars['Boolean']>;
  isAgentInformationViewable?: Maybe<Scalars['Boolean']>;
  isAgentLevelsManager?: Maybe<Scalars['Boolean']>;
  isAgentMemoManager?: Maybe<Scalars['Boolean']>;
  isApiKeyManager?: Maybe<Scalars['Boolean']>;
  isApprovable?: Maybe<Scalars['Boolean']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isBackOfficeMember?: Maybe<Scalars['Boolean']>;
  isBankManager?: Maybe<Scalars['Boolean']>;
  isBaseshopManagerActivationAllowed?: Maybe<Scalars['Boolean']>;
  isBusinessAddressSameAsHome?: Maybe<Scalars['Boolean']>;
  isCLIManager?: Maybe<Scalars['Boolean']>;
  isCarrierAppointmentManager?: Maybe<Scalars['Boolean']>;
  isCarrierAppointmentViewer?: Maybe<Scalars['Boolean']>;
  isCarriersManager?: Maybe<Scalars['Boolean']>;
  isCertified?: Maybe<Scalars['Boolean']>;
  isCertifiedTrainer?: Maybe<Scalars['Boolean']>;
  isCommissionAdjustmentCategoriesManager?: Maybe<Scalars['Boolean']>;
  isCommissionAssignmentManager?: Maybe<Scalars['Boolean']>;
  isCommissionEntryCategoriesManager?: Maybe<Scalars['Boolean']>;
  isCommissionManager?: Maybe<Scalars['Boolean']>;
  isCommissionOnHold?: Maybe<Scalars['Boolean']>;
  isCommissionUsedAsPaymentAllowed?: Maybe<Scalars['Boolean']>;
  isCommissionViewer?: Maybe<Scalars['Boolean']>;
  isCompanySettingsManager?: Maybe<Scalars['Boolean']>;
  isCompanySettingsViewer?: Maybe<Scalars['Boolean']>;
  isCompensationPlansManager?: Maybe<Scalars['Boolean']>;
  isCompensationPlansViewer?: Maybe<Scalars['Boolean']>;
  isCompensationTemplatesManager?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isDocumentViewable?: Maybe<Scalars['Boolean']>;
  isDriverLicenseApprovable?: Maybe<Scalars['Boolean']>;
  isDriverLicenseDeletable?: Maybe<Scalars['Boolean']>;
  isDriverLicenseRejectable?: Maybe<Scalars['Boolean']>;
  isDriverLicenseSubmitable?: Maybe<Scalars['Boolean']>;
  isDriverLicenseViewable?: Maybe<Scalars['Boolean']>;
  isEmailTemplateManager?: Maybe<Scalars['Boolean']>;
  isErrorOmissionManager?: Maybe<Scalars['Boolean']>;
  isErrorOmissionViewer?: Maybe<Scalars['Boolean']>;
  isEventManager?: Maybe<Scalars['Boolean']>;
  isExcludedAsSponsor?: Maybe<Scalars['Boolean']>;
  isFieldManualAcknowledged?: Maybe<Scalars['Boolean']>;
  isFieldManualViewable?: Maybe<Scalars['Boolean']>;
  isFilesManager?: Maybe<Scalars['Boolean']>;
  isFilesViewer?: Maybe<Scalars['Boolean']>;
  isGenerationalOverridesDisqualified?: Maybe<Scalars['Boolean']>;
  isGroupManager?: Maybe<Scalars['Boolean']>;
  isHierarchyViewable?: Maybe<Scalars['Boolean']>;
  isHistoryViewable?: Maybe<Scalars['Boolean']>;
  isHomeAddressDefaultMailing?: Maybe<Scalars['Boolean']>;
  isImpersonatable?: Maybe<Scalars['Boolean']>;
  isInactivable?: Maybe<Scalars['Boolean']>;
  isInvitationManager?: Maybe<Scalars['Boolean']>;
  isLastStatementWidgetEnabled?: Maybe<Scalars['Boolean']>;
  isLevelHistoryManager?: Maybe<Scalars['Boolean']>;
  isLicenseInfoManager?: Maybe<Scalars['Boolean']>;
  isLicenseInfoViewer?: Maybe<Scalars['Boolean']>;
  isLicensesManager?: Maybe<Scalars['Boolean']>;
  isLinksManager?: Maybe<Scalars['Boolean']>;
  isManageDefaultTaskAssigneesAccessible?: Maybe<Scalars['Boolean']>;
  isManagerOrAbove?: Maybe<Scalars['Boolean']>;
  isMembershipAgreementViewable?: Maybe<Scalars['Boolean']>;
  isNewMemberApplicationManager?: Maybe<Scalars['Boolean']>;
  isNewMemberApplicationViewer?: Maybe<Scalars['Boolean']>;
  isNotificationPreferencesViewable?: Maybe<Scalars['Boolean']>;
  isOwnedCommissionStatementViewer?: Maybe<Scalars['Boolean']>;
  isOwnedPayoutEntriesViewable?: Maybe<Scalars['Boolean']>;
  isPaymentMethodsViewable?: Maybe<Scalars['Boolean']>;
  isPaymentModesManager?: Maybe<Scalars['Boolean']>;
  isPoliciesViewer?: Maybe<Scalars['Boolean']>;
  isPolicyManager?: Maybe<Scalars['Boolean']>;
  isPolicyNotesManager?: Maybe<Scalars['Boolean']>;
  isPolicyStatesManager?: Maybe<Scalars['Boolean']>;
  isPrivilegesManager?: Maybe<Scalars['Boolean']>;
  isProductTypesManager?: Maybe<Scalars['Boolean']>;
  isProductsManager?: Maybe<Scalars['Boolean']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  isProfileHealthRecalculable?: Maybe<Scalars['Boolean']>;
  isProfileHealthViewable?: Maybe<Scalars['Boolean']>;
  isProfileNoteManager?: Maybe<Scalars['Boolean']>;
  isRejectable?: Maybe<Scalars['Boolean']>;
  isRolesManager?: Maybe<Scalars['Boolean']>;
  isSendMessage?: Maybe<Scalars['Boolean']>;
  isSendTaskToClickUpAllowed?: Maybe<Scalars['Boolean']>;
  isSubscribedToSMS?: Maybe<Scalars['Boolean']>;
  isSystemManager?: Maybe<Scalars['Boolean']>;
  isTaskViewable?: Maybe<Scalars['Boolean']>;
  isTasksManager?: Maybe<Scalars['Boolean']>;
  isTasksViewer?: Maybe<Scalars['Boolean']>;
  isTerminatable?: Maybe<Scalars['Boolean']>;
  isTourEntriesManager?: Maybe<Scalars['Boolean']>;
  isTrainingManager?: Maybe<Scalars['Boolean']>;
  isTrainingViewer?: Maybe<Scalars['Boolean']>;
  isTransferable?: Maybe<Scalars['Boolean']>;
  isUpdatable?: Maybe<Scalars['Boolean']>;
  isUpdatableFieldManualAcknowledged?: Maybe<Scalars['Boolean']>;
  isUserManager?: Maybe<Scalars['Boolean']>;
  isUserViewable?: Maybe<Scalars['Boolean']>;
  isUserViewer?: Maybe<Scalars['Boolean']>;
  isUsingDailyDigestEmail?: Maybe<Scalars['Boolean']>;
  isVested?: Maybe<Scalars['Boolean']>;
  isViewPrivileges?: Maybe<Scalars['Boolean']>;
  lastActivity?: Maybe<UserCompanyRoleHistory>;
  lastLoggedInAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  latestDateEAndO?: Maybe<Scalars['DateTime']>;
  latestPolicy?: Maybe<Policy>;
  latestRecruit?: Maybe<UserCompanyRole>;
  licenseInfo?: Maybe<LicenseInfo>;
  lifeSaleStats?: Maybe<Array<Maybe<LifeSaleStat>>>;
  manager?: Maybe<UserCompanyRole>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  numberOfWrittenPoliciesInLast120Days?: Maybe<Scalars['Float']>;
  paidLifeCount?: Maybe<Scalars['Float']>;
  paidLifePoints?: Maybe<Scalars['Float']>;
  paymentCustomerId?: Maybe<Scalars['String']>;
  pendingLifeCount?: Maybe<Scalars['Float']>;
  pendingLifePoints?: Maybe<Scalars['Float']>;
  phoneNumber?: Maybe<Scalars['String']>;
  policies?: Maybe<Array<Maybe<Policy>>>;
  primaryPhone?: Maybe<Scalars['String']>;
  printedName?: Maybe<Scalars['String']>;
  producingAgentEffectiveDate?: Maybe<Scalars['BigInt']>;
  profileItems?: Maybe<Array<Maybe<UserCompanyRoleProfileItem>>>;
  profileScore?: Maybe<Scalars['Float']>;
  progressAPCL1?: Maybe<Scalars['Float']>;
  progressAPCL2?: Maybe<Scalars['Float']>;
  progressAPCL3?: Maybe<Scalars['Float']>;
  pushNotificationRegistrations?: Maybe<Array<Maybe<UserPushNotificationRegistration>>>;
  questions?: Maybe<Array<Maybe<AgentApplicationBackgroundQuestion>>>;
  receivingAdvanceCommissionCapAmount?: Maybe<Scalars['Float']>;
  references?: Maybe<Array<Maybe<AgentApplicationReference>>>;
  rejectedLifeCount?: Maybe<Scalars['Float']>;
  rejectedLifePoints?: Maybe<Scalars['Float']>;
  residentState?: Maybe<Scalars['String']>;
  reviewerPrintedName?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['ID']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  sponsor?: Maybe<UserCompanyRole>;
  sponsorId?: Maybe<Scalars['ID']>;
  ssn?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['ID']>;
  statusDate?: Maybe<Scalars['DateTime']>;
  statusEAndO?: Maybe<Scalars['String']>;
  totalLifeCount?: Maybe<Scalars['Float']>;
  totalLifePoints?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
  vestedSinceDate?: Maybe<Scalars['DateTime']>;
};

export type UserCompanyRoleHistory = {
  __typename?: 'UserCompanyRoleHistory';
  after?: Maybe<Scalars['JSONObject']>;
  before?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCompanyRole>;
  createdByUserId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  fromStatus?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
};

export type UserCompanyRoleProfileItem = {
  __typename?: 'UserCompanyRoleProfileItem';
  id: Scalars['ID'];
  isFinish?: Maybe<Scalars['Boolean']>;
  item: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  validity: Scalars['String'];
  warning: Scalars['String'];
};

export type UserCompanyRoleState = {
  __typename?: 'UserCompanyRoleState';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type UserGroup = {
  __typename?: 'UserGroup';
  agentCode?: Maybe<Scalars['String']>;
  agentLevelId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCompanyRole>;
  email?: Maybe<Scalars['String']>;
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  isCertified?: Maybe<Scalars['Boolean']>;
  isCertifiedTrainer?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userCompanyRoleId: Scalars['ID'];
};

export type UserPushNotificationRegistration = {
  __typename?: 'UserPushNotificationRegistration';
  companyId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCompanyRole>;
  deviceName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActivable?: Maybe<Scalars['Boolean']>;
  isDeactivable?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userCompanyRoleId: Scalars['ID'];
};

export type WidgetUser = {
  __typename?: 'WidgetUser';
  isEnabled?: Maybe<Scalars['Boolean']>;
  widgetId?: Maybe<Scalars['ID']>;
  widgetName?: Maybe<Scalars['String']>;
};

export type WidgetUserInput = {
  isEnabled: Scalars['Boolean'];
  widgetId: Scalars['ID'];
};

export type Winter2021CashBonusBaseshop = {
  __typename?: 'Winter2021CashBonusBaseshop';
  agentCode?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['ID']>;
  agentLevelId?: Maybe<Scalars['ID']>;
  agentName?: Maybe<Scalars['String']>;
  baseshopAgentIds?: Maybe<Scalars['String']>;
  dec2021ClosedPolicyPointValues?: Maybe<Scalars['Float']>;
  dec2021PolicyPointValues?: Maybe<Scalars['Float']>;
  isProductionQualified?: Maybe<Scalars['Boolean']>;
  isRecruitingQualified?: Maybe<Scalars['Boolean']>;
  jan2022ClosedPolicyPointValues?: Maybe<Scalars['Float']>;
  jan2022PolicyPointValues?: Maybe<Scalars['Float']>;
  nov2021ClosedPolicyPointValues?: Maybe<Scalars['Float']>;
  nov2021PolicyPointValues?: Maybe<Scalars['Float']>;
  numberOfCompletedExchangeSales?: Maybe<Scalars['Int']>;
  numberOfLicenses?: Maybe<Scalars['Int']>;
  numberOfRecruits?: Maybe<Scalars['Int']>;
  oct2021ClosedPolicyPointValues?: Maybe<Scalars['Float']>;
  oct2021PolicyPointValues?: Maybe<Scalars['Float']>;
};

export type Winter2021CashBonusPersonal = {
  __typename?: 'Winter2021CashBonusPersonal';
  agentCode?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['ID']>;
  agentLevelId?: Maybe<Scalars['ID']>;
  agentName?: Maybe<Scalars['String']>;
  isProductionQualified?: Maybe<Scalars['Boolean']>;
  isRecruitingQualified?: Maybe<Scalars['Boolean']>;
  numberOfCompletedExchangeSales?: Maybe<Scalars['Int']>;
  numberOfDeliveredPolicies?: Maybe<Scalars['Float']>;
  numberOfLicenses?: Maybe<Scalars['Int']>;
  numberOfPolicies?: Maybe<Scalars['Float']>;
  numberOfRecruits?: Maybe<Scalars['Int']>;
  totalPolicyPointValues?: Maybe<Scalars['Float']>;
};

export type Winter2021StatFilterInput = {
  agent?: InputMaybe<FilterQueryInput>;
};

export type Workflow = {
  __typename?: 'Workflow';
  actions?: Maybe<Array<Maybe<WorkflowAction>>>;
  blockInputDuringAutoSave?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  description: Scalars['String'];
  fields?: Maybe<Array<Maybe<WorkflowField>>>;
  hasDedicatedPage?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isAutoSave?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isUserInitializationAllowed?: Maybe<Scalars['Boolean']>;
  isWorkflowOwner?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  roles?: Maybe<Array<Maybe<WorkflowRole>>>;
};

export type WorkflowAction = {
  __typename?: 'WorkflowAction';
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCommentRequired?: Maybe<Scalars['Boolean']>;
  isSignatureRequired?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  prompt?: Maybe<Scalars['String']>;
};

export type WorkflowField = {
  __typename?: 'WorkflowField';
  code: Scalars['String'];
  fieldTypeCode: Scalars['ID'];
  fileProperties?: Maybe<WorkflowFieldFile>;
  helperText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  paragraphProperties?: Maybe<WorkflowFieldParagraph>;
  priority?: Maybe<Scalars['Int']>;
  textProperties?: Maybe<WorkflowFieldText>;
};

export type WorkflowFieldFile = {
  __typename?: 'WorkflowFieldFile';
  acceptableMimeTypes?: Maybe<Scalars['String']>;
  maxFiles?: Maybe<Scalars['PositiveInt']>;
};

export type WorkflowFieldParagraph = {
  __typename?: 'WorkflowFieldParagraph';
  rows?: Maybe<Scalars['PositiveInt']>;
};

export type WorkflowFieldRadioOption = {
  __typename?: 'WorkflowFieldRadioOption';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type WorkflowFieldText = {
  __typename?: 'WorkflowFieldText';
  maxLength?: Maybe<Scalars['PositiveInt']>;
  minLength?: Maybe<Scalars['PositiveInt']>;
  multiline?: Maybe<Scalars['Boolean']>;
  rows?: Maybe<Scalars['PositiveInt']>;
};

export type WorkflowFilterInput = {
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
};

export type WorkflowItem = {
  __typename?: 'WorkflowItem';
  actions?: Maybe<Array<Maybe<WorkflowAction>>>;
  code: Scalars['String'];
  commentCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['BigInt']>;
  createdBy?: Maybe<UserCompanyRole>;
  fields?: Maybe<Array<Maybe<WorkflowItemField>>>;
  histories?: Maybe<WorkflowItemHistory>;
  id: Scalars['ID'];
  isInTrash?: Maybe<Scalars['Boolean']>;
  isOwnerDeletable?: Maybe<Scalars['Boolean']>;
  isWorkflowOwner?: Maybe<Scalars['Boolean']>;
  lastActivity?: Maybe<WorkflowItemHistory>;
  name: Scalars['String'];
  privileges?: Maybe<WorkflowItemPrivilege>;
  signatureActivities?: Maybe<Array<Maybe<WorkflowItemHistory>>>;
  state?: Maybe<WorkflowState>;
  stateId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['BigInt']>;
  updatedBy?: Maybe<UserCompanyRole>;
  userCompanyRole?: Maybe<UserCompanyRole>;
  validationErrors?: Maybe<Array<Maybe<WorkflowItemField>>>;
  workflow?: Maybe<Workflow>;
  workflowId: Scalars['ID'];
};

export type WorkflowItemCategoryStat = {
  __typename?: 'WorkflowItemCategoryStat';
  code: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type WorkflowItemComment = {
  __typename?: 'WorkflowItemComment';
  actor?: Maybe<UserCompanyRole>;
  comment: Scalars['String'];
  createdAt?: Maybe<Scalars['BigInt']>;
  fieldId: Scalars['ID'];
  id: Scalars['ID'];
  isVisibleToAllRoles?: Maybe<Scalars['Boolean']>;
  workflowItem?: Maybe<WorkflowItem>;
  workflowItemField?: Maybe<WorkflowItemField>;
  workflowItemId: Scalars['ID'];
};

export type WorkflowItemEmail = {
  __typename?: 'WorkflowItemEmail';
  attachmentBase64Content?: Maybe<Scalars['String']>;
  attachmentName?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['BigInt']>;
  id: Scalars['ID'];
  recipient?: Maybe<UserCompanyRole>;
  recipientEmailAddress?: Maybe<Scalars['String']>;
  recipientId: Scalars['ID'];
  sentAt?: Maybe<Scalars['BigInt']>;
  subject?: Maybe<Scalars['String']>;
  workflowItem?: Maybe<WorkflowItem>;
  workflowItemId: Scalars['ID'];
};

export type WorkflowItemField = {
  __typename?: 'WorkflowItemField';
  commentCount?: Maybe<Scalars['Int']>;
  fieldId: Scalars['ID'];
  fieldTypeCode: Scalars['ID'];
  id: Scalars['ID'];
  invalidReason?: Maybe<Scalars['String']>;
  isCommentable?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isViewable?: Maybe<Scalars['Boolean']>;
  lgScreen?: Maybe<Scalars['Int']>;
  mdScreen?: Maybe<Scalars['Int']>;
  smScreen?: Maybe<Scalars['Int']>;
  triggerAutosave?: Maybe<Scalars['Boolean']>;
  value?: Maybe<WorkflowItemFieldValue>;
  xlScreen?: Maybe<Scalars['Int']>;
  xsScreen?: Maybe<Scalars['Int']>;
};

export type WorkflowItemFieldDatePicker = {
  __typename?: 'WorkflowItemFieldDatePicker';
  date?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
};

export type WorkflowItemFieldValue = {
  __typename?: 'WorkflowItemFieldValue';
  actionPlanChecklist?: Maybe<WorkflowItemFieldValueActionPlanChecklist>;
  agentPicker?: Maybe<WorkflowItemFieldValueAgentPicker>;
  aigContractPrerequisiteList?: Maybe<WorkflowItemFieldValueAigContractList>;
  appointedList?: Maybe<Array<Maybe<WorkflowItemFieldValueAppointedList>>>;
  attendTrainingClassChecklist?: Maybe<Array<Maybe<WorkflowItemFieldValueAttendTrainingClassChecklist>>>;
  buildingBusinessChecklist?: Maybe<Array<Maybe<WorkflowItemFieldValueBuildingBusinessChecklist>>>;
  buildingBusinessChecklistPart1?: Maybe<Array<Maybe<WorkflowItemFieldValueBuildingBusinessChecklistPart1>>>;
  datePicker?: Maybe<WorkflowItemFieldDatePicker>;
  fieldTrainingSaleAppointments?: Maybe<Array<Maybe<WorkflowItemFieldValueFieldTrainingSaleAppointment>>>;
  file?: Maybe<Array<Maybe<WorkflowItemFieldValueFile>>>;
  jumpStartForNewMembersChecklist?: Maybe<Array<Maybe<WorkflowItemFieldValueJumpStartForNewMembersChecklist>>>;
  jumpStartSkillTraining?: Maybe<Array<Maybe<WorkflowItemFieldValueJumpStartSkillTraining>>>;
  levelPicker?: Maybe<WorkflowItemFieldValueLevelPicker>;
  licensedList?: Maybe<Array<Maybe<WorkflowItemFieldValueLicensedList>>>;
  paragraph?: Maybe<WorkflowItemFieldValueParagraph>;
  personallyCloseIulSales?: Maybe<Array<Maybe<WorkflowItemFieldValuePersonallyCloseIulSale>>>;
  phase1OnlineTrainingProgram?: Maybe<Array<Maybe<WorkflowItemFieldValuePhase1OnlineTrainingProgram>>>;
  phase1UniversityClassList?: Maybe<Array<Maybe<WorkflowItemFieldValuePhase1UniversityClassList>>>;
  phase2UniversityClassContinuedList?: Maybe<Array<Maybe<WorkflowItemFieldValuePhase2UniversityClassList>>>;
  potentialRecruitChecklist?: Maybe<Array<Maybe<WorkflowItemFieldValuePotentialRecruitChecklist>>>;
  presentingSkillsChecklist?: Maybe<Array<Maybe<WorkflowItemFieldValuePresentingSkillsChecklist>>>;
  promotionTaskChecklist?: Maybe<Array<Maybe<WorkflowItemFieldValuePromotionTaskChecklist>>>;
  radio?: Maybe<WorkflowItemFieldValueRadio>;
  startBuildingChecklist?: Maybe<WorkflowItemFieldValueStartBuildingChecklist>;
  statePicker?: Maybe<WorkflowItemFieldValueStatePicker>;
  text?: Maybe<WorkflowItemFieldValueText>;
  trainingClassPicker?: Maybe<WorkflowItemFieldValueTrainingClassPicker>;
  trainingClassRequirement?: Maybe<Array<Maybe<WorkflowItemFieldValueTrainingClassRequirement>>>;
  trainingClassSessionPicker?: Maybe<WorkflowItemFieldValueTrainingClassSessionPicker>;
  universityClass?: Maybe<Array<Maybe<WorkflowItemFieldValueUniversityClass>>>;
};

export type WorkflowItemFieldValueAigContractList = {
  __typename?: 'WorkflowItemFieldValueAIGContractList';
  directDeposit?: Maybe<Scalars['Boolean']>;
  eo?: Maybe<Scalars['Boolean']>;
  fieldManual?: Maybe<Scalars['Boolean']>;
  producerLicense?: Maybe<Scalars['Boolean']>;
};

export type WorkflowItemFieldValueAigContractListInput = {
  directDeposit?: InputMaybe<Scalars['Boolean']>;
  eo?: InputMaybe<Scalars['Boolean']>;
  fieldManual?: InputMaybe<Scalars['Boolean']>;
  producerLicense?: InputMaybe<Scalars['Boolean']>;
};

export type WorkflowItemFieldValueActionPlanChecklist = {
  __typename?: 'WorkflowItemFieldValueActionPlanChecklist';
  completedSales?: Maybe<Array<Maybe<WorkflowItemFieldValueStartBuildingCompletedSale>>>;
  is5530Completed?: Maybe<Scalars['Boolean']>;
  my90DaysActionPlanDate?: Maybe<Scalars['Date']>;
  recruits?: Maybe<Array<Maybe<WorkflowItemFieldValueStartBuildingAgentRecruitment>>>;
};

export type WorkflowItemFieldValueActionPlanChecklistInput = {
  completedSales?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueStartBuildingCompletedSaleInput>>>;
  is5530Completed?: InputMaybe<Scalars['Boolean']>;
  my90DaysActionPlanDate?: InputMaybe<Scalars['Date']>;
  recruits?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueStartBuildingAgentRecruitmentInput>>>;
};

export type WorkflowItemFieldValueAgentPicker = {
  __typename?: 'WorkflowItemFieldValueAgentPicker';
  agentCode?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['ID']>;
  agentLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type WorkflowItemFieldValueAgentPickerInput = {
  agentCode?: InputMaybe<Scalars['String']>;
  agentLevelId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['EmailAddress']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type WorkflowItemFieldValueAppointedList = {
  __typename?: 'WorkflowItemFieldValueAppointedList';
  carrierAppointmentId?: Maybe<Scalars['ID']>;
  carrierId?: Maybe<Scalars['ID']>;
  checklistGetAppointedId?: Maybe<Scalars['ID']>;
  completedDate?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
};

export type WorkflowItemFieldValueAppointedListInput = {
  carrierAppointmentId?: InputMaybe<Scalars['ID']>;
  carrierId?: InputMaybe<Scalars['ID']>;
  checklistGetAppointedId?: InputMaybe<Scalars['ID']>;
  completedDate?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
};

export type WorkflowItemFieldValueAttendHandOnTrainingChecklistInput = {
  checklistAttendHandsOnTrainingId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
};

export type WorkflowItemFieldValueAttendTrainingClassChecklist = {
  __typename?: 'WorkflowItemFieldValueAttendTrainingClassChecklist';
  checklistAttendTrainingClassId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueAttendTrainingClassChecklistInput = {
  checklistAttendTrainingClassId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueBuildingBusinessChecklist = {
  __typename?: 'WorkflowItemFieldValueBuildingBusinessChecklist';
  checklistBuildingBusinessId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueBuildingBusinessChecklistInput = {
  checklistBuildingBusinessId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueBuildingBusinessChecklistPart1 = {
  __typename?: 'WorkflowItemFieldValueBuildingBusinessChecklistPart1';
  checklistBuildingBusinessPart1Id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueBuildingBusinessChecklistPart1Input = {
  checklistBuildingBusinessPart1Id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueDatePickerInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type WorkflowItemFieldValueFieldTrainingSaleAppointment = {
  __typename?: 'WorkflowItemFieldValueFieldTrainingSaleAppointment';
  appointmentDate?: Maybe<Scalars['Date']>;
  policies?: Maybe<Array<Maybe<WorkflowItemFieldValueFieldTrainingSalePolicy>>>;
  trainerAgentCode?: Maybe<Scalars['String']>;
  trainerId?: Maybe<Scalars['ID']>;
  trainerName?: Maybe<Scalars['String']>;
};

export type WorkflowItemFieldValueFieldTrainingSaleAppointmentInput = {
  appointmentDate?: InputMaybe<Scalars['Date']>;
  policies?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueFieldTrainingSalePolicyInput>>>;
  trainerAgentCode?: InputMaybe<Scalars['String']>;
  trainerId?: InputMaybe<Scalars['ID']>;
  trainerName?: InputMaybe<Scalars['String']>;
};

export type WorkflowItemFieldValueFieldTrainingSalePolicy = {
  __typename?: 'WorkflowItemFieldValueFieldTrainingSalePolicy';
  insuredName?: Maybe<Scalars['String']>;
  policyId?: Maybe<Scalars['ID']>;
  policyNumber?: Maybe<Scalars['String']>;
  writtenDate?: Maybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueFieldTrainingSalePolicyInput = {
  insuredName?: InputMaybe<Scalars['String']>;
  policyId?: InputMaybe<Scalars['ID']>;
  policyNumber?: InputMaybe<Scalars['String']>;
  writtenDate?: InputMaybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueFile = {
  __typename?: 'WorkflowItemFieldValueFile';
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['PositiveInt']>;
};

export type WorkflowItemFieldValueFileInput = {
  mimeType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['PositiveInt']>;
};

export type WorkflowItemFieldValueJumpStartForNewMembersChecklist = {
  __typename?: 'WorkflowItemFieldValueJumpStartForNewMembersChecklist';
  agentCode?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['ID']>;
  avatarUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueJumpStartForNewMembersChecklistInput = {
  agentCode?: InputMaybe<Scalars['String']>;
  agentId?: InputMaybe<Scalars['ID']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueJumpStartSkillTraining = {
  __typename?: 'WorkflowItemFieldValueJumpStartSkillTraining';
  checklistJumpStartSkillTrainingId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
};

export type WorkflowItemFieldValueJumpStartSkillTrainingInput = {
  checklistJumpStartSkillTrainingId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
};

export type WorkflowItemFieldValueLevelPicker = {
  __typename?: 'WorkflowItemFieldValueLevelPicker';
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type WorkflowItemFieldValueLevelPickerInput = {
  id?: InputMaybe<Scalars['ID']>;
  level?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type WorkflowItemFieldValueLicensedList = {
  __typename?: 'WorkflowItemFieldValueLicensedList';
  checklistGetLicensedId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
};

export type WorkflowItemFieldValueLicensedListInput = {
  checklistGetLicensedId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
};

export type WorkflowItemFieldValueParagraph = {
  __typename?: 'WorkflowItemFieldValueParagraph';
  content?: Maybe<Scalars['String']>;
  readPercentage?: Maybe<Scalars['Int']>;
};

export type WorkflowItemFieldValueParagraphInput = {
  content?: InputMaybe<Scalars['String']>;
  readPercentage?: InputMaybe<Scalars['Int']>;
};

export type WorkflowItemFieldValuePersonallyCloseIulSale = {
  __typename?: 'WorkflowItemFieldValuePersonallyCloseIulSale';
  insuredName?: Maybe<Scalars['String']>;
  pointValue?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['ID']>;
  policyNumber?: Maybe<Scalars['String']>;
  receiptReviewedDate?: Maybe<Scalars['Date']>;
  writtenDate?: Maybe<Scalars['Date']>;
};

export type WorkflowItemFieldValuePersonallyCloseIulSaleInput = {
  insuredName?: InputMaybe<Scalars['String']>;
  pointValue?: InputMaybe<Scalars['Float']>;
  policyId?: InputMaybe<Scalars['ID']>;
  policyNumber?: InputMaybe<Scalars['String']>;
  receiptReviewedDate?: InputMaybe<Scalars['Date']>;
  writtenDate?: InputMaybe<Scalars['Date']>;
};

export type WorkflowItemFieldValuePhase1OnlineTrainingProgram = {
  __typename?: 'WorkflowItemFieldValuePhase1OnlineTrainingProgram';
  checklistPhase1OnlineTrainingProgramId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
};

export type WorkflowItemFieldValuePhase1OnlineTrainingProgramInput = {
  checklistPhase1OnlineTrainingProgramId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
};

export type WorkflowItemFieldValuePhase1UniversityClassList = {
  __typename?: 'WorkflowItemFieldValuePhase1UniversityClassList';
  checklistPhase1UniversityClassId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
};

export type WorkflowItemFieldValuePhase1UniversityClassListInput = {
  checklistPhase1UniversityClassId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
};

export type WorkflowItemFieldValuePhase2UniversityClassList = {
  __typename?: 'WorkflowItemFieldValuePhase2UniversityClassList';
  checklistPhase2UniversityClassId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
};

export type WorkflowItemFieldValuePhase2UniversityClassListInput = {
  checklistPhase2UniversityClassId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
};

export type WorkflowItemFieldValuePotentialRecruitChecklist = {
  __typename?: 'WorkflowItemFieldValuePotentialRecruitChecklist';
  agentCode?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['ID']>;
  avatarUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type WorkflowItemFieldValuePotentialRecruitChecklistInput = {
  agentCode?: InputMaybe<Scalars['String']>;
  agentId?: InputMaybe<Scalars['ID']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type WorkflowItemFieldValuePresentingSkillsChecklist = {
  __typename?: 'WorkflowItemFieldValuePresentingSkillsChecklist';
  checklistPresentingSkillsId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
};

export type WorkflowItemFieldValuePresentingSkillsChecklistInput = {
  checklistPresentingSkillsId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
};

export type WorkflowItemFieldValuePromotionTaskChecklist = {
  __typename?: 'WorkflowItemFieldValuePromotionTaskChecklist';
  checklistPromotionTaskId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type WorkflowItemFieldValuePromotionTaskChecklistInput = {
  checklistPromotionTaskId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueRadio = {
  __typename?: 'WorkflowItemFieldValueRadio';
  value?: Maybe<Scalars['String']>;
};

export type WorkflowItemFieldValueRadioInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type WorkflowItemFieldValueStartBuildingAgentRecruitment = {
  __typename?: 'WorkflowItemFieldValueStartBuildingAgentRecruitment';
  agentCode?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['ID']>;
  agentName?: Maybe<Scalars['String']>;
  dateReviewed?: Maybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueStartBuildingAgentRecruitmentInput = {
  agentCode?: InputMaybe<Scalars['String']>;
  agentId?: InputMaybe<Scalars['ID']>;
  agentName?: InputMaybe<Scalars['String']>;
  dateReviewed?: InputMaybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueStartBuildingChecklist = {
  __typename?: 'WorkflowItemFieldValueStartBuildingChecklist';
  appointment1Date?: Maybe<Scalars['Date']>;
  appointment2Date?: Maybe<Scalars['Date']>;
  completedSales?: Maybe<Array<Maybe<WorkflowItemFieldValueStartBuildingCompletedSale>>>;
  is5530Completed?: Maybe<Scalars['Boolean']>;
  recruits?: Maybe<Array<Maybe<WorkflowItemFieldValueStartBuildingAgentRecruitment>>>;
  signupDate?: Maybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueStartBuildingChecklistInput = {
  appointment1Date?: InputMaybe<Scalars['Date']>;
  appointment2Date?: InputMaybe<Scalars['Date']>;
  completedSales?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueStartBuildingCompletedSaleInput>>>;
  is5530Completed?: InputMaybe<Scalars['Boolean']>;
  recruits?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueStartBuildingAgentRecruitmentInput>>>;
  signupDate?: InputMaybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueStartBuildingCompletedSale = {
  __typename?: 'WorkflowItemFieldValueStartBuildingCompletedSale';
  insuredName?: Maybe<Scalars['String']>;
  policyId?: Maybe<Scalars['ID']>;
  policyNumber?: Maybe<Scalars['String']>;
  writtenDate?: Maybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueStartBuildingCompletedSaleInput = {
  insuredName?: InputMaybe<Scalars['String']>;
  policyId?: InputMaybe<Scalars['ID']>;
  policyNumber?: InputMaybe<Scalars['String']>;
  writtenDate?: InputMaybe<Scalars['Date']>;
};

export type WorkflowItemFieldValueStatePicker = {
  __typename?: 'WorkflowItemFieldValueStatePicker';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WorkflowItemFieldValueStatePickerInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type WorkflowItemFieldValueText = {
  __typename?: 'WorkflowItemFieldValueText';
  content?: Maybe<Scalars['String']>;
};

export type WorkflowItemFieldValueTextInput = {
  content?: InputMaybe<Scalars['String']>;
};

export type WorkflowItemFieldValueTrainingClassPicker = {
  __typename?: 'WorkflowItemFieldValueTrainingClassPicker';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  trainingClassId?: Maybe<Scalars['ID']>;
};

export type WorkflowItemFieldValueTrainingClassPickerInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type WorkflowItemFieldValueTrainingClassRequirement = {
  __typename?: 'WorkflowItemFieldValueTrainingClassRequirement';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isManualValidated?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  trainingClassRequirementId?: Maybe<Scalars['ID']>;
};

export type WorkflowItemFieldValueTrainingClassRequirementInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  isManualValidated?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  trainingClassRequirementId?: InputMaybe<Scalars['ID']>;
};

export type WorkflowItemFieldValueTrainingClassSessionPicker = {
  __typename?: 'WorkflowItemFieldValueTrainingClassSessionPicker';
  description?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<TrainingClassSessionFile>>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  trainingClassSessionId?: Maybe<Scalars['ID']>;
};

export type WorkflowItemFieldValueTrainingClassSessionPickerInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type WorkflowItemFieldValueUniversityClass = {
  __typename?: 'WorkflowItemFieldValueUniversityClass';
  description?: Maybe<Scalars['String']>;
  helperText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  universityClassId?: Maybe<Scalars['ID']>;
};

export type WorkflowItemFieldValueUniversityClassInput = {
  description?: InputMaybe<Scalars['String']>;
  helperText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  universityClassId?: InputMaybe<Scalars['ID']>;
};

export type WorkflowItemFilterInput = {
  agents?: InputMaybe<FilterIdsInput>;
  category?: InputMaybe<FilterBooleanInput>;
  isPendingOnMe?: InputMaybe<FilterBooleanInput>;
  isTrashed?: InputMaybe<FilterBooleanInput>;
  owners?: InputMaybe<FilterIdsInput>;
  query?: InputMaybe<FilterQueryInput>;
  text?: InputMaybe<FilterQueryInput>;
  workflows?: InputMaybe<FilterIdsInput>;
};

export type WorkflowItemHistory = {
  __typename?: 'WorkflowItemHistory';
  action?: Maybe<WorkflowAction>;
  actionId?: Maybe<Scalars['ID']>;
  actor?: Maybe<UserCompanyRole>;
  actorId?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['BigInt']>;
  email?: Maybe<Array<Maybe<WorkflowItemEmail>>>;
  id: Scalars['ID'];
  newValues: Scalars['JSONObject'];
  oldValues?: Maybe<Scalars['JSONObject']>;
  role?: Maybe<WorkflowRole>;
  roleId?: Maybe<Scalars['ID']>;
  signature?: Maybe<Scalars['String']>;
  workflowId: Scalars['ID'];
};

export type WorkflowItemPrivilege = {
  __typename?: 'WorkflowItemPrivilege';
  actor?: Maybe<UserCompanyRole>;
  actorId: Scalars['ID'];
  id: Scalars['ID'];
  isCommentable?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isResetable?: Maybe<Scalars['Boolean']>;
  isViewable?: Maybe<Scalars['Boolean']>;
  workflowRole?: Maybe<WorkflowRole>;
  workflowRoleId?: Maybe<Scalars['ID']>;
};

export type WorkflowItemValueInput = {
  actionPlanChecklist?: InputMaybe<WorkflowItemFieldValueActionPlanChecklistInput>;
  agentPicker?: InputMaybe<WorkflowItemFieldValueAgentPickerInput>;
  aigContractPrerequisiteList?: InputMaybe<WorkflowItemFieldValueAigContractListInput>;
  appointedList?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueAppointedListInput>>>;
  attendTrainingClassChecklist?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueAttendTrainingClassChecklistInput>>>;
  buildingBusinessChecklist?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueBuildingBusinessChecklistInput>>>;
  buildingBusinessChecklistPart1?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueBuildingBusinessChecklistPart1Input>>>;
  datePicker?: InputMaybe<WorkflowItemFieldValueDatePickerInput>;
  fieldTrainingSaleAppointments?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueFieldTrainingSaleAppointmentInput>>>;
  file?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueFileInput>>>;
  id: Scalars['ID'];
  jumpStartForNewMembersChecklist?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueJumpStartForNewMembersChecklistInput>>>;
  jumpStartSkillTraining?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueJumpStartSkillTrainingInput>>>;
  levelPicker?: InputMaybe<WorkflowItemFieldValueLevelPickerInput>;
  licensedList?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueLicensedListInput>>>;
  paragraph?: InputMaybe<WorkflowItemFieldValueParagraphInput>;
  personallyCloseIulSales?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValuePersonallyCloseIulSaleInput>>>;
  phase1OnlineTrainingProgram?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValuePhase1OnlineTrainingProgramInput>>>;
  phase1UniversityClassList?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValuePhase1UniversityClassListInput>>>;
  phase2UniversityClassContinuedList?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValuePhase2UniversityClassListInput>>>;
  potentialRecruitChecklist?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValuePotentialRecruitChecklistInput>>>;
  presentingSkillsChecklist?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValuePresentingSkillsChecklistInput>>>;
  promotionTaskChecklist?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValuePromotionTaskChecklistInput>>>;
  radio?: InputMaybe<WorkflowItemFieldValueRadioInput>;
  startBuildingChecklist?: InputMaybe<WorkflowItemFieldValueStartBuildingChecklistInput>;
  statePicker?: InputMaybe<WorkflowItemFieldValueStatePickerInput>;
  text?: InputMaybe<WorkflowItemFieldValueTextInput>;
  trainingClassPicker?: InputMaybe<WorkflowItemFieldValueTrainingClassPickerInput>;
  trainingClassRequirement?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueTrainingClassRequirementInput>>>;
  trainingClassSessionPicker?: InputMaybe<WorkflowItemFieldValueTrainingClassSessionPickerInput>;
  universityClass?: InputMaybe<Array<InputMaybe<WorkflowItemFieldValueUniversityClassInput>>>;
};

export type WorkflowRole = {
  __typename?: 'WorkflowRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type WorkflowState = {
  __typename?: 'WorkflowState';
  backgroundColor?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isStart?: Maybe<Scalars['Boolean']>;
  isStop?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  textColor?: Maybe<Scalars['String']>;
};

export type GetConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfigurationQuery = { __typename?: 'Query', config?: { __typename?: 'Config', isDevelopment: boolean, isAutomationAllowed: boolean, toastAutoHideDuration: any, isSystemInMaintenanceMode?: boolean | null, isTourEntriesAllowed: boolean, version: string, defaultPaginationRowsPerPage: Array<number | null> } | null, getSession?: { __typename?: 'Session', user?: { __typename?: 'User', id: string, name: string, avatarUrl?: string | null } | null } | null };

export type CreateCompanyMutationVariables = Exact<{
  adminFirstName: Scalars['String'];
  adminLastName: Scalars['String'];
  adminEmail: Scalars['String'];
  companyCode: Scalars['String'];
  companyDomainName: Scalars['String'];
  companyName: Scalars['String'];
  companyAddress: Scalars['String'];
  companyPhone: Scalars['String'];
  companySlogan: Scalars['String'];
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany?: { __typename?: 'Company', id: string, code: string } | null };


export const GetConfigurationDocument = gql`
    query getConfiguration {
  config {
    isDevelopment
    isAutomationAllowed
    toastAutoHideDuration
    isSystemInMaintenanceMode
    isTourEntriesAllowed
    version
    defaultPaginationRowsPerPage
  }
  getSession {
    user {
      id
      name
      avatarUrl
    }
  }
}
    `;

/**
 * __useGetConfigurationQuery__
 *
 * To run a query within a React component, call `useGetConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
      }
export function useGetConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
        }
export type GetConfigurationQueryHookResult = ReturnType<typeof useGetConfigurationQuery>;
export type GetConfigurationLazyQueryHookResult = ReturnType<typeof useGetConfigurationLazyQuery>;
export type GetConfigurationQueryResult = Apollo.QueryResult<GetConfigurationQuery, GetConfigurationQueryVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($adminFirstName: String!, $adminLastName: String!, $adminEmail: String!, $companyCode: String!, $companyDomainName: String!, $companyName: String!, $companyAddress: String!, $companyPhone: String!, $companySlogan: String!) {
  createCompany(
    adminFirstName: $adminFirstName
    adminLastName: $adminLastName
    adminEmail: $adminEmail
    companyCode: $companyCode
    companyDomainName: $companyDomainName
    companyName: $companyName
    companyAddress: $companyAddress
    companyPhone: $companyPhone
    companySlogan: $companySlogan
  ) {
    id
    code
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      adminFirstName: // value for 'adminFirstName'
 *      adminLastName: // value for 'adminLastName'
 *      adminEmail: // value for 'adminEmail'
 *      companyCode: // value for 'companyCode'
 *      companyDomainName: // value for 'companyDomainName'
 *      companyName: // value for 'companyName'
 *      companyAddress: // value for 'companyAddress'
 *      companyPhone: // value for 'companyPhone'
 *      companySlogan: // value for 'companySlogan'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;