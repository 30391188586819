import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import config from "./config";
import isOffline from "../isOffline";

let app: FirebaseApp;

export default function getClient(): FirebaseApp {
  if (app) {
    return app;
  }
  app = initializeApp(config);

  if (!isOffline()) {
    const auth = getAuth();
    auth.useDeviceLanguage();
  }
  return app;
}
